.card {
	@extend .box-shadow;
	@extend .flexible-island;
	border-radius: $border-radius;
	min-height: 300px;
	min-width: 300px;
	margin: 30px 20px 20px 20px;
	flex-grow: 1;

	@media #{$medium-screen} {
		margin: 10px 8px;
	}

	@media #{$small-screen} {
		margin: 15px 8px;
	}

	&-header {
		@extend .flexible-island__item;
		display: flex;
		justify-content: flex-start;

		h3 {
			margin: 0;
		}
	}

	&-body {
		@extend .flexible-island__item;
		margin-top: 0.5em;
	}
}
