@import "./components/style.scss";

.initiative-list-controls {
	display: flex;
	padding: 0.5rem;
	border-radius: $STRATEGY--border-radius;
	background-color: $grey-1;
	margin-bottom: 1rem;
}

.initiative-list > * {
	margin-bottom: 1rem;
}
