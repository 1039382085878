@import "components/style";

.settings {
	display: flex;
	margin: 0 5%;
	// Bottom padding for Chatlio live chat widget
	padding-bottom: 75px;
}

.setting {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 50%;
	height: max-content;
	max-height: 500px;

	&--loading {
		height: 200px;
		justify-self: center;
	}
}

.color-preview {
	width: calc(#{$small-vertical-unit} * 2);
	height: calc(#{$small-vertical-unit} * 2);
	border-radius: $border-radius;
}

.color-picker {
	margin-top: calc(#{$small-vertical-unit} * 4);
	position: absolute;
	z-index: $menu-top-layer;

	&__cover {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
