.data-inspector__title {
	font-weight: bold;
}

.data-inspector__tabs,
.data-inspector__tabs .react-tabs__tab-panel--selected {
	height: 100%;
}

.data-inspector__table-wrapper {
	display: block;
	width: 100%;
	position: relative;
	height: 100%;
}

.entity-editor__confirmation-btn-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.entity-row {
	.react-datepicker-wrapper {
		width: 100%;
	}
}

.fixedDataTableLayout_main {
	@extend .u-reset-list;
	.entity-title {
		display: none;
	}
	.entity-value {
		width: 100%;
	}
	.entity-input {
		color: $primary-text-color;
		width: 70%;
		padding: 0.5em;
		margin-bottom: 1px;
	}
	select.entity-input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding: 0.5em;
		background-image: linear-gradient(45deg, transparent 50%, $primary-text-color 50%),
			linear-gradient(135deg, $primary-text-color 50%, transparent 50%);
		background-position: calc(100% - 15px) calc(50%), calc(100% - 10px) calc(50%);
		background-size: 5px 5px, 5px 5px, 1px 1.5em;
		background-repeat: no-repeat;
	}
	.entity-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left: calc((#{$small-horizontal-unit} / 4) * 3);
		margin-right: 4px;
		border-bottom: 1px solid transparent;
	}
	.custom-icon {
		font-size: 18px;
		width: 14px;
		height: 14px;
	}
	.fixedDataTableRowLayout_main:not(.fixedDataTableLayout_header) {
		.icon-btn {
			color: inherit;
			visibility: hidden;
		}
		.icon-btn:hover {
			color: $primary-text-color;
		}
		.icon-btn:focus {
			color: $primary-text-color;
			visibility: visible;
		}
	}
	.fixedDataTableRowLayout_main:not(.fixedDataTableLayout_header):hover {
		.entity-row {
			cursor: pointer;
			border-bottom: 1px solid $primary-text-color;
		}
		.cell__white-text {
			.entity-row {
				border-bottom: 1px solid $white;
			}
		}
		.icon-btn {
			visibility: visible;
		}
	}

	.entity-editor__confirmation-btn-wrapper {
		max-width: 80px;
	}
}

.data-inspector__entity-table {
	@extend .u-reset-list;
	font-size: smaller;
	width: 100%;
	overflow: auto;

	.entity-row:nth-child(1) {
		border-top: 1px solid $fadedpink;

		.entity-title {
			min-height: 36px;
		}
	}

	.entity-row {
		min-height: 36px;
		display: flex;
		align-items: stretch;
		border-bottom: 1px solid $fadedpink;
		border-right: 1px solid $fadedpink;

		.entity-value {
			display: flex;
			align-items: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: pre-wrap;
			padding: 3px 6px 3px 3px;
			width: 80%;
		}

		.entity-updated {
			background: $brightyellow;
		}

		.entity-title {
			background-color: $fadedpink;
			display: flex;
			align-items: center;
			width: 25%;
			min-width: 25%;
			overflow: hidden;
			text-overflow: ellipsis;
			justify-content: flex-end;
			font-weight: bold;
			padding: 3px 6px 3px 3px;
			min-height: 35px;
			text-align: right;
		}

		.entity-input {
			width: 65%;
			border: none;
			border-bottom: 1px solid inherit;
		}
		select.entity-input {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			padding: 0.5em;
			background-image: linear-gradient(45deg, transparent 50%, $primary-text-color 50%),
				linear-gradient(135deg, $primary-text-color 50%, transparent 50%);
			background-position: calc(100% - 15px) calc(50%), calc(100% - 10px) calc(50%);
			background-size: 5px 5px, 5px 5px, 1px 1.5em;
			background-repeat: no-repeat;
		}

		.entity-save__btn {
			color: $limegreen;
			font-weight: bold;
		}

		.entity-reset__btn {
			color: $red;
			font-weight: bold;
		}
	}
}

.data-inspector__buttons {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1em;

	.data-inspector__btn {
		margin: 0.5em;
		max-width: 300px;
	}
	.data-inspector__list {
		margin: 0.5em;
		ul {
			list-style: none;
			margin-left: -1em;
		}
		li {
			text-decoration: none;
			padding: 0.25em 0;
		}
	}
	.collapse-icon {
		margin-right: 5px;
	}
}

.data-inspector__links {
	margin-bottom: 1em;

	.data-inspector__buttons {
		margin-bottom: 0;

		.btn--fill a {
			color: $white;
			border: none;
		}
	}
}
