.content-editable {
	@extend .interactive;
}

.content-editable-can-edit {
	justify-content: space-between;
	border-bottom: 1px solid transparent;
	white-space: pre-wrap;

	&:hover {
		border-bottom: 1px solid $lightgrey;
	}
	&.focus {
		border-bottom: 1px solid $primary-text-color;
	}

	&__placeholder {
		width: auto;
		color: $inactive;

		&:before {
			content: attr(placeholder);
		}
	}
}

.content-editable-can-edit__input:focus {
	border: none;
	outline: none;
}

.content-editable__edit-icon {
	padding-bottom: 4px;
	padding-left: 2px;
	font-size: 15px !important;
}

%content-editable__default-title {
	margin: 0;
	outline: none;
	&:hover {
		cursor: text;
	}
}

.content-editable__bold {
	@extend %content-editable__default-title;
	font-weight: bold;
}
