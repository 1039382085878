.graph-series-by-period {
	&__stage {
		padding: 0;
		margin: 0;

		.target-value-group {
			.labels text {
				fill: $kiplot-blue;
			}

			.target-value {
				stroke-dasharray: 5px;
				stroke: $kiplot-blue;
			}
		}

		.line-group {
			.labels text {
				fill: $highlight-color;
				font-weight: 700;
			}

			.line {
				stroke: $highlight-color;
			}

			.line-dot {
				stroke: $highlight-color;
			}
		}

		.bar-group {
			.labels text {
				fill: $grey-4;
				font-weight: 700;
			}

			.bar {
				fill: $grey-2;
			}
		}

		.axis-group {
			path {
				color: $grey-3;
			}

			text,
			.text-class {
				font-size: $STRATEGY--font-small-size;
				fill: $grey-5;
			}
		}
	}
}
