.create-outcome {
	display: flex;
	align-items: center;
	background-color: transparent;
	font-size: small;
	font-weight: bold;
	width: 100%;
	height: 2rem;
	border-radius: $STRATEGY--border-radius;
	cursor: pointer;
	transition: $STRATEGY--button-transition;
	color: $grey-3;
	visibility: hidden;

	&:hover {
		background-color: $grey-1;
	}

	&--show {
		visibility: visible;
	}

	&__icon {
		font-size: small;
		margin: 0 10px;
		line-height: 1.2;
	}
}

.create-outcome-trigger {
	width: 100%;
}

.create-outcome-grey-box {
	margin-bottom: 0;
}

.create-outcome-dates {
	display: flex;
	flex-direction: column;
	width: 250px;
}

.create-outcome-error {
	max-width: 250px;
}
