@import "../../../../../lib/style/variables";
@import "../../../../../lib/style/breakPoints";
@import "../../variables";

.dataset-row {
	&-loading {
		background: linear-gradient(to right, $very-light-grey 20%, $lightgrey 50%, $very-light-grey 80%);
		animation-name: moving-gradient;
		animation-duration: 1400ms;
		animation-iteration-count: infinite;
		animation-timing-function: cubic-bezier(0, 0.13, 0.79, 0.97);
		animation-fill-mode: forwards;
	}
}

.dataset-row__wrapper-loading {
	// long fade-in to prevent flashing ui
	animation: fade-in 220ms ease-in;
	transition: 120ms;
}

.dataset-row__wrapper,
.dataset-row__wrapper-loading {
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: row;
	margin: 0px 20px 0px 15px;

	&.active,
	&:hover {
		.dataset-row,
		.dataset-logo__wrapper {
			background-color: $standardgrey;
			transition: 250ms;
		}
	}

	&:nth-child(1) {
		margin-top: 15px;
	}

	.dataset-row-click-target {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	.dataset-name {
		pointer-events: none;
	}

	.dataset-row,
	.dataset-row-loading {
		@import "DatasetSyncStatus/style";
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 0 $border-radius $border-radius 0;
		padding-left: 15px;
		background-color: $very-light-grey;
		width: 100%;
		height: $row-height-mobile;
		margin-left: -15px;
		position: relative;
		pointer-events: none;

		@media #{$mobile-break-point} {
			border-radius: 25px $border-radius $border-radius 25px;
			height: $row-height-desktop;
			padding-left: 30px;
		}

		&--meta-data {
			display: flex;
			align-items: center;
			font-size: 14px;
			margin-left: 5px;
			height: 18px;
			pointer-events: all;

			@media #{$navigation-collapse-break-point} {
				&-item {
					&::after {
						content: " | ";
					}

					&:last-child::after {
						content: "";
					}
				}
			}
		}

		.dataset-row--new-dataset {
			@extend .font-bold;
			color: $success;
			padding-left: 10px;

			@media #{$navigation-collapse-break-point} {
				padding-left: 0px;
			}
		}

		.dataset-row--last-updated {
			@include hide;
			@media #{$navigation-collapse-break-point} {
				@include show;
			}
		}
	}
}
