@import "../../../lib/style/breakPoints";

.dataset-side-panel__icon-menu {
	.icon-menu {
		display: flex;
		width: 100%;
		// ie11 does not work with `space-between;`
		justify-content: space-around;
		justify-content: space-between;

		.icon-btn {
			i[class^="icon-"],
			i[class*=" icon-"] {
				color: $white;
			}

			&:hover {
				background-color: $deep-blue;
			}
		}
	}

	.delete-dataset-button,
	.duplicate-dataset-button,
	.sync-dataset-button,
	.upload-dataset-button,
	.share-dataset-button,
	.download-dataset-button {
		@include hide;
	}
}
