$STRATEGY--CC-period-header-height: 70px;

@import "./components/style.scss";

.planning-stage-white-box {
	border-radius: $STRATEGY--border-radius;
	background-color: $white;
	position: relative;

	&__header {
		display: flex;
		padding: 0 1rem 1rem 1rem;
		align-items: center;

		h3 {
			font-weight: normal;
			margin-right: 0.5rem;
		}
	}
}

.planning-stage-wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	place-items: stretch;
	overflow-y: auto;
	// this is hidden as nothing should cause this scrolling and the dependency overlay arrow svg will cause a scroll which should be hidden
	overflow-x: hidden;

	height: calc(100vh - 14rem);
}

.teams-buttons {
	display: flex;

	& > * {
		margin-left: 0.5rem;
	}
}
