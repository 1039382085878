.mark-tooltip {
	@extend .small-flexible-island;
	pointer-events: none;
	position: fixed;
	background: $white;
	border: 1px solid $lightgrey;
	font-size: 14px;
	z-index: $tooltip-layer;
	visibility: hidden;

	$fg: $white;
	$bg: $standardgrey;
	$fgWidth: 8px;
	$bgWidth: 8px;

	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);

	// tooltip is oriented at top (above), left (to left of) etc
	// so we add a caret pointing at the content hovered

	// carets are 0 width/height elements using borders as the
	// triangle. 2 borders are transparent to create the triangular shape.

	// ::after is the foreground, ::before is the background
	&.top {
		&:before {
			top: calc(100% - 6px);
			left: calc(50% - 6px);
		}
		&:after {
			bottom: 0;
			left: calc(50% - 8px);
			width: 24px;
			height: 10px;
		}
	}
	&.bottom {
		&:before {
			bottom: calc(100% - 6px);
			left: calc(50% - 6px);
		}
		&:after {
			top: 0;
			left: calc(50% - 8px);
			width: 24px;
			height: 10px;
		}
	}
	&.left {
		&:before {
			top: calc(50% - 6px);
			left: calc(100% - 6px);
		}
		&:after {
			right: 0;
			top: calc(50% - 8px);
			height: 24px;
			width: 10px;
		}
	}
	&.right {
		&:before {
			top: calc(50% - 6px);
			right: calc(100% - 6px);
		}
		&:after {
			left: 0;
			top: calc(50% - 8px);
			height: 24px;
			width: 10px;
		}
	}
	&:after,
	&:before {
		border: none;
		content: " ";
		position: absolute;
		pointer-events: none;
	}
	&:after {
		background-color: $white;
	}
	&:before {
		width: 12px;
		height: 12px;
		background: $fg;
		transform: rotate(-45deg);
		box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 2px 1px -1px rgba(0, 0, 0, 0.22);
	}
	.tooltip-content {
		position: relative;
		z-index: $tooltip-layer;
		&:after,
		&:before {
			display: none;
		}
	}
}
