.initiative-detail-header {
	@extend .flexible-island;
	display: flex;
	flex: 0 0 auto;
	width: auto;
	flex-grow: 0;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

.initiative-detail-header-input {
	font-size: 32px;
	margin: 0.5em 0;
}

.initiative-detail-tabs-wrapper {
	margin: 0 20px 15px 15px;
	display: flex;
	justify-content: space-between;

	.initiative-detail-tabs {
		@extend .u-reset-list;

		.react-tabs__tab {
			text-decoration: none;
			display: inline-block;
			text-align: center;
			min-width: 100px;
		}
	}
}
