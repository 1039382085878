.sign-in-button {
	@extend .btn, .btn--fill;
	width: 150px;
	padding: 10px;
}

.sign-in-header {
	@extend .show-whitespace;
	text-align: left;
	width: 100%;
	padding: 0rem 0.99rem;

	> p {
		font-style: italic;
	}
}

.sign-in-button-container {
	padding: 25px 0px;
}

$transition: all 200ms;
.sign-in-nav__wrapper {
	// remove this to show the toggle switch, It took an ungodly amount of time to fathom the below styles
	display: none;
	visibility: hidden;
	pointer-events: none;

	@extend .box-shadow;
	z-index: $general-mid-layer;
	position: absolute;
	width: 200px;
	top: 3%;
	left: 12.5%;
	border-radius: 30px;
	background: $white;
	padding: 20px;

	@media #{$small-screen} {
		top: 20px;
		right: 20px;
		left: auto;
		position: fixed;
	}

	.sign-in-nav {
		position: absolute;
		top: 16%;
		left: 5%;
		width: 180px;
		height: 27px;
		outline: 0;
		border-radius: 100px;
		border: none;
		cursor: pointer;
		z-index: $general-mid-layer;
		transition: 0.4s;
		background: $very-light-grey;
	}

	.sign-in-nav__slider {
		@extend .font-bold, .flex;
		align-items: center;
		justify-content: center;
		color: $white;
		width: 80px;
		padding: 0;
		height: 27px;
		border-radius: 40px;
		transition: $transition;
		z-index: -2;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		&.un-checked {
			transition: $transition;
			left: 100%;
			transform: translateX(-100%);
			box-shadow: 1px 1px 6px 1px $translucent-black;
			background-color: $red;
		}

		&.checked {
			left: 0;
			transform: translateX(0);
			transition: $transition;
			box-shadow: -1px 0px 5px 1px $translucent-black;
			background-color: $blue;
		}
	}
}
