.page-not-found {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__image {
		width: 700px;
		height: auto;

		@include media(">phone", "<tablet") {
			width: 600px;
		}

		@include media("<phone") {
			width: 70%;
		}
	}

	&__header {
		margin-top: 2rem;
		text-align: center;
	}

	&__message {
		text-align: center;
	}
}
