.roadmap-initiative-item {
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	min-width: 6rem;
	max-width: 100%;
	box-sizing: content-box;
	display: flex;
	align-items: stretch;
	justify-content: stretch;
	height: 2rem;
	transition: $STRATEGY--button-transition;
	border-radius: $STRATEGY--border-radius;
	user-select: none;

	&--expanded {
		.roadmap-initiative-item__left-value--rounded {
			border-bottom-left-radius: 0;
		}

		.roadmap-initiative-item__right-tear--rounded {
			border-bottom-right-radius: 0;
		}
	}

	&__row-wrapper {
		width: 100%;
		position: relative;
		min-height: 2rem;
	}

	&__left-value {
		box-sizing: border-box;
		min-width: 50px;
		width: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 0.2rem 0 6px;
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		color: $primary-text-color;
		background-color: $dark-pale-blue;
		border-right: 0.125rem solid $white;

		&--blue {
			color: $white;
			background-color: $blue;
		}

		&--red {
			background-color: $red;
		}
		&--amber {
			background-color: $amber;
		}
		&--green {
			background-color: $green;
		}
		&--grey {
			background-color: $dark-pale-blue;
		}

		&--rounded {
			border-top-left-radius: $STRATEGY--border-radius;
			border-bottom-left-radius: $STRATEGY--border-radius;
		}

		&--torn {
			clip-path: $STRATEGY--left-tear-clipping-path;
		}
	}

	&__content {
		flex: auto;
		display: flex;
		align-items: center;
		justify-content: left;
		background-color: $pale-blue;
		padding: 0.4rem;
		width: calc(100% - 50px - 6px);
	}

	&__content-name {
		width: fit-content;
		max-width: 250px;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		color: $primary-text-color;
		margin-right: 1rem;
		cursor: pointer;
	}

	&__content-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__action-button {
		margin: 0 1px;
		height: $STRATEGY--icon-height;
		width: $STRATEGY--icon-height;

		i {
			font-size: $STRATEGY--font-bigger-size;
		}
	}

	&__right-tear {
		width: 6px;
		height: 100%;
		background-color: $pale-blue;

		&--rounded {
			border-top-right-radius: $STRATEGY--border-radius;
			border-bottom-right-radius: $STRATEGY--border-radius;
		}

		&--torn {
			clip-path: $STRATEGY--right-tear-clipping-path;
		}
	}

	&__left-drag-area {
		position: absolute;
		top: 0;
		left: -10px;
		width: 20px;
		height: 100%;
		z-index: 5;
		cursor: ew-resize;
	}

	&__right-drag-area {
		position: absolute;
		top: 0;
		right: -5px;
		width: 15px;
		height: 100%;
		z-index: 5;
		cursor: ew-resize;
	}
}

.roadmap-initiative-expanded-background {
	position: absolute;
	z-index: 1;
	top: 2rem;
	left: 0.3rem;
	min-width: 6rem;
	max-width: 100%;
	box-sizing: border-box;
	height: calc(100% - 2rem);
	transition: $STRATEGY--button-transition;
	border-bottom-left-radius: $STRATEGY--border-radius;
	border-bottom-right-radius: $STRATEGY--border-radius;
	background-color: transparent;
	border: 3px solid $pale-blue;
	border-top: none;
}

.drag-handle-tooltip {
	position: fixed;
	width: 160px;
	height: 60px;
	background-color: $white;
	border-radius: $STRATEGY--border-radius;
	box-shadow: $STRATEGY--shadow-distance-2;
	z-index: 10;

	display: flex;
	justify-content: center;
	align-items: center;

	&:after {
		display: block;
		width: 10px;
		height: 10px;
		position: absolute;
		left: 75px;
		bottom: -5px;
		background-color: $white;
		content: "";
		transform: rotate(45deg);
		box-shadow: $STRATEGY--shadow-distance-2;
		z-index: 11;
	}

	&:before {
		display: block;
		width: 100%;
		height: 15px;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: $white;
		content: "";
		z-index: 12;
		border-radius: $STRATEGY--border-radius;
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding: 0.4rem;
		justify-content: space-between;
		z-index: 13;

		&:last-child {
			border-top-right-radius: $STRATEGY--border-radius;
		}
	}

	&__label {
		font-size: $STRATEGY--font-normal-size;
		font-weight: bold;
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__dates {
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		color: $grey-4;
	}

	&__color-code {
		width: 100%;
		display: flex;
		padding: 0.1rem 0.5rem;
		margin-top: 0.3rem;
		border-radius: $STRATEGY--border-radius;

		span {
			font-size: $STRATEGY--font-smaller-size;
			font-weight: bold;
		}

		&--current {
			background-color: #1b26ed;
			color: $white;
		}
	}
}
