.share-dataset {
	&--member-row {
		&__email {
			margin-left: 3em;
			color: $grey;
		}

		&__team {
			display: flex;
			flex-direction: row;
		}

		&__team-icon {
			margin-right: 0.25em;
		}
	}

	&--modal-select {
		@extend .modal-select;
		border: none;
	}

	&--change-role {
		display: flex;
		justify-content: flex-end;
		width: 100%;

		.react-select__control {
			width: 100%;
		}
	}

	&--permission-btn {
		@extend .btn;
		@extend .btn__p-0;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		cursor: pointer;
		padding: 1px; // to have straight browser outlines

		&__text {
			margin-right: 0.25em;
		}
	}

	&--wrapper {
		@extend .small-flexible-island__item;

		&__select-input {
			flex-grow: 0.5;
			padding-left: 3px;

			.select-arrow {
				color: white;
			}
		}

		&__invite-input {
			flex-grow: 4;
		}
	}
}
