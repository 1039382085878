.editable-field-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;

	&--no-margin {
		margin-bottom: 0;
	}

	&__label {
		display: flex;
		margin-bottom: 0.2rem;
	}

	&__is-updating {
		font-size: 1.2rem;
		color: $blue;
		position: absolute;
		right: -23px;
		top: -1px;
		-webkit-animation: spin 4s linear infinite;
		-moz-animation: spin 4s linear infinite;
		animation: spin 4s linear infinite;
	}

	&__text {
		font-size: $STRATEGY--font-smaller-size;
		font-weight: bold;
		color: $grey-4;
		position: relative;
	}
}

@-moz-keyframes editable-field-wrapper-spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes editable-field-wrapper-spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes editable-field-wrapper-spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
