/**
 * Over-rides styling of some third-party widgets
 */
.react-tabs__tablist {
	@extend .u-reset-list;
	display: flex;
}

.react-tabs__tab {
	@extend .small-island;
	cursor: pointer;
	color: $grey;
	font-weight: 700;
	border-bottom: 2px solid $grey;
	padding-left: 0;
	padding-right: 2em;
	margin-right: 1em;
	display: inline-block;
	vertical-align: bottom;
	border-radius: $border-radius $border-radius 0 0;
	@media #{$small-screen} {
		flex: 1;
	}
}

.react-tabs__tab:focus {
	background: $fadedpink;
}

.react-tabs__tab:hover {
	background: $very-light-grey;
}

.react-tabs__tab:last-child {
	margin-right: 0em;
}

.react-tabs__tab--selected {
	color: $blue;
	border-bottom: 3px solid $red;
}

.react-tooltip-overwrites {
	font-size: 16px;
	padding: 8px;
}
