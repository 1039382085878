.extra-column-controls {
	position: relative;
	top: 40px;
	width: $row-header-width;
	height: $column-header-height;
	display: inline-flex;
	align-items: center;

	.t-vertical {
		min-width: 50px;
	}

	i {
		font-size: medium;
	}

	&--active-column {
		color: $blue;
	}

	&--left {
		margin-right: auto;
	}

	&--right {
		display: inline-flex;
		margin-left: auto;
	}
}
