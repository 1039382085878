.dataset-association--list {
	width: 100%;

	.datasets-table-date {
		max-width: 132px;
	}

	&--tab-container {
		padding-top: 0px;
		display: flex;
		flex-direction: row;

		.dataset-tab {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 35px;
			border: none;
			cursor: pointer;
			margin-right: 10px;
			min-width: 140px;
			max-width: 140px;
			padding-left: 5px;
			transition: 250ms;
			outline: none;
			background: none;
			border-radius: $border-radius;

			.styled-checkbox:not(:checked) + label:before {
				background: none;
			}

			.styled-checkbox + label::before {
				border: 1px solid $primary-text-color;
				border-radius: $border-radius;
			}

			&:last-child {
				margin-right: 0em;
			}

			&:hover {
				background-color: $lightblue;
			}
		}
	}
}
