.outcome-dependency-list {
	& > * {
		margin-bottom: 1rem;
	}

	& > :last-child {
		margin-bottom: 0;
	}
}

.outcome-dependency-title {
	margin: 1rem 0.5rem;
}
