@import "./components/style.scss";

.control-center-outcome-context-menu {
	&__buttons {
		width: 100%;

		button {
			width: 100%;
			font-size: $STRATEGY--font-smaller-size;
			font-weight: normal;
			justify-content: flex-start;
			margin-bottom: 0.5rem;
		}
	}

	.context-modal {
		max-width: 300px;
	}
}

.control-center-outcome-modal {
	.content-modal__header {
		padding-bottom: 1.5rem;
	}

	.content-modal__body {
		max-height: 70vh;
		overflow-y: auto;
	}

	&__content-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		justify-content: stretch;
		flex-wrap: nowrap;
	}

	&__content {
		width: 75%;
		padding-right: 2rem;

		.outcome-dates {
			display: flex;
			width: 100%;

			& > div {
				margin-bottom: 0;
			}

			& > :first-child {
				margin-right: 1rem;
			}
		}
	}

	&__gutter-content {
		width: 24%;
		padding-left: 1rem;
		border-left: 1px solid $grey-1;
	}

	.modal-controls {
		margin-top: 0.5rem;
	}
}

.outcome-drag-wrapper {
	border-radius: $STRATEGY--border-radius;
	cursor: grab;

	&--is-dragging {
		user-select: none;
		box-shadow: $STRATEGY--shadow-distance-2;
	}

	&--unplanned {
		height: 100%;
		width: calc(18rem - 1rem);
		margin: 0.2rem 0.5rem;

		@media #{$desktop-screen} {
			width: calc(14.6rem - 1rem);
		}
	}

	&--no-drag {
		cursor: auto;
	}
}
