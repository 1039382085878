/**
 * inspired by https://codepen.io/Vestride/pen/dABHx
 */

.styled-checkbox {
	z-index: $menu-top-layer; // makesure it is actually clickable
	margin: 0;
	position: absolute;
	opacity: 0;
	width: 21px;
	height: 21px;
	cursor: pointer;
	border-radius: $border-radius;
	&:disabled {
		cursor: default;
	}

	& + label {
		position: relative;
		cursor: pointer;
		transition: 0.3s;
		padding: 0;
		width: 21px;
		border-radius: $border-radius;
	}

	// Box.
	& + label:before {
		content: "";
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		transition: 0.3s;
		border: 1px solid $transparent-black;
		background: white;
		border-radius: $border-radius;
	}

	// Box hover
	&:hover + label:before {
		box-shadow: 0 0 0 4px $transparent-black;
		transition: 0.3s;
		border-radius: $border-radius;
	}

	// Box focus
	&:focus + label:before {
		transition: 0.3s;
		box-shadow: 0 0 0 3px $transparent-black;
		border-radius: $border-radius;
	}

	// Box checked
	&:checked + label:before {
		background: $blue;
		border: 1px solid $blue;
		transition: 0.3s;
		border-radius: $border-radius;
	}

	// Disabled state label.
	&:disabled + label {
		color: $inactive;
		cursor: auto;
		border-radius: $border-radius;
	}

	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		border: 1px solid $inactive;
		background-color: $inactive;
		border-radius: $border-radius;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		content: "";
		position: absolute;
		left: 5px;
		top: 10px;
		background-color: $white;
		width: 2px;
		height: 2px;
		transition: 0.3s;
		box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white, 4px -4px 0 $white, 4px -6px 0 $white,
			4px -8px 0 $white;
		transform: rotate(45deg);
		border-radius: $border-radius;
	}
}

.styled-checkbox-wrapper {
	position: relative;
	display: inline-block;
	border-radius: $border-radius;
}
