$column-header-height: 40px;
$row-header-width: 250px;
$column-width: 100px;
$border: 2px solid #f7f7f7;
$table-height: 500px;
$extra-info-cell-bg: #ebe6f8;
$empty-cell-bg: #f7f7f7;
$extra-positive-cell-bg: #e6f8e7;
$extra-negative-cell-bg: #f8e6ec;

@import "Cell/style";
@import "ColumnHeaders/style";
@import "ExtraColumnControls/style";
@import "Totals/style";
@import "RowHeaders/style";

.table {
	font-size: smaller;

	.t-body {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 100%;
		height: 100%;
	}

	.t-data {
		height: $table-height;
	}

	.t-horizontal {
		display: inline-flex;
		min-height: $column-header-height;
	}

	.t-vertical {
		display: flex;
		flex-direction: column;
	}

	.t-loading-state {
		//display: flex;
		height: 615px;
		//width: 100%;
		justify-content: center;
		align-items: center;
	}

	.t-empty-state {
		//display: flex;
		//flex-direction: column;
		height: 615px;
		width: 100%;
		justify-content: center;
		align-items: center;
		img {
			width: 50%;
		}
		h1 {
			font-weight: bold;
		}
	}
}

// Components that use the classes below are defined in their specific domain.
.data-rows {
	overflow-y: scroll;
	overflow-x: auto;
	display: flex;
	flex-direction: column;

	&__group {
		display: flex;
		flex-direction: column;
		margin-bottom: 4px;
		border-bottom: 1px solid $fadedpink;
	}

	.data-row {
		height: $column-header-height;

		&--sticky {
			background-color: $white;
			position: sticky;
			top: 0;
			left: 0;
			border-right: 1px solid $standardgrey;
		}
	}

	// this controls styles from a different component (Cell) and is not optimal
	// however it is necessary due to IE11 limitations on position:sticky
	.data-row:last-child .cell {
		border-bottom: none;
	}
}
