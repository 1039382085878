.chart-settings_list {
	list-style: none;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	margin: 0;

	.chart-settings__input-box {
		width: 100%;
	}

	.chart-settings__item {
		margin: 0;
		padding: 8px 0px;
		display: inherit;
		justify-content: space-between;
		align-items: center;
		white-space: normal;
	}

	.chart-settings__title {
		padding-right: 5px;
		display: block;
		width: 66.66666%;
	}

	.chart-settings__item-stacked {
		@extend .chart-settings__item;
		flex-direction: column;
		align-items: flex-start;

		// style the external DatePicker component
		.react-datepicker-wrapper {
			width: 100%;

			.react-datepicker__input-container {
				width: 100%;
				> button {
					cursor: pointer;
				}
			}
		}
	}

	.chart-settings__title-stacked {
		padding-bottom: 5px;
	}

	.chart-settings__slider {
		height: 30px;
		width: 100%;
	}

	.chart-settings__color-wrapper {
		position: relative;
		width: 100%;
	}

	.chart-settings__color {
		display: flex;
		cursor: pointer;

		.chart-settings__color-swatch {
			width: 17px;
			height: 17px;
			border: 1px solid $lightgrey;
			border-radius: 3px;
			margin-right: 5px;
		}
	}

	.chart-settings__heatmap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&--colors-input {
			width: 100%;
		}

		&--colors-icon {
			@extend .btn;
			@extend .btn--fill;
			@extend .icon-btn__no-label;
			@extend .small-btn;
			padding: 7px;
		}

		&--color-picker {
			margin-top: 5px;
			position: absolute;
			z-index: 5;
			right: 0;
		}

		&--segment {
			@extend .small-flexible-island;

			&:last-child {
				padding-right: 0;
			}

			&:first-child {
				padding-left: 0;
			}

			&--color {
				padding: 3px;
				position: relative;
			}
		}
	}

	.chart-settings__tree {
		width: 100%;
		border: 1px solid $lightgrey;
		border-radius: 5px;

		.tree-icon {
			border-radius: 0;
			border-bottom-style: none;
			border-top-style: none;
			width: 100%;
			border-right: 1px solid $lightgrey;
			transition: 250ms;

			&:last-child {
				border-right: none;
			}

			&:hover {
				background: $fadedpink;
				transition: 0.3s;
			}
		}
	}
}
