.slider-input {
	width: 50px;
	position: absolute;
	top: 20px;
	font-size: 12px;
}

.slider-wrapper {
	position: relative;
	height: 40px;
}
