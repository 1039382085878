.external-link a {
	color: $primary-text-color;
	padding-bottom: 2px;
	border-bottom: 1px solid $primary-text-color;
	transition: 250ms;

	&:hover {
		color: $blue;
		border-bottom: 1px solid $blue;
	}
}
