.drop-down-btn {
	@extend .btn;
	@extend .btn__p-0;

	&:hover {
		.drop-down-btn--content {
			background-color: $blue;
		}

		.drop-down-btn--icon {
			background-color: $darkblue;
		}
	}

	&--content {
		height: 40px;
		display: flex;
		align-items: center;
		border-radius: $border-radius 0 0 $border-radius;
		border-right: 0.5px solid $darkerblue;
		@extend .btn--fill;
	}

	&--icon {
		height: 40px;
		display: flex;
		align-items: center;
		border-radius: 0 $border-radius $border-radius 0;
		padding-left: 6px;
		padding-right: 10px;
		@extend .btn--fill;
	}
}

button:disabled.drop-down-btn:hover .drop-down-btn--icon {
	background-color: $blue;
}
