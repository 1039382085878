.date-picker-portal {
	z-index: $modal-top-layer;
}

.date-picker-input {
	@extend .input, .small-island;
	cursor: pointer;
	display: flex;
	width: 100%;
}

.react-datepicker__close-icon {
	top: -2px;

	&:after {
		background-color: transparent;
		font-size: 36px;
		color: $primary-text-color;
	}
}
