.markdown-field {
	width: 100%;
	box-shadow: none;
	border-radius: $STRATEGY--border-radius;
	height: auto !important;
	background: $grey-1;
	padding: 0.6rem;

	&--invalid {
		border-color: $red;
	}

	.save-markdown-button {
		display: flex;
		font-weight: bold;
		justify-content: center;
		align-items: center;
	}

	// 3rd party plugin override "react-md-editor"
	.w-md-editor-toolbar {
		height: auto !important;
		background-color: $grey-1;
		border: none;
		padding: 0 0 4px 0;

		& li > button {
			height: 26px;
			padding: 4px 6px;
			border-radius: 4px;
			margin-right: 2px;
			margin-bottom: 4px;
			font-size: 14px;

			& > svg {
				width: 15px;
				height: 15px;
			}
		}

		& li.active > button,
		& li > button:hover {
			color: $white;
			background-color: $blue;
		}
	}

	.w-md-editor-content {
		font-size: $STRATEGY--font-normal-size;
		font-family: century-gothic, Arial, sans-serif;
		height: auto !important;
		max-height: 40vh;
		display: flex;
		background: white;
		border-radius: $STRATEGY--border-radius;

		.w-md-editor-input {
			height: auto !important;
			min-height: 140px;
			max-height: 40vh;
			flex: auto;
		}
	}

	.w-md-editor-text {
		font-size: $STRATEGY--font-normal-size;
		font-family: century-gothic, Arial, sans-serif;
		line-height: 1.3;
	}

	.w-md-editor-preview {
		padding: 10px;
	}

	.w-md-editor-bar {
		display: none;
	}

	.wmde-markdown {
		font-size: $STRATEGY--font-normal-size;
		font-family: century-gothic, Arial, sans-serif;

		p {
			font-size: $STRATEGY--font-normal-size;
		}

		h1 {
			font-size: 1.6rem;
		}

		h2 {
			font-size: 1.4rem;
		}

		h3 {
			font-size: 1.2rem;
		}

		h4 {
			font-size: 1.1rem;
		}
	}
}

.markdown-content {
	width: 100%;
	height: 100%;
	font-family: century-gothic, Arial, sans-serif;
	font-size: $STRATEGY--font-normal-size;

	&--none {
		color: $grey-3;
	}

	p {
		font-size: $STRATEGY--font-normal-size;
	}

	h1 {
		font-size: 1.6rem;
	}

	h2 {
		font-size: 1.4rem;
	}

	h3 {
		font-size: 1.2rem;
	}

	h4 {
		font-size: 1.1rem;
	}
}

.markdown-edit-button {
	position: absolute;
	top: -6px;
	right: -35px;
}

.markdown-wrapper {
	&__label {
		cursor: pointer;
		margin-bottom: 0.5rem;
	}
}

.markdown-control-button {
	white-space: nowrap;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;

	&--save {
		color: $blue;
		margin-left: 10px;
	}

	&--cancel {
		margin-left: 10px;
	}
}
