@import "../../../../lib/style/breakPoints";

.report-row--meta-data {
	@include hide;
	font-size: smaller;
	color: $grey;
	height: 15px;
	display: none;

	@media #{$mobile-break-point} {
		@include show;
	}
}
