/*=====================*/

.checkbox {
	position: relative;
	display: inline-block;

	input {
		z-index: $modal-top-layer;
	}

	&:after,
	&:before {
		font-family: FontAwesome;
		font-feature-settings: normal;
		font-kerning: auto;
		font-language-override: normal;
		font-stretch: normal;
		font-style: normal;
		font-synthesis: weight style;
		font-variant: normal;
		font-weight: normal;
		text-rendering: auto;
	}

	label {
		width: 90px;
		height: 42px;
		background: $standardgrey;
		position: relative;
		display: inline-block;
		border-radius: (46px);
		transition: 0.2s;
		&:after {
			content: "";
			position: absolute;
			width: 50px;
			height: 50px;
			border-radius: (100%);
			left: 0;
			top: -5px;
			z-index: $menu-top-layer;
			background: $white;
			box-shadow: (0 0 5px $transparent-black);
			transition: 0.2s;
		}
	}
	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		&:hover + label:after {
			box-shadow: (0 2px 15px 0 $transparent-black, 0 3px 8px 0 $transparent-black);
		}
		&:checked + label {
			&:after {
				left: 40px;
			}
		}
	}
}

.toggle-button {
	.checkbox {
		label {
			background: $transparent-brightblue;
			height: 25px;
			width: 55px;
			&:after {
				background: $blue;
				top: -2px;
				width: 29px;
				height: 29px;
				// no pointer evens so that the target underneath can be clicked to toggle
				pointer-events: none;
			}
		}
		input:checked + label {
			background: $translucent-brightblue;
			&:after {
				background: $blue;
				left: 27px;
				// no pointer evens so that the target underneath can be clicked to toggle
				pointer-events: none;
			}
		}
	}
}

.toggle-button__long {
	width: 100%;
	height: 25px;
	border-radius: 100px;
	border: none;
	display: flex;
	align-items: center;
	margin-bottom: 19px;
	position: relative;
	cursor: pointer;
	z-index: 1;
	transition: 0.4s;

	&:focus {
		box-shadow: 0 0 1pt 0pt $almostblack;
	}

	&.un-checked {
		background-color: $transparent_brightblue;
		transition: all 300ms;
	}

	&.checked {
		background-color: $translucent-brightblue;
		transition: all 300ms;
	}
}

.toggle-button__slider {
	width: 27px;
	padding: 0;
	height: 27px;
	background-color: $blue;
	border-radius: 100%;
	transition: 0.4s;
	z-index: -2;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	&.un-checked {
		left: 0;
		transition: all 0.3s;
		transform: translateX(0);
		box-shadow: 1px 1px 6px 1px $translucent-black;
	}

	&.checked {
		left: 100%;
		transform: translateX(-100%);
		transition: all 0.3s;
		box-shadow: -1px 0px 5px 1px $translucent-black;
	}
}
