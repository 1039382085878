$padding-unit-horizontal: 1em;
$padding-unit-vertical: 1ex;
$query-builder-option-height: 39px;
$query-builder-header-height: 45px;
$query-builder-padding: 16px;

.rules {
	position: relative;
}

%query-builder-focus {
	outline: 0;
	border: 1px transparentize($color: $kiplot-blue, $amount: 0.6) solid;
}

.query-builder {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 97vh;
}

.query-builder-nav {
	@extend .box-shadow;
	display: flex;
	flex-direction: row;
	background: $blue;
	justify-content: flex-start;
	align-items: center;
	color: $white;
	min-height: $query-builder-header-height;
	max-height: $query-builder-header-height;

	.query-builder-nav__headers {
		display: flex;
		flex-direction: row;

		> p {
			padding: 2px 0px 0px 0px;
			margin: 0;
		}
		> h3 {
			padding: 0px 10px;
			margin: 0;
		}
	}
	.back-btn {
		border-radius: 50%;
	}
}

.query-builder-body {
	padding: $padding-unit-vertical $padding-unit-horizontal;
	// this has to be equal to the size of the side panel
	margin-left: 376px;
	overflow: auto;
	height: 100%;
	fieldset {
		border: none;
	}

	.green {
		color: $limegreen;
	}

	.red {
		color: $red;
	}

	.blue {
		color: $blue;
	}

	.orange {
		color: $warning;
	}

	label {
		margin-left: $padding-unit-horizontal;

		&:first-child {
			margin-left: inherit;
		}
	}

	.results {
		overflow: auto;
	}

	h3 {
		margin-top: inherit;
		margin-bottom: $padding-unit-vertical * 2;
	}

	.pill {
		font-family: monospace;
		white-space: pre;
		padding: 6px;
		background: $fadedpink;
		border-radius: $border-radius;
		border: 1px solid $lightgrey;
	}

	.entities-table-outer {
		height: 310px;

		td,
		th {
			/* important to show people whitespace if data/column names have whitespace - to indicate where quoting is required etc */
			white-space: pre;
		}
		.filter {
			button {
				margin-left: 5px;
			}
		}
	}

	.created-dataset {
		color: $grey;
	}
}

.query-input {
	width: 100%;
	box-sizing: border-box;
	min-height: 200px;
	margin-bottom: $padding-unit-vertical * 2;
}

.card-info {
	padding-left: 10px;
}

.truncate-results-control {
	padding: 10px;
	margin-right: 70px;
}

.query-overlay-card {
	background-color: $white;
	width: 100%;
	left: 0;
	margin: 0;
	height: 400px;
	border-top: $very-light-grey solid 1px;

	.card-content {
		padding: $query-builder-padding;
	}
	i {
		margin-right: 10px;
		font-size: $query-builder-padding;
	}
	.overflow {
		overflow: auto;
		flex: 1;
		margin-right: 1em;
	}
	.overlay-results {
		display: flex;
		flex-direction: row;
	}

	&:hover {
		bottom: 0px;
	}
	&.no-hover:hover {
		bottom: -320px;
	}
}

.result-header {
	h2,
	i,
	label {
		display: inline-block;
	}
}

.query-builder-join_select {
	width: 300px;
}

.query-builder-card {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 8px;

	.card-content {
		padding: $query-builder-padding;
		> h2 {
			display: inline-block;
		}
	}

	&.rule-card {
		.card-content {
			padding: 1.5px $query-builder-padding;
		}
	}
}

.query-form {
	position: relative;
	padding: $query-builder-padding;
	// blot out everything
	&.full-screen {
		position: fixed;
		overflow: auto;
		height: 100%;
		width: 100%;
		padding-top: 0px !important;
		top: 0;
		left: 0;
		z-index: $modal-mid-layer;
		background: $white;
		padding: $query-builder-padding;
	}

	h3 {
		margin: 0px 0px 7px 0px;
	}
	.description {
		display: flex;
		flex-direction: column;
		.toolbar__wrapper {
			padding: 5px 0px;
			position: relative;
			display: flex;
			flex-direction: row;
			height: 45px;
			width: 100%;
			align-items: center;
		}

		.item {
			padding: 5px 0px;
			width: 100%;
		}
	}
}
.row {
	display: flex;
	align-items: center;
	label {
		margin: 0 10px;
	}
}

.custom-fields {
	.text-area-autocomplete-container {
		height: $query-builder-option-height;
	}
	.text-area-autocomplete {
		width: 400px;
		padding: 10px 5px;
		resize: none;
		background-color: $white;
		height: $query-builder-option-height;
		border: 1px solid $lightgrey;
		border-radius: $border-radius;
	}
	.label-as {
		margin: 5px 10px;
	}
	.text-area-autocomplete-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		background: $white;
		border: 1px solid $standardgrey;
		border-radius: $border-radius;
		height: $query-builder-option-height;
		display: flex;
		align-items: center;
	}
	.text-area-autocomplete-item {
		padding: 5px;
		cursor: pointer;
		&:hover {
			background-color: lightgray;
		}
	}
}

.rules-container {
	position: relative;
	border: 1px solid $lightgrey;
	border-radius: $border-radius;
	padding: 20px 10px !important;

	.close-rule {
		position: absolute;
		top: -12px;
		right: -18px;
	}
}
.query-builder-card {
	button.pill {
		padding: 1px 5px;
	}
}
.rule-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	width: 100%;
	border-radius: $border-radius;

	.input {
		background-color: $white;
		height: $query-builder-option-height;
		z-index: auto;
	}

	&:nth-child(odd) {
		background-color: $fadedpink;
	}
	.rule {
		.row {
			align-items: center;

			@media screen and (max-width: 1200px) {
				align-items: flex-start;
				flex-direction: column;
			}
		}
	}
}

%query-builder__side-panel-form {
	background-color: $white;
	padding: 6px;
	border: 1px solid $lightgrey;
	border-radius: $border-radius;
}

.revision-time-form__wrapper {
	.revision-time-form {
		@extend %query-builder__side-panel-form;

		.revision-time-submit-btn {
			align-items: center;
			flex-direction: row;
			justify-content: space-around;

			> .btn {
				width: 125px;
			}
		}

		.absolute-date-picker {
			padding: 30px 0px !important; // overide `QueryBuilderSection padding`
		}
	}

	.relative-option-label {
		& > span,
		select,
		input {
			font-size: smaller;
			margin: 0.1em;
		}
		input[type="number"] {
			width: 55px;
		}
		input[type="radio"] {
			margin-right: 10px;
		}
	}
	.revision-time-footnote {
		padding-top: 0.5em;
		display: block;
		font-size: smaller;
		color: $grey;
	}

	.react-datepicker-wrapper {
		width: 100%;
		.react-datepicker__input-container {
			width: 100%;

			.select-date {
				width: 100%;
				outline: 0;
				background-color: $white;

				&:focus {
					@extend %query-builder-focus;
				}
			}
		}
	}
}

.query-builder__top-section {
	padding-top: 0px;
}

.query-builder-section {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 0px;

	&.row {
		flex-direction: row;
	}

	&.derived-dataset-form {
		margin-top: 10px;
		@extend %query-builder__side-panel-form;
	}

	&.toolbar__inner {
		height: 45px;
		justify-content: center;
		padding: 0px;
	}

	&.toolbar {
		background-color: $fadedpink;
		max-width: 135px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;

		.toggle-button {
			padding-left: 10px;
			.toggle-button__long {
				margin-bottom: 0px;
				width: 60px;
			}
			.toggle-button__slider {
				&.un-checked {
					background-color: $red;
				}
			}
		}

		.full-screen__btn {
			border-radius: 100%;
			height: 27px;
			width: 27px;
			padding: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.select-option {
		width: 100%;
		background: white;
		border: 1px solid $lightgrey;
		border-radius: $border-radius;
		position: relative;
		height: $query-builder-option-height;

		&.btn:focus {
			@extend %query-builder-focus;
		}
	}

	.relative-time-form__wrapper {
		width: 100%;
		overflow: auto;

		.relative-time-form {
			min-width: 420px;
		}
	}

	&.table-wrapper {
		max-height: 260px;
		overflow: auto;
		padding: 0px;
		margin: 10px 0px;
		background-color: $white;

		.select-label {
			background-color: $fadedpink;
		}
	}

	.select-label {
		padding-bottom: 8px;
		display: flex;
		align-items: center;

		> i {
			font-size: 16px;
		}
	}
}

.query-builder-side-panel {
	padding-bottom: 150px;
	font-size: smaller;

	.concertina-menu__btn {
		@extend .btn;
		padding: 0px;
		display: flex;
		outline: 0;
		border: 0px;
	}

	.concertina-menu__list-item {
		font-size: small;
	}

	.switch-dataset-wrapper {
		position: relative;
		width: 100%;

		.alias-name {
			font-weight: $bold-font;
			font-size: 16px;

			&.error {
				border-bottom: 1px solid $red;
			}
		}

		.remove-dataset-btn {
			position: absolute;
			right: 0;
		}
	}

	.query-builder__column-table {
		font-size: small;

		.table-header {
			padding: 0.3rem 0.33rem;
			background-color: $fadedpink;
		}

		.table-row {
			height: 30px;
		}
	}
}

.query-builder__divider {
	border: 1px solid $white;
}

.select-date-form {
	width: 100%;
	border: 1px solid $lightgrey;
	border-radius: $border-radius;
	background: $white;

	.btn.select-option {
		border: none;
	}

	.react-tabs__tablist {
		padding: 0.5em;
	}

	.revision-time-footnote {
		color: $grey;
	}

	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input,
	.input {
		width: 100%;
	}
}
