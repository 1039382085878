.skeleton-loading {
	&__row {
		width: 100%;
		animation: fade-in 220ms ease-in;
		transition: 120ms;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: row;
		height: 40px;
		margin-bottom: 10px;

		&:first-child {
			margin-top: 20px;
		}
	}

	&__row-small {
		height: 40px;
	}

	&__row-medium {
		height: 65px;
		margin-bottom: 15px;
	}

	&__row-big {
		height: 90px;
		margin-bottom: 20px;
	}

	&__row-huge {
		height: 300px;
		margin-bottom: 50px;

		.skeleton-loading__item {
			margin: 50px 40px;
		}
	}

	&__item {
		height: 100%;
		flex: 1;
		border-radius: $border-radius;
		margin: 0px 10px;
		background: linear-gradient(to right, $very-light-grey 20%, $standardgrey 50%, $very-light-grey 80%);
		animation: moving-gradient 1.4s ease-in-out infinite;
		animation-direction: alternate;
	}

	&__centered_wrapper {
		position: relative;
		width: 1400px;
		margin: 100px auto;
		overflow: hidden;
	}
}
