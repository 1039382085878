@import "components/DatasetList/style";
@import "components/DeriveDatasetButton/style";
@import "components/DatasetTabs/style";
@import "components/SortControls/style";
@import "components/SyncAllDatasetsButton/style";

.datasets-no-items {
	text-align: center;
	font-size: 1.2em;
}

.empty-field {
	// Needed to overide input styles
	border-color: $red !important;
}

.page-list-item.dataset-list-item {
	background-color: $fadedpink;
	border-bottom: 4px solid $white;
	padding-left: 0.5em;

	@media #{$small-screen} {
		flex-direction: column;

		& > div {
			justify-content: flex-start;
		}
	}
}

@import "../../components/DatasetSidePanel/style";

.dataset-list-item .icon-btn__no-label:hover {
	background-color: $lightgrey;
}

.dataset-modal {
	background-color: $white;
}

.dataset-modal__content {
	height: 100%;
	width: 100%;
	box-shadow: none;
}

.dataset-modal__wrapper {
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.dataset-modal__inner {
	max-width: 620px;
	padding: 0 1em;
	width: 100%;
}

.dataset-list__wrapper {
	padding-bottom: 60px;
}
