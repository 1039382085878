@import "../../../../../../lib/style/breakPoints";

&--sync-status {
	@include hide;
	@media #{$mobile-break-point} {
		@include show;
	}
	cursor: pointer;

	&__in-progress {
		border-bottom: 1px solid;
	}

	&__error {
		color: $red;
		border-bottom: 1px solid;
	}
}
