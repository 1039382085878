.drag-drop-area {
	&:hover,
	&.drag-file-select-hover {
		border-color: $grey;
	}

	transition: border-color 0.25s ease-in-out;
	border: 4px dashed $grey;
	display: block;
	min-height: 150px;
	min-width: 300px;
	position: relative;
	background: $lightgrey;
	text-align: center;
	padding: 15px 10px;
	margin: 10px 0;
	cursor: pointer;
}

.drag-drop-allowed-formats {
	margin: 0px;
	margin-top: 4px;
	color: $darkgrey;
	font-style: italic;
}

.drag-drop-label {
	pointer-events: none;
	margin-top: 60px;
}

.file-select-input {
	visibility: hidden;
}

.upload-modal {
	@extend .horizontal-list--space-evenly;
	height: 330px;
	max-width: 610px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	padding-bottom: 40px;

	.upload-modal-sub-title {
		font-size: 1.17rem;
	}
}

.result-modal {
	@extend .upload-modal;
	height: auto;
}

.data-uploader-input {
	padding: 10px 0px;
	margin-top: 10px;
	width: 100%;
	border: 0;
	border-bottom: 1px solid $lightgrey;
}

.upload-img {
	width: 40%;
	max-width: 300px;
}

.upload-header {
	max-width: 300px;
	font-weight: 600;
}

.dataupload-btn {
	min-width: 100px;
}

.select-sheet-modal {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.select-sheet-table__wrapper {
	@extend .datasets-table__wrapper;
	margin-top: 10px;
	border: 1px solid $dark-pale-grey;
	width: 100%;
}

.select-sheet-table__row {
	@extend .datasets-table__item;
	&:hover {
		background: $fadedpink;
	}

	.select-sheet-table__cell {
		padding: 8px 25px;

		&.disabled {
			background-color: $inactive;
		}

		&.selected {
			background-color: $lightgrey;
		}
	}
}
