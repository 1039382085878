@import "components/ReadOnlyInfoForm/style";

.project-detail-info-wrapper {
	@import "components/style";

	width: 100%;

	@media (min-width: 1200px) {
		padding-left: 10%;
		padding-right: 10%;
	}
}
