.color-select {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;

	&__input {
		flex-grow: 3;
	}

	&__color-picker {
		margin-left: 1em;
		flex-grow: 1;
	}

	&--colors-icon {
		@extend .btn;
		@extend .btn--fill;
		@extend .icon-btn__no-label;
		@extend .small-btn;
		padding: 7px;
		color: white;

		&:hover,
		&:focus {
			background-color: $darkblue;
			border-color: $darkblue;
		}
	}
}
