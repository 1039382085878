.white-box {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: $white;
	border-radius: $STRATEGY--border-radius;
	position: relative;

	&--condensed {
		padding: 0.5rem 1rem;

		.white-box-content {
			margin: 0.5rem 0;
		}
	}

	&--with-gutter {
		margin-bottom: 1rem;
	}

	&__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: $STRATEGY--overlay-content-layer;
		border-radius: $STRATEGY--border-radius;

		/* slightly transparent fallback */
		background-color: rgba(255, 255, 255, 0.65);

		/* if backdrop support: very transparent and blurred */
		@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
			background-color: rgba(255, 255, 255, 0.65);
			-webkit-backdrop-filter: blur(1.5px);
			backdrop-filter: blur(1.5px) brightness(90%);
		}
	}
}

.white-box-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	&__title {
		display: flex;
		flex: auto;

		h4 {
			@extend .text-ellipsis;
			margin-top: 3px;
			margin-right: 0.5rem;
		}
	}

	&__controls {
		flex: initial;
		display: flex;
		justify-content: flex-end;
	}
}

.white-box-content {
	margin-top: 1rem;
}

.empty-list {
	width: 100%;
	height: 100%;
	min-height: 6rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;

	&__title {
		margin-bottom: 0.5rem;
		color: $grey-5;
	}

	&__message {
		margin-bottom: 2rem;
		color: $grey-4;
		font-size: $STRATEGY--font-bigger-size;
	}

	&__call-to-ation {
		display: flex;

		& > * {
			margin-right: 1rem;
		}
	}
}
