$report-row-height: 72px;

.report-row {
	height: $report-row-height;
	border-bottom: 0.5px solid $lightgrey;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.6em 0;
}

@import "DeleteReportModal/style";
