@import "../../../../lib/style/breakPoints";

.sync-all-datasets__button {
	.btn--hollow {
		font-weight: 400;
		padding-right: 1em;
		padding-left: 1em;
		text-align: left;
		width: 100%;
		display: flex;
		justify-content: flex-start;

		&:hover {
			background-color: $very-light-grey;
		}

		&:focus {
			background: $fadedpink;
		}

		@media #{$mobile-break-point} {
			border: 1px solid $very-light-grey;
			border-radius: $border-radius;
			padding: 3px;
		}

		.custom-icon {
			@include hide;
			font-size: 20px;

			@media #{$mobile-break-point} {
				@include show;
			}
		}
	}

	.sync-all-datasets__button-inner {
		@extend .small-flexible-island__item;
		display: flex;
		width: 110px;
		justify-content: flex-start;

		@media #{$mobile-break-point} {
			justify-content: space-around;
		}
	}
}
