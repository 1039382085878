@import "../../../../lib/style/breakPoints";

$medium-screen: "(max-width: 1300px)";

.dataset-tab__wrapper {
	@extend .horizontal-island-margin;

	@media #{$medium-screen} {
		display: flex;
		flex-direction: column-reverse;
	}

	@media #{$mobile-break-point} {
		display: flex;
		align-items: center;
	}

	.dataset-groups-mobile--context-menu__menu {
		right: inherit;
	}

	.dataset-groups-mobile {
		@include show;

		@media #{$mobile-break-point} {
			@include hide;
		}
	}

	.dataset-groups--desktop {
		width: 50%;

		@media #{$medium-screen} {
			width: 100%;
		}
	}

	.dataset-groups--desktop,
	.dataset-sort-sync__wrapper {
		@include hide;
		@media #{$mobile-break-point} {
			@include show;
			display: flex;
		}
	}

	.dataset-sort-sync__wrapper {
		justify-content: flex-end;
		width: 58%;
		align-items: center;

		@media #{$medium-screen} {
			width: 100%;
			padding-bottom: 30px;
			justify-content: flex-start;
		}
	}

	.dataset-tab {
		height: 35px;
		border: none;
		cursor: pointer;
		margin-right: 10px;
		min-width: 140px;
		max-width: 140px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: 5px;
		transition: 250ms;
		outline: none;
		border-radius: $border-radius;
		background-color: $fadedpink;

		.styled-checkbox:not(:checked) + label:before {
			background: none;
		}

		&:last-child {
			margin-right: 0em;
		}

		&:hover {
			background-color: $lightblue;
		}

		&.selected {
			background-color: $fadedblue;
		}
	}
}
