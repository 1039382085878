@import "OutcomeInfo/style.scss";

.stage-info {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto 180px auto auto;
	grid-template-areas:
		"header header header"
		"display display display"
		"display-text display-text display-text"
		"left-nav center-nav right-nav";
	place-items: stretch;
	font-size: small;

	width: 300px;

	&__header {
		grid-area: header;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}

	&__display {
		grid-area: display;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0.5rem;

		img {
			width: 100%;
		}
	}

	&__text {
		grid-area: display-text;
		display: flex;
		margin-top: 0.5rem;
	}
}
