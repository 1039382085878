@import "Table/style";
@import "ControlButtons/style";
@import "InitiativePageHeader/style";

.year-controls {
	display: inline-flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.banner {
	background-color: $kiplot-blue;
	color: $white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5em;
	border-bottom: 1px solid $white;
	border-radius: 0 0 $border-radius $border-radius;
}
