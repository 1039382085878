.failed-visualisation {
	@extend .interactive;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: medium;

	&--error {
		@extend .font-bold;
		color: $red;
		font-size: medium;
	}

	&__button-wrapper {
		margin-top: 0.5rem;
	}
}
