// Normally hide the print specific components
.print-header,
.print-header__wrapper,
.print-outline {
	display: none;
}
.print-controls-btn {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1;
	background: rgba(255, 255, 255, 0.8);
}

@mixin print-style() {
	// Hide elements that should not be printed
	.report-controls,
	.report-header,
	.configuration-tile,
	.visualization-controls,
	.color-option--inactive,
	.color-option--value-not-present,
	.mark-tooltip,
	.add-tile-portal,
	.report-page-nav,
	.report-nav-pages,
	.report-page-nav-placeholder,
	.report-nav-indicators,
	.table__entity-delete-btn,
	.add-entity-icon,
	#chatlio-widget {
		display: none !important;
	}

	// Restyle elements to look better on print
	.cell-used__inner {
		border-bottom: 1px solid $lightgrey;
	}
	.cell-used__box-shadow {
		box-shadow: none !important;
		border: none !important;
	}
	.visualization-source {
		opacity: 1;
		color: $lightgrey;
		pointer-events: none;
	}
	.mark {
		pointer-events: none;
	}
	.text-node {
		&:hover {
			cursor: default;
			box-shadow: none !important;
		}
	}
	.text-widget--markdown--overflow {
		overflow: hidden;
		background: none;
		padding-right: 0;
	}
	.visualization-toolbar {
		padding: 0;
		font-size: 12px;
	}
	.visualization-toolbar__styled-headers .content-editable {
		font-size: inherit;
	}
	.visualization-toolbar__styled-headers--text-tile {
		margin: -4px -4px 0;
	}
	.fixedDataTableLayout_main .fixedDataTableRowLayout_main:not(.fixedDataTableLayout_header):hover {
		background: transparent;
	}

	// Print header styling
	.print-header__wrapper {
		display: flex;
		flex-direction: column;

		// Add a header for the print version
		.print-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: baseline;
			padding-bottom: 10px;
			border-bottom: 1px solid $grey;

			&__report-name {
				font-weight: 700;
				font-size: 28px;
			}

			&__page-name {
				font-size: 28px;
			}

			&__date {
				font-size: 28px;
			}
		}
		.print-client-logo {
			max-height: 50px;
			max-width: 200px;
			margin-right: 24px;
		}
		.print-title {
			padding: 0 12px;
		}
		.print-powered-by-text {
			font-weight: 700;
			margin-right: 10px;
			margin-bottom: 2px;
		}
		.print-powered-by-logo {
			height: 40px;
			width: auto;
			margin-bottom: -10px;
		}
	}
}

// PDF Preview mode adds print-to-pdf class to body tag
.print-to-pdf {
	@include print-style();

	.print-windows-browser__banner {
		height: 37px;
		margin-top: -10px;
		z-index: $toaster;
	}

	.print-outline {
		display: block;
		border: 2px dashed $grey;
		position: absolute;
		left: 0;
	}
}

// Using the browser print
@media print {
	@include print-style();

	// Adjust colors for print - they can be hidden/white otherwise
	* {
		-webkit-print-color-adjust: exact; // For Chrome
		color-adjust: exact; // For Firefox
	}

	// Hide the controls, warning banner, page outlines in the print output
	.print-windows-browser__banner,
	.side-panel,
	.print-controls-btn,
	.print-outline {
		display: none !important;
	}
}
