.strategy-page-header {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 2rem;

	&__title {
		max-width: 70%;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 0;
	}

	&__editable-title {
		max-width: 100%;
	}
}
