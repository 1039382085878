.strategy-table {
	$cell-padding: 0.3rem;
	$cell-horizontal-padding: calc(
		#{$STRATEGY--field-horizontal-padding} + #{$STRATEGY--field-border-width}
	);

	thead {
		color: $grey-4;
		height: calc(#{$STRATEGY--field-height} + 6px);

		tr {
			border-bottom: none;

			& > :last-child {
				padding-right: 0;
			}

			& > :first-child {
				padding-left: 0;
			}
		}

		th {
			font-size: $STRATEGY--font-smaller-size;
			padding: $cell-padding $cell-horizontal-padding 0 $cell-horizontal-padding;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid $grey-1;

			&:last-child {
				border-bottom: none;
			}

			td:first-child {
				padding-left: 0;
			}

			td:last-child {
				padding-right: 0;
			}
		}

		td {
			height: calc(#{$STRATEGY--field-height});
			padding: $cell-padding $cell-horizontal-padding $cell-padding $cell-horizontal-padding;
			font-size: $STRATEGY--font-normal-size;

			&.medium-width {
				width: 180px;
			}
		}

		.editable-cell {
		}
	}
}
