.list-page-controls {
	display: flex;
	padding: 0.5rem;
	border-radius: $STRATEGY--border-radius;
	background-color: $grey-2;
	margin-bottom: 1rem;
	justify-content: space-between;
	align-items: center;

	&__left {
		display: flex;

		& > * {
			margin-left: 0.5rem;
		}

		& :first-child {
			margin-left: 0;
		}
	}

	&__right {
		margin-left: auto;
	}
}
