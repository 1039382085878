.report-page-nav {
	@extend .interactive;
	z-index: $report-nav-layer;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 45px;
	display: flex;
	justify-content: center;
	background-color: $kiplot-blue;
	color: $white;

	.report-page-nav__inner {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}

	.report-page-nav__content {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.report-page-nav__logo {
		height: 25px;
		width: 72px;
		margin-top: 5px;
	}
}

.report-page-nav-placeholder {
	height: 45px;
	width: 100%;
	background-color: $kiplot-blue;

	@media #{$small-screen} {
		height: 0;
	}
}

.report-nav__item {
	@extend .horizontal-list;
	@extend .horizontal-list--center;
	height: 100%;
	font-size: smaller;

	.duplicate-page-btn,
	.delete-page-btn {
		padding: 5px;
	}

	.page-btn {
		@extend .btn;
		display: flex;
		flex-direction: column;
		text-transform: capitalize;
		min-width: 62px;

		&:hover,
		&:focus,
		&.active {
			background: $deep-blue;
		}
	}

	.page-btn-drop-down {
		padding: 0.4em;
		border-left: 1px solid $white;
		display: none;
	}
}

@media screen and (min-width: 540px) {
	.report-nav__item {
		.page-btn {
			flex-direction: row;
			border-radius: $border-radius;
			padding: 0;
			margin: 0 1em;
			border: 1px solid $white;
		}

		.page-btn-title {
			padding: 0.5em;
			padding-left: 1em;
		}

		.page-btn-text {
			padding: 0.5em;
			padding-right: 1em;
		}

		.page-btn-drop-down {
			display: flex;
		}
	}
}

.report-nav__divider {
	height: 34px;
	width: 1px;
	background: $white;
}

.report-titles {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: smaller;

	.report-title {
		font-weight: 600;
		margin-right: 0.5em;

		@media screen and (max-width: 450px) {
			overflow: hidden;
			word-wrap: break-word;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 200px;
			// this gets pollyfilled for Firefox / safari / Chrome
			width: min-content;
			// this is for IE11 / Edge
			display: -ms-grid;
			-ms-grid-columns: min-content;
		}
	}

	.report-title-for-page {
		padding-left: 8px;
		height: 80%;
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 450px) {
			display: none;
		}

		&__name {
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 150px;
			overflow: hidden;

			@media screen and (max-width: 1090px) {
				&:nth-of-type(2),
				&:nth-of-type(3) {
					display: none;
				}
			}

			@media screen and (max-width: 930px) {
				&:nth-of-type(1) {
					display: none;
				}
			}
		}
	}

	.content-editable-can-edit.report-title {
		margin-top: 3px;
		margin-right: 0;
	}
}

.report-nav__disks {
	@extend .small-flexible-island;
	display: none;
	flex-direction: row-reverse;
	align-items: center;
}

@media screen and (min-width: 768px) {
	.report-nav__disks {
		display: flex;
	}
}

.report-nav__disk {
	border: 1px solid $white;
}

.report-nav__disk-message {
	margin-left: 8px;
}

.page-strip {
	@extend .u-reset-list;
	@extend .horizontal-list;
	display: flex;
	flex-direction: column;

	& > li {
		margin: 5px;
	}
}

.page-strip__overflow {
	max-height: 335px;
	box-shadow: inset 0px 8px 8px -10px #ccc, inset 0px -8px 8px -10px #ccc;
	overflow-y: auto;
	overflow-x: hidden;
}

.page-strip__move-icon {
	font-size: 16px !important; // Overwrite iconfont font size
}

// Safari 10.1+ (alternate method)
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.page-strip__overflow {
			padding-right: 1em;
		}
	}
}

// Firefox
@-moz-document url-prefix() {
	.page-strip__overflow {
		padding-right: 1em;
	}
}

.report-pages__menu {
	padding: 0px;
	padding-bottom: 10px;
	margin-top: 7px;
	max-height: calc(100vh - 45px);
	overflow: auto;

	.horizontal-divider {
		width: 100%;
		height: 1px;
		border: none;
		background: $lightgrey;
	}
}

@media screen and (max-width: 540px) {
	.report-pages__menu {
		position: fixed;
		margin-top: 0;
		left: 0;
		right: auto;
	}
}

.page-strip__item {
	border: 1px solid $lightgrey;
	width: 300px;
	display: flex;
	border-radius: $border-radius;
	align-items: center;
	padding-left: 5px;
	height: 35px;
	margin: 5px;

	.page-name {
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
	}

	&.strip-btn {
		border: none;
		justify-content: flex-start;
		margin-top: 0.5em;

		&.dashed-btn {
			border: 1px dashed $lightgrey;
		}
	}
}

.page-strip__item-selected {
	border: 1px solid $kiplot-blue;
	background-color: $kiplot-blue;
	color: $white;

	.icon-btn:hover,
	.icon-btn:focus {
		background: $darkblue;
	}
}

.share-icon-btn {
	display: flex;
}

.share-btn {
	display: none;
}

@media screen and (min-width: 768px) {
	.share-icon-btn {
		display: none;
	}

	.share-btn {
		display: flex;
	}
}

.report-nav-search {
	display: flex;
	flex-direction: row;
	border-radius: 50px;
	margin-left: 1em;
	height: 32px;
	border: none;
}

.report-nav-search__active {
	color: $primary-text-color;
	background: white;
	border: 1px solid $lightgrey;
}

@media screen and (max-width: 600px) {
	.report-nav-search__active {
		position: absolute;
		width: calc(100% - 10px);
		left: 0;
		margin: 0 5px;
		z-index: $menu-mid-layer;
	}
}

.report-nav-search-input {
	transition: width 0.1s ease-in-out;
	width: 0px;
	border: none;
	background: transparent;
}

.report-nav-search-input__active {
	width: 220px;
	outline: none;
}

.report-nav-search-button {
	width: 32px;
	height: 32px;

	i {
		font-size: 20px;
		margin-top: -3px;
	}
}

.report-nav-pages {
	@extend .interactive;
	display: flex;
	align-items: center;
	flex-direction: row;
	font-size: smaller;
	overflow-x: auto;
	overflow-y: hidden;
}

.report-nav-page-btn {
	display: flex;
	margin-right: -1em;
	visibility: hidden;

	i {
		font-size: 14px;
	}
}

.report-nav-page {
	@extend .small-flexible-island;
	display: flex;
	align-items: center;
	background: $fadedpink;
	border-right: 1px solid $lightgrey;
	padding: 0.2em 1em;
	min-width: 80px;
	border-bottom: 2px solid transparent;
	height: 100%;
	overflow: hidden;
	height: 44px;
}

.link-box:hover .report-nav-page-btn {
	visibility: visible;
}

.report-nav-page__active {
	background: $white;
	border-bottom: 2px solid $kiplot-blue;
	font-weight: 700;

	.report-nav-page-btn {
		visibility: visible;
	}
}

.report-nav-page-highlight-name {
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 150px;
	overflow: hidden;
}

.report-nav-indicators {
	@extend .interactive;
	z-index: $menu-base-layer;
	position: fixed;
	bottom: 0px;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background: rgba(255, 255, 255, 0.8);
	padding: 10px 180px 20px;
	max-height: 90px;
}

@media screen and (max-width: 600px) {
	.report-nav-indicators {
		display: none;
	}
}

.report-nav-indicator {
	position: relative;
	padding: 0 0.5em;
	margin: 5px 0;
}

.report-nav-indicator-icon {
	width: 10px;
	height: 10px;
	background: $grey;
	border-radius: 100%;
}

.report-nav-indicator-label {
	top: -39px;
	position: absolute;
	padding: 10px 1em 0;
	margin: 0 auto;
	max-width: 250px;
	text-align: center;
	visibility: hidden;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transform: translateX(-48%);
}

.link-box:hover {
	.report-nav-indicator-label {
		visibility: visible;
	}

	.report-nav-indicator-icon {
		background: $primary-text-color;
	}
}

.report-nav-indicator__active {
	.report-nav-indicator-icon {
		background: $kiplot-blue;
	}

	&:hover {
		.report-nav-indicator-icon {
			background: $kiplot-blue;
		}
	}
}

.duplicate-page-modal-report-list,
.duplicate-page-modal-page-list {
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		cursor: pointer;
		border-radius: $border-radius;
		padding: 1em;
		width: 100%;

		&:hover {
			background: $lightgrey;
		}

		&.active {
			background-color: $kiplot-blue;
			color: $white;
			font-weight: bold;
		}
	}
}
