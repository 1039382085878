@import "components/UnsavedChanges/style";
@import "Header/Settings/style";

.report-list-nav {
	margin-top: 3em;

	.report-list-nav-search {
		background: white;
		color: $primary-text-color;
		border-radius: $border-radius;
		margin-bottom: 1em;
	}

	.report-list-nav-title {
		border-top: 1px solid #fff;
		padding-top: 0.5em;
		color: $white;
	}

	.report-list-nav-item {
		background: $fadedpink;
		border-radius: $border-radius;
		margin: 1em 0em;
		padding: 1em;
		color: $primary-text-color;
		font-size: smaller;
		display: flex;
		align-items: center;
		flex-direction: row;
		&:hover {
			background: $lightgrey;
		}
	}

	.report-list-nav-item__active {
		background: $lightgrey;
		pointer-events: none;
	}

	.report-list-nav-item-title {
		font-weight: 700;
	}

	.report-list-nav-item-icon {
		color: $kiplot-blue;
		margin: 0 0.2em;
	}
}
