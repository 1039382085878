.export-table-to-ppt {
	width: 40%;

	.export-content {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&__preview-area {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 90%;
			background-color: $standardgrey;
			border-radius: 5px;
		}

		&__table-options {
			width: 100%;
		}

		&__options {
			height: 100%;
			width: 100%;
			padding-right: 12px;

			&--title {
				display: flex;
				align-items: center;
				margin-bottom: 3px;
				font-size: 0.8rem;

				i {
					color: $medium-dark-grey;
					margin-left: 2px;
					font-size: 0.8rem;
				}
			}

			&--validation {
				display: inline-flex;
				align-items: center;
				font-size: 0.6rem;
				color: $warning;
				position: absolute;
				top: 15px;

				i {
					margin-left: -4px;
					margin-right: 2px;
					font-size: 0.8rem;
				}
			}

			&--details {
				@extend .vertical-list;
				position: relative;
				width: 100%;
			}

			&--item-horizontal {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 64px;
				margin: 0;
				padding: 0 0 8px 2px;
				white-space: normal;

				span {
					width: 100%;
					justify-content: flex-start;
				}
			}

			&--item-stacked {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				width: 100%;
				margin: 0;
				padding-bottom: 8px;
				white-space: normal;

				.react-select {
					width: 100%;
				}

				input {
					width: 100%;
				}
			}
		}
	}
}

.color-option {
	justify-content: flex-end;
}
