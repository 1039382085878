@import "src/core/styles/variables";
// Do not forget to update variables.icss.scss if you are going to use them in js

$vertical-unit: calc(#{$line-height} * #{$font-size});
$horizontal-unit: calc(#{$line-height} * #{$font-size} * 1.1);
$small-horizontal-unit: calc(#{$horizontal-unit} / 2);
$small-vertical-unit: calc(#{$vertical-unit} / 2);
$max-page-width: 1600px;
$border-radius: 5px;

$visualization-cell-default-height: 400px;
$button-height: calc((#{$small-vertical-unit} * 2) + #{$icon-font-size});
$select-height: calc(#{$font-size} * 1.3 + 2px);

$rows: 3;
$highlights-name-lines: 1;
$highlights-name-lines-large: 3;

// Navigation
$nav-width-expanded: 240px;
$nav-width-collapsed: 64px;

// Colors
$inactive: #979797;

// Z INDEXES
$below-background: -1;
$general-content: 0;
$general-mid-layer: 1;
$general-top-layer: 2;
$menu-base-layer: 3;
$menu-mid-layer: 4;
$menu-top-layer: 5;
$highlight-layer: 6;
$side-panel-layer: 7;
$report-nav-layer: 8;
$tooltip-layer: 9;
$modal-base-layer: 10;
$modal-mid-layer: 11;
$modal-top-layer: 12;
$toaster: 13;

// Blues
$lightblue: #cae0fc;
$fadedblue: #afcfee;
$skyblue: #0080c8;
$transparent-brightblue: #1b26ed23;
$translucent-brightblue: #1b26ed80;

$deep-blue: #151fd4;
$darkblue: #161580;
$darkerblue: #12116a;

// Yellows
$brightyellow: #f7f09a;
$light-beige: #ffdca9;

// Greens
$limegreen: #43b283;
$deepgreen: #51a35178;

// Success indicators
$warning: #f89406;
$success: #51a351;
$red: #ee3a34;
$darkred: #ac140f;
$pastelpink: #fcdce8;
$lightgreen: #b2edc0;

// monochromatic
$black: #000000;
$translucent-black: #00000066;
$transparent-black: #00000033;
$almostblack: #333333;
$darkgrey: #4a4a4a;
$slate-grey: #888888;
$medium-dark-grey: #a9a9a9;
$grey: #979797;
$standardgrey: #cccccc;
// factoid color is user by the navy seals & subaru
$dark-pale-grey: #dbdbdb;
$lightgrey: #dededf;
$very-light-grey: #eeeeee;
$palegrey: #f3f3f3;
$ghostgrey: #f8f8f8;
$ghostwhite: #fefefe;
$fadedpink: #f1f3f6;
