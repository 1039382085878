// https://jsbin.com/gotuyo/7/edit?html,css,output
// link-box component
// Makes a box into a link, whilst retaining interactivity for links and buttons
// inside

.link-box {
	/* link-box__link is relative to this */
	position: relative;
}

.link-box__link {
	@extend .u-hide-text;
	/* Position the link over the contents, so the whole box is clickable. */
	/* Relative to link-box */
	position: absolute;
	// Above contents, below interactive elements
	z-index: $general-mid-layer;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.link-box__contents a,
.link-box__contents button {
	/* Position on top of link-box__link */
	position: relative;
	// Above link
	// The link must come first in the document order for this to take
	// precendence
	z-index: $general-mid-layer;
}
