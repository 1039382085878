.link-group {
	display: flex;
	flex-direction: column;
	max-width: 100%;

	&__wrapper {
		width: 300px;
	}

	&__add {
		margin-top: 0.5rem;
	}

	a {
		text-decoration: none;
		max-width: 100%;

		button {
			max-width: 100%;

			.button__text {
				width: auto;
				max-width: 100%;
			}
		}
	}
}

.link-button {
	font-weight: normal;
	padding-left: 0;
}

.link-button-wrapper {
	width: fit-content;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	max-width: 100%;
}
