.member-disk {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	color: white;
	font-size: 12px;
	font-weight: 500;
	position: relative;
	outline: none;
	margin-right: -5px;
	letter-spacing: 1px;
	background-color: $kiplot-blue;
	cursor: default;
}
