@import "VisualizationNode/style";

.tile {
	@extend .box-shadow;

	display: flex;
	flex-direction: column;
	margin: 5px;
	transition: all 300ms;
	min-height: 110px;
	max-width: calc(100% - 10px);
	border-radius: $border-radius;

	&--active {
		box-shadow: 0 2px 13px 1px #3d3cb49e;
	}

	&--small {
		flex: 1 1 100px;
	}

	&--regular {
		flex: 2 2 200px;
	}

	&--large {
		flex: 3 3 300px;
	}

	&--dragging {
		box-shadow: 0 2px 13px 1px rgba(0, 0, 0, 0.4);
	}

	&--full {
		flex-grow: 1;
		height: 100%;
		margin-bottom: 20px;
		/* width is defined by the tile itself, so we just ensure we fill it */
		width: 100%;

		@extend .vertical-list;
		@extend .interactive;
	}
}

.inner-tile {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 20px 20px 0px;
	margin-top: 42px;
}

.tile-header {
	@extend .interactive;

	display: flex;
	align-items: center;
	max-width: 100%;
	min-height: 42px;
	padding: 10px 20px;
	font-size: 18px;
	border-radius: $border-radius $border-radius 0 0;
	position: absolute;

	&--title {
		width: 100%;
		flex: none;
	}
}
