@import "./FieldOptions/style.scss";

.type-cell-content {
	display: flex;
	align-items: center;

	button {
		margin-left: 0.5rem;
	}
	button:first-of-type {
		margin-left: 1rem;
	}
}
