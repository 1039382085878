.configuration-tile {
	display: flex;
	flex-direction: column;
	margin: 5px;
	transition: all 300ms;
	min-height: 110px;
	max-width: calc(100% - 10px);
	flex: 2 2 200px;
	border: 3px dashed #dededf;

	&--dragging {
		box-shadow: 0 2px 13px 1px rgba(0, 0, 0, 0.4);
	}

	&__inner {
		flex-grow: 1;

		/* width is defined by the cell itself, so we just ensure we fill it */
		width: 100%;
		height: 100%;

		@extend .flexible-island;
		@extend .vertical-list;
		@extend .interactive;
	}
}

.add-tile-button {
	background: $fadedpink;
	width: 130px;
	height: 45px;
	border: 2px solid $fadedpink;
	border-radius: 20px;
	@extend .box-shadow;
	text-transform: uppercase;
	font-weight: 700;
	font-size: smaller;

	.add-tile-button__container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
}

.add-tile-button:focus {
	background: $very-light-grey;
	border: 2px solid $very-light-grey;
}

.add-tile-button:hover {
	background: $lightgrey;
	border: 2px solid $lightgrey;
}

.add-tile-portal {
	position: fixed;
	bottom: 1em;
	left: 1em;
	z-index: $menu-mid-layer;
}

.add-tile-portal-dragging {
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}
