.stage-loader {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $STRATEGY--overlay-content-layer;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: $STRATEGY--border-radius;
}
