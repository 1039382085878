$row-height-desktop: 65px;
$row-height-mobile: 85px;

@keyframes moving-gradient {
	0% {
		background-position: -250px 0;
	}
	100% {
		background-position: 250px 0;
	}
}
