// Show/hide at mobile breakpoint
.hide-on-mobile {
	@include hide();
	@media #{$mobile-break-point} {
		@include show();
	}
}

.show-on-mobile {
	@include show();
	@media #{$mobile-break-point} {
		@include hide();
	}
}
