.linear-filter__wrapper {
	@extend .horizontal-list;

	&--relative-datepicker {
		flex-wrap: wrap;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.date-filter {
		cursor: pointer;
		width: 100%;
		text-align: left;
	}

	.linear-filter__item {
		@extend .small-flexible-island;
		width: 50%;

		&--relative-datepicker {
			min-width: 100%;
			display: block;
		}
	}

	.linear-filter__input {
		@extend .input;
		width: 100%;
	}
}
