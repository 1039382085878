.outcome-list-item {
	display: flex;
	width: 100%;
	align-items: stretch;
	justify-content: stretch;
	height: 4rem;
	transition: $STRATEGY--button-transition;

	&__estimate {
		flex-basis: 5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $grey-1;
		padding: 1rem 0.2rem;
		font-size: 1.3rem;
		font-weight: bold;
		color: $grey-5;
		border-top-left-radius: $STRATEGY--border-radius;
		border-bottom-left-radius: $STRATEGY--border-radius;
		border-right: 3px solid $white;

		&--green {
			color: $white;
			background-color: $green;
		}

		&--amber {
			color: $white;
			background-color: $amber;
		}

		&--yellow {
			color: $white;
			background-color: $yellow;
		}

		&--red {
			color: $white;
			background-color: $red;
		}

		&--grey {
			color: $primary-text-color;
			background-color: $grey-1;
		}
	}

	&__content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: stretch;
		background-color: $grey-1;
		border-top-right-radius: $STRATEGY--border-radius;
		border-bottom-right-radius: $STRATEGY--border-radius;
		max-width: calc(100% - 5rem);

		&__details {
			flex: 1;
			max-width: calc(100% - 80px);
			padding: 1rem;
			text-decoration: none;

			h3 {
				margin-bottom: 0.2rem;
				max-width: 100%;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				white-space: nowrap;
				text-decoration: none;
			}

			.description {
				display: inline-block;
				font-size: $STRATEGY--font-smaller-size;
				color: $grey-4;
				font-weight: bold;
				padding-right: 0.6rem;
				text-decoration: none;
			}

			.team {
				display: inline-block;
				font-size: $STRATEGY--font-smaller-size;
				color: $grey-4;
				text-decoration: none;
			}
		}

		&__actions {
			flex-basis: 80px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 1rem;
			min-width: 80px;

			button {
				margin: 0 2px;
			}
		}

		&:hover {
			background-color: $grey-2;
		}
	}
}
