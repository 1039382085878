.node-name-icon--wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.node-name-icon {
	@extend .horizontal-list, .horizontal-list--center;
	background-color: $dark-pale-grey;
	padding: 4px 10px;
	margin: 4px;
	border-radius: $border-radius;
}

.node-name-icon--name {
	padding-left: 5px;
}

.node-name-icon__filter {
	flex-grow: 1;
	display: flex;
	transition: 250ms;

	&.selected {
		.node-name-icon {
			cursor: pointer;
			background-color: $blue;
			color: $white;
		}
	}

	&.selectable {
		.node-name-icon {
			cursor: pointer;
			background-color: $white;
		}
	}

	&.disabled,
	&.un-selectable {
		.node-name-icon {
			cursor: not-allowed;
			background-color: $dark-pale-grey;
		}
	}

	.node-name-icon {
		@extend .box-shadow;
		position: relative;
		height: 100px;
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
		flex-direction: column-reverse;
		transition: 250ms;
		margin: 0.5em;

		.node-name-icon--name {
			@extend %text-ellipsis;
			width: 92%;
			font-size: smaller;
			position: absolute;
			top: 4px;
			left: 4px;
		}

		.node-name-icon--icon {
			width: auto !important;
			height: auto !important;
			font-size: 60px;
		}
	}
}
