.other-reports {
	&__table {
		max-height: 50vh;
		overflow-y: auto;
		overflow-x: auto;

		&--item,
		&--item-no-access {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			border: none;
			border-radius: $border-radius;
			width: 100%;
			border-bottom: 1px solid $lightgrey;
			padding-right: 5px;
			text-decoration: none;
		}

		&--item {
			&--no-items {
				text-align: center;
			}

			&:hover {
				background: $lightgrey;
				cursor: pointer;
			}

			&.active {
				background-color: $kiplot-blue;
				color: $white;
				font-weight: bold;
			}
		}

		&--item-no-access {
			font-style: italic;
			background: $fadedpink;
			cursor: default;
		}

		&--name {
			@extend .flexible-island;
			white-space: nowrap;
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&--view {
			white-space: nowrap;
			text-align: end;
		}

		&--no-items {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			img {
				width: 60%;
			}
		}
	}
}
