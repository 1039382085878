.nav-body {
	display: flex;
	flex-direction: column;
	width: 100%;
	//height: 100%;
	margin-top: 20px;
	padding: 0 0.8rem;
	overflow-x: hidden;
	overflow-y: auto;

	a {
		text-decoration: none;
	}
}

.nav-header {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	position: relative;

	&__kiplot-logo {
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		align-self: center;
		height: 46px;
		width: 100%;
		position: relative;
	}

	&__version {
		font-size: x-small;
		align-self: center;
		margin-top: 10px;
	}

	&__collapse-btn {
		background-color: $kiplot-blue;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		width: 24px;
		height: 24px;
		right: -10px;
		top: 52px;
		cursor: pointer;
		transition: background-color 250ms ease-in-out;
		z-index: 1;

		&:hover {
			background-color: darken($kiplot-blue, 20%);
		}

		i {
			font-size: 0.8rem;
			cursor: pointer;
		}
	}
}

.nav-logo {
	opacity: 1;
	position: absolute;

	&__mini {
		height: 46px;
		top: 0;
		left: 8px;
		transition: transform 250ms cubic-bezier(0.01, 0.5, 0.06, 0.59),
			opacity cubic-bezier(0.01, 0.99, 0.03, 0.59) 250ms;
		z-index: 1;

		&--hide {
			transform: rotate(300deg);
			opacity: 0;
		}
	}

	&__full {
		position: relative;
		height: 41px;
		top: 3px;
		left: 16px;
		opacity: 0;
		transition: left 500ms cubic-bezier(0.07, 0.49, 0, 1.01) 200ms,
			opacity 350ms cubic-bezier(0.01, 0.99, 0.03, 0.59) 250ms;

		&--show {
			left: 64px;
			opacity: 1;
		}
	}
}

.nav-account {
	background-color: darken($darkblue, 20%);
	width: 100%;
	height: 60px;
	border-bottom-right-radius: 10px;
	padding: 1rem;
	display: flex;
	align-items: center;
	position: relative;

	&--collapsed {
		justify-content: center;
		align-items: center;
	}

	&__details {
		display: flex;
		flex-direction: column;
		margin-left: 1rem;
		opacity: 0;
		transition: opacity 130ms ease-in-out;

		&--show {
			opacity: 1;
		}
	}

	&__name {
		font-size: small;
		font-weight: bold;
	}

	&__email {
		font-size: x-small;
		color: $grey-3;
	}

	&__profile-img {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		outline: 1px solid $white;
		background-color: $kiplot-blue;
		cursor: pointer;
		transition: all 150ms ease-in-out;

		&--collapsed {
			position: absolute;
			top: calc(50% - 15px);
			left: calc(50% - 15px);
		}
	}

	&__logout {
		background-color: $red;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 5px;
		margin-left: auto;
		opacity: 0;
		transition: background-color 250ms ease-in-out, opacity 130ms ease-in-out;
		position: relative;

		&--show {
			opacity: 1;
		}

		&:hover {
			background-color: darken($red, 20%);
		}
	}
}

.profile-avatar {
	align-content: center;

	img {
		width: 100%;
	}
}

.nav-customer-logo {
	margin-bottom: 1rem;
	width: 100%;
	height: 70px;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	img {
		height: 100%;
	}
}

.nav-group {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	cursor: pointer;
	transition: background-color 250ms ease-in-out;
	border-radius: 5px;
	min-height: 42px;

	&--collapsed {
		justify-content: center;
		align-items: center;
	}

	&--active {
		background-color: darken($darkblue, 15%);
		font-weight: bold;
	}

	&:hover {
		background-color: darken($darkblue, 15%);
	}

	&__header {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 5px;
		font-size: 0.75rem;

		@media #{$desktop-screen} {
			font-size: 0.8rem;
		}

		@media #{$mobile-screen} {
			font-size: 0.85rem;
		}
	}

	&__children {
		margin: 0.5rem 0;
	}

	.phosphor-icon {
		min-width: 20px;
		min-height: 20px;
		width: 20px;
		height: 20px;
	}
}

.nav-group-separator {
	min-height: 20px;
	position: relative;
	width: 100%;

	&:after {
		position: absolute;
		top: 10px;
		left: 0;
		content: "";
		width: 100%;
		height: 1px;
		border-radius: 50%;
		background-color: #5051ab;
	}
}

.nav-group-child {
	display: flex;
	align-items: center;
	min-height: 46px;
	width: 100%;
	font-size: 0.75rem;

	@media #{$desktop-screen} {
		font-size: 0.8rem;
	}

	@media #{$mobile-screen} {
		font-size: 0.85rem;
	}

	&__line {
		width: 32px;
		height: 46px;
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			left: 15px;
			content: "";
			width: 1px;
			height: 100%;
			background-color: #5051ab;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		width: 100%;
		cursor: pointer;
		padding-left: 1rem;
		border-radius: 5px;
		height: 42px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		transition: background-color 250ms ease-in-out;

		&--active {
			background-color: darken($darkblue, 15%);
			font-weight: bold;
		}

		&:hover {
			background-color: darken($darkblue, 15%);
		}
	}
}

.nav-link {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	cursor: pointer;
	transition: background-color 250ms ease-in-out;
	border-radius: 5px;
	min-height: 42px;

	&--collapsed {
		justify-content: center;
		align-items: center;
	}

	&--active {
		background-color: darken($darkblue, 15%);
		font-weight: bold;
	}

	&:hover {
		background-color: darken($darkblue, 15%);
	}

	&__header {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 5px;
		font-size: 0.75rem;

		@media #{$desktop-screen} {
			font-size: 0.8rem;
		}

		@media #{$mobile-screen} {
			font-size: 0.85rem;
		}
	}

	.phosphor-icon {
		min-width: 20px;
		min-height: 20px;
		width: 20px;
		height: 20px;
	}
}

.nav-group-child-separator {
	margin-left: 15px;
	min-height: 12px;
	position: relative;
	width: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 1px;
		height: 100%;
		background-color: #5051ab;
	}

	&:after {
		position: absolute;
		top: 6px;
		left: 0;
		content: "";
		width: 90%;
		height: 1px;
		background-color: #5051ab;
	}
}

.nav-spacer {
	min-height: 10px;
	position: relative;
	width: 100%;
}

.app-nav-mobile {
	height: 46px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $darkblue;
	display: flex;
	align-items: center;
	justify-content: space-between;
	pointer-events: all;

	img {
		margin-left: 1rem;
		width: 80px;
	}

	&__toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		margin-right: 1rem;
		cursor: pointer;
		transition: background-color 250ms ease-in-out;

		&:hover {
			background-color: darken($darkblue, 15%);
		}
	}
}

.app-nav-mobile-content {
	position: fixed;
	z-index: 10;
	top: 46px;
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: $darkblue;
	pointer-events: all;
	padding: 2rem 1rem;
	color: $white;

	a {
		text-decoration: none;
	}
}

.logout-menu {
	position: absolute;
	bottom: calc(100% + 10px);
	background-color: white;
	border-radius: 5px;
	display: flex;
	width: 200px;
	justify-content: center;
	align-items: center;
	font-size: smaller;
	box-shadow: 1px 9px 25px rgb(0 0 0 / 25%);
	font-weight: bold;
	padding: 0.2rem;

	&__option {
		width: 100%;
		padding: 1rem;
		transition: background-color 250ms ease-in-out;
		color: $primary-text-color;
		border-radius: 5px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;

		&--red {
			color: white;
			background-color: $red;

			&:hover {
				background-color: darken($red, 20%);
			}
		}

		&:hover {
			background-color: #cecece;
		}
	}
}
