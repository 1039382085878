.page-error {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__image {
		width: 500px;
		height: auto;

		@include media("<tablet") {
			width: 70%;
		}
	}

	&__header {
		margin-top: 2rem;
		text-align: center;
	}

	&__message {
		text-align: center;
	}

	&__code {
		color: $grey-3;
		font-weight: bold;
		font-size: $STRATEGY--font-smaller-size;
		text-transform: uppercase;
		margin-bottom: 1.5rem;
	}
}
