$icon-width: 50px;
$icon-height: 50px;
$icon-radius: 50%;

.performance-indicator {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__total {
		@extend .font-bold;
		padding: 5px;
		margin: 5px;
		border: 2px solid transparent;
		animation: fade-in 400ms ease-in;
		transition: 400ms;
		margin-bottom: 0px;

		&--title {
			margin: 2px 0px 0px 0px;
			word-wrap: break-word;
			display: block;
		}
	}

	&__divider {
		margin: 0px 5px 7px 5px;
		color: #979797;
		animation: fade-in 400ms ease-in;
		transition: 400ms;
	}

	&__box {
		display: flex;
		align-items: center;
		flex-grow: 1;
		cursor: pointer;
		padding: 5px;
		margin: 5px;
		border-radius: $border-radius;
		min-height: 75px;
		animation: fade-in 400ms ease-in;
		border: 2px solid transparent;
		transition: 400ms;

		&--compact {
			min-height: initial;
		}

		&:hover {
			transition: 250ms;
			// this is due to the border sometimes being styled inline
			border: 2px solid $primary-text-color !important;
		}
	}

	&__meta-data {
		display: flex;
		min-height: 60px;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		overflow: hidden;
		color: $black;
		padding-left: 0.5em;

		&--compact {
			padding-left: 0;
			min-height: initial;
		}
	}

	&__title {
		margin-bottom: 5px;
		width: 90%;
		font-size: small;
		word-wrap: break-word;

		&--compact {
			@extend .font-bold;
			margin-bottom: 0;
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			word-wrap: initial;
			font-size: 0.7em;
		}
	}

	&__value {
		@extend .font-bold;
		font-size: x-large;
		word-wrap: break-word;
	}

	&__icon-container {
		position: relative;
		display: block;
		border-radius: $icon-radius;
		// this makes sure it is the same on every browser
		height: $icon-height;
		min-width: $icon-width;
		max-width: $icon-width;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
