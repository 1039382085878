@import "../../../../../../lib/style/variables";
@import "../../../variables";
@import "../../../../../../lib/style/breakPoints";

$border-size: 4px;
.dataset-logo__image {
	&-loading {
		background: $medium-dark-grey;
		border-radius: 50%;
		width: 55px;
		height: 55px;

		@media #{$mobile-break-point} {
			height: 45px;
			width: 45px;
		}
	}
}

.dataset-logo__wrapper {
	width: $row-height-mobile;
	height: $row-height-mobile;
	box-sizing: content-box;
	background-color: $very-light-grey;
	border-radius: $border-size;
	padding-left: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: $border-size;
	z-index: 1;
	pointer-events: none;

	@media #{$mobile-break-point} {
		width: $row-height-desktop;
		height: $row-height-desktop;
		border: $border-size solid $white;
		margin: auto;
		padding-left: 0px;
		border-radius: 50%;
	}

	.dataset-logo__image,
	.dataset-logo__image-loading {
		height: 55px;
		@media #{$mobile-break-point} {
			height: 45px;
		}
	}
}
