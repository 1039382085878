.schema-context-editor {
	&__btn {
		position: absolute;
		top: -0.05em;
		left: 3em;
	}

	&__flags-btn {
		position: absolute;
		top: -1em;
		right: -0.2em;
	}

	&__header {
		@extend .small-flexible-island;
		margin: 0;
	}

	&__content {
		@extend .small-flexible-island;
		display: inline-flex;
		align-items: center;

		&--select {
			width: 15em;
			max-width: 15em;
		}

		&--button {
			@extend .btn;
			@extend .btn--fill;
			@extend .btn--auto-complete-inline;
			max-height: 2.3em;
			margin-left: 0.3em;
		}
	}

	&__select-flavor {
		width: 8em;
		margin-right: 1em;
	}

	&__checkbox {
		margin-right: 1em;
	}
}

.schema-context-editor--portal {
	@extend .box-shadow;
	position: absolute;
	background: $white;
	min-width: 300px;
}
