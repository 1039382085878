@import "Dates/style";
@import "Dependencies/style";
@import "Modal/style";
@import "Team/style";
@import "Forecasts/component/style";

.skill-efforts-table {
	&__actions-column {
		width: 40px;
	}
}
