.multi-level-dropdown__item {
	@extend .u-reset-btn, .horizontal-list, .small-flexible-island;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	outline: none;
	transition: 250ms;
	cursor: pointer;

	&-label {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&:hover {
		background-color: $lightblue;
		color: $primary-text-color;
	}
}

.multi-level-dropdown__item--wrapper {
	border-bottom: 1px solid $lightgrey;
}
