.context-menu {
	// Child `.context-menu` is relative to this
	position: relative;
}

.context-menu__menu {
	@extend .box-shadow;
	padding: 5px 0;
	color: $primary-text-color;
	position: absolute;
	z-index: $modal-top-layer;
	background-color: $white;
	display: flex;
	flex-direction: column;
	white-space: nowrap;
	right: 0;
	border-radius: $border-radius;

	hr {
		@extend .small-vertical-island-margin;
		// TODO: Share
		border-top: 1px solid hsl(0, 0%, 88%);
		border-top: inherit;
		// Unset UAS
		margin-left: inherit;
		// Unset UAS
		margin-right: inherit;
		margin-top: 0;
		margin-bottom: 0;
	}
}

// Set width and height for filter context-menu
.filter-context-menu .context-menu__menu {
	overflow: auto;
	max-width: 526px;
	min-width: 426px;
	text-overflow: ellipsis;
}

.context-menu__menu__item {
	@extend .btn;
	padding-right: 1em;
	padding-left: 1em;
	// TODO: Opt-in, not opt-out
	// Override btn
	justify-content: flex-start;
	text-align: left;

	&:hover {
		background: $very-light-grey;
	}

	&:focus {
		background: $fadedpink;
	}
}

.context-menu__menu__badge {
	@extend .small-flexible-island__item;
	@extend .small-flexible-island;
	font-size: 0.9em;
	background-color: $lightgrey;
	color: $darkgrey;
	margin-left: $small-horizontal-unit;
}
