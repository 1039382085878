@import "Graph/style";

.plan-list {
	& > * {
		margin-bottom: 1rem;
	}

	& :last-child {
		margin-bottom: 0;
	}
}

.plan-entry-list {
	&--center-text {
		text-align: center;
	}

	&--center {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}

.key-result-summary {
	tr {
		&:last-child {
			border-bottom: none;
			td {
				border-bottom: none;
			}
		}
	}

	&__wrapper {
		max-width: 100%;
		position: relative;
		overflow-x: auto;
	}

	&__value {
		min-width: 4rem;
	}

	&__name-column {
		position: sticky;
		left: 0;
		min-width: 15rem;
		white-space: nowrap;
		background: $white;

		&--with-border {
			border-bottom: 1px solid $grey-1;
		}
	}

	&__name-column-content {
		display: flex;
		height: 1.6rem;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
	}

	&__plan-name {
		color: $blue;
	}

	&__name {
		display: inline-block;
		max-width: 14rem;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		font-weight: bold;

		&--level-1 {
			font-weight: normal;
			padding-left: 0.5rem !important;
		}

		&--level-2 {
			font-weight: normal;
			padding-left: 0.9rem !important;
		}

		&--no-items {
			font-weight: normal;
			font-size: smaller;
			color: $red;
		}
	}

	&__actions {
		display: inline-block;
		margin-left: 0.5rem;
	}

	&__total {
		position: sticky;
		right: 0;
		background: $white;
		min-width: 5rem;
		font-weight: bold;

		&--with-border {
			border-bottom: 1px solid $grey-1;
		}
	}

	&__header {
		vertical-align: top;
	}

	&__period-color {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0.1rem 0.5rem;
		margin-top: 0.2rem;
		border-radius: $STRATEGY--border-radius;

		span {
			font-size: $STRATEGY--font-small-size;
			font-weight: bold;
		}

		&--current {
			background-color: #1b26ed;
			color: $white;
		}
	}
}
