.report-info__alias {
	&-wrapper {
		@extend .flexible-island;
		display: flex;
		flex-direction: column;
		border-bottom: 2px solid $lightgrey;

		.inline-error {
			align-items: flex-end;
		}
	}

	&-title {
		@extend .font-bold;
		margin-top: 0;
	}

	&-input {
		@extend .input;
		width: 80%;
	}

	&-display {
		width: 80%;
	}

	&-display-editable {
		cursor: pointer;
		border-bottom: 1px solid transparent;
		transition: 250ms;

		&:hover {
			transition: 250ms;
			border-bottom: 1px solid $almostblack;
		}
	}

	&-controls-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: 20%;
		max-height: 40px;
		min-height: 40px;
	}

	&-input-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
	}

	&-input-label {
		font-size: 12px;
		height: 30px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}
}
