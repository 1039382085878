.empty-state-wrapper {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.empty-state-image {
	width: 100%;
	height: auto;
	max-width: 550px;
}

.empty-state-header {
	text-align: center;
	font-weight: 700;
}

.empty-state__btn-wrapper {
	@extend .horizontal-list, .horizontal-list--center;
	@media #{$small-screen} {
		flex-direction: column;
	}
	.empty-state__btn {
		margin: 0.5em;
	}
}

// SENTRY FEEDBACK FORM OVERWRITES
.powered-by {
	display: none;
}

.sentry-error-embed-wrapper .sentry-error-embed .form-submit .btn {
	@extend .btn;
	@extend .btn--fill;
}
