.application-error__btn-wrapper {
	@extend .horizontal-list, .horizontal-list--center;
	@media #{$small-screen} {
		flex-direction: column;
	}
	.application-error__btn {
		margin: 0.5em;
	}
}

// SENTRY FEEDBACK FORM OVERWRITES
.powered-by {
	display: none;
}

.sentry-error-embed-wrapper .sentry-error-embed .form-submit .btn {
	@extend .btn;
	@extend .btn--fill;
}
