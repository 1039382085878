.input-with-crud--wrapper {
	width: 100%;
}

.input-with-crud--wrapper .inline-error {
	height: 14px;
}

.input-with-crud--form {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.input-with-crud--form .input {
	width: 100%;
}

.input-with-crud--controls-wrapper {
	display: flex;
	align-items: center;
}

.input-with-crud--display-value {
	@extend %text-ellipsis;
	width: 100%;
	transition: 250ms;
	border-bottom: 1px solid transparent;
}

.input-with-crud--display-value--wrapper {
	@extend .small-island, %text-ellipsis;
	display: flex;
	width: 100%;
}

@mixin input-with-crud__hoverable--border-color($border-bottom-color) {
	.input-with-crud--display-value__click-editable:hover,
	.input-with-crud--display-value__hoverable {
		border-bottom: 1px solid $border-bottom-color;
	}
}

@include input-with-crud__hoverable--border-color($almostblack);
