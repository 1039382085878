.project-profile-list {
	@import "ListItem/style";

	@extend .u-reset-list;

	&__loading {
		// long fade-in to prevent flashing ui
		animation: fade-in 220ms ease-in;
		transition: 120ms;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: row;
		height: 65px;
		margin-bottom: 10px;

		&--row {
			height: 100%;
			width: 100%;
			border-radius: $border-radius;
			margin: 0px 20px 0px 15px;
			background: linear-gradient(
				to right,
				$very-light-grey 20%,
				$standardgrey 50%,
				$very-light-grey 80%
			);
			animation: moving-gradient 1.4s ease-in-out infinite;
			animation-direction: alternate;
		}
	}
}
