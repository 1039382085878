@import "~fixed-data-table-2/dist/fixed-data-table-base.css";
@import "~rc-slider/assets/index.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~include-media/dist/_include-media.scss";

// CORE STYLES
@import "src/core/styles";

// STRATEGY STYLES
/*
THIS IS NOT BEST PRACTISE
 We have decided to do this to make sure we don't interfere with legacy code and to avoid namespace collision.
 The performance hit we would take for this is minor compared to the safety we gain.
 The future ambition is to move this wave to its own application.
 */
.STRATEGY-WAVE {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	pointer-events: all;
	@import "src/strategy/styles";
}

// variables
@import "src/reporting/lib/style/variables";
@import "src/reporting/lib/style/breakPoints";

// pull in global, app-wide styling
@import "src/app/App/Navigation/style";
@import "src/reporting/lib/style/global";

// overwrite third party library styles
@import "src/reporting/lib/style/pluginStyles";

// pull in CSS-only components, often used for extending
@import "src/reporting/components/cssOnly";

// components (css only)
@import "src/reporting/components/style/buttons";
@import "src/reporting/lib/style/breakPointHelpers"; // needs to follow buttons to overwrite styles
@import "src/reporting/components/style/contextMenu";

// components stytling
@import "src/reporting/components/Avatar/style";
@import "src/reporting/components/Banner/style";
@import "src/reporting/components/Box/style";
@import "src/reporting/components/Card/style";
@import "src/reporting/components/Checkbox/style";
@import "src/reporting/components/ColorSelect/style";
@import "src/reporting/components/ColumnEditor/style";
@import "src/reporting/components/ConcertinaMenu/style";
@import "src/reporting/components/DatasetSidePanel/style";
@import "src/reporting/components/DataUploader/component/style";
@import "src/reporting/components/DatePicker/style";
@import "src/reporting/components/DebouncedInput/style";
@import "src/reporting/components/DeleteDatasetModal/style";
@import "src/reporting/components/EmptyState/style";
@import "src/reporting/components/ExportTableToPowerPoint/style";
@import "src/reporting/components/ExternalLink/style";
@import "src/reporting/components/Form/style";
@import "src/reporting/components/Icon/style";
@import "src/reporting/components/InputWithCrud/style";
@import "src/reporting/components/LinkBox/style";
@import "src/reporting/components/LinkBox/style";
@import "src/reporting/components/LoadingTable/style";
@import "src/reporting/components/Markdown/style";
@import "src/reporting/components/Members/style";
@import "src/reporting/components/Modal/style";
@import "src/reporting/components/MultiLevelDropdown/style";
@import "src/reporting/components/PageHeader/style";
@import "src/reporting/components/RadioButton/style";
@import "src/reporting/components/ResizableFrame/style";
@import "src/reporting/components/SchemaEditor/style";
@import "src/reporting/components/SelectArrow/style";
@import "src/reporting/components/SelectInput/input-styles";
@import "src/reporting/components/ShareDataset/style";
@import "src/reporting/components/ShareReport/style";
@import "src/reporting/components/SidePanel/style";
@import "src/reporting/components/Slider/style";
@import "src/reporting/components/SortButton/style";
@import "src/reporting/components/Spinner/style";
@import "src/reporting/components/Title/style";
@import "src/reporting/components/Toaster/style";
@import "src/reporting/components/ToggleButton/style";
@import "src/reporting/components/SkeletonGenerator/style";
@import "src/reporting/components/SidePanelConcertina/style";

// ensure these come after extendable components
@import "src/reporting/App/Admin/style";
@import "src/reporting/App/DatasetPage/style";
@import "src/reporting/App/DatasetPage/style";
@import "src/reporting/App/DatasetUsage/style";
@import "src/reporting/App/ProjectProfile/style";
@import "src/reporting/App/Initiatives/style";
@import "src/reporting/App/QueryBuilder/view/main";
@import "src/reporting/App/Report/style";
@import "src/reporting/App/ReportsList/style";
@import "src/reporting/App/ResourceManagement/style";
@import "src/app/App/ErrorBoundary/style";
@import "src/reporting/App/Settings/style";
@import "src/reporting/App/SignIn/component/style";
@import "src/reporting/App/SignIn/style";
@import "src/reporting/App/Stage/style";
@import "src/reporting/App/Templates/Reports/components/DatasetPicker/style";
@import "src/reporting/App/Templates/Reports/components/ReportTemplateModal/style";
@import "src/reporting/App/Templates/Reports/components/TemplatePicker/style";
@import "src/reporting/App/Templates/Reports/style";
@import "src/reporting/App/UserProfile/style";
@import "src/reporting/App/Visualizations/ReactVisualizations/PerformanceIndicator/style";

// Report Styling
@import "src/reporting/App/Report/Header/Menu/style";
@import "src/reporting/App/Report/Header/PrintToPdf/printToPdf";
@import "src/reporting/App/Report/Header/TimeMachine/style";
@import "src/reporting/App/Report/Pages/style";
@import "src/reporting/App/Report/ReportGrid/style";
@import "src/reporting/App/Report/ReportGrid/Tile/Configuring/style";
@import "src/reporting/App/Report/ReportGrid/Tile/DatasetInfo/style";
@import "src/reporting/App/Report/ReportGrid/Tile/Legends/style";
@import "src/reporting/App/Report/ReportGrid/Tile/MarkTooltip/style";
@import "src/reporting/App/Report/ReportGrid/Tile/MarkTooltip/tooltip";
@import "src/reporting/App/Report/SidePanel/ChartSettings/style";
@import "src/reporting/App/Report/SidePanel/DataInspector/style";
@import "src/reporting/App/Report/SidePanel/style";
@import "src/reporting/App/Visualizations/ReactVisualizations/Table/style";
@import "src/reporting/App/Visualizations/ReactVisualizations/PivotTable/style";
@import "src/reporting/App/Visualizations/ReactVisualizations/Text/TextWidget";

// Custom icons from Icomoon
@import "assets/style";
