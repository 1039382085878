.banner {
	background-color: $kiplot-blue;
	color: $white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5em;
	border-bottom: 1px solid $white;
	a {
		background-color: $red;
		color: $white;
		font-weight: 700;
		border-radius: $border-radius;
		padding: 6px 12px;
		text-decoration: none;
		margin: 0 1em;
		min-width: 110px;
	}
	a:hover {
		background-color: $darkred;
	}
	p {
		margin: 0.5em 1em;
	}
}

.banner-message {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
