.button {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	border-radius: $STRATEGY--border-radius;
	font-size: $STRATEGY--font-bigger-size;
	font-weight: bold;
	padding: 0 0.6rem;
	position: relative;

	&__indicator {
		flex: none;
		position: absolute;
		right: -0.35rem;
		top: -0.35rem;
		width: 0.7rem;
		height: 0.7rem;
		border-radius: 50%;
		border: none;
		background-color: $blue;
		box-shadow: 0 0 4px 0 $blue;
	}

	//Button Sizes
	&--large {
		height: 2.5rem;
		padding: 0 1rem;

		i {
			font-size: 1.4rem;
		}
	}

	&--large-no-text {
		width: 2.5rem;
	}

	&--medium {
		height: $STRATEGY--field-height;

		i {
			font-size: $STRATEGY--font-bigger-size;
			margin-top: 2px;
		}
	}

	&--medium-no-text {
		width: $STRATEGY--field-height;
	}

	&--small {
		height: 1.6rem;
		font-size: $STRATEGY--font-small-size;

		i {
			font-size: $STRATEGY--font-normal-size;
		}

		/* IE 11 only hack */
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			& i {
				margin-top: -2px;
				margin-left: -3px;
			}
		}
	}

	&--small-no-text {
		width: 1.6rem;
	}

	&--smaller {
		height: 1.2rem;
		font-size: $STRATEGY--font-small-size;

		i {
			font-size: $STRATEGY--font-smaller-size;
		}
	}

	&--smaller-no-text {
		width: 1.2rem;
	}

	// Button Types
	&--primary {
		background-color: $blue;
		color: $white;
		transition: $STRATEGY--button-transition;

		&:hover {
			background-color: darken($blue, 10%);
		}
	}

	&--primary-disabled {
		background-color: $grey-2;
		color: $grey-4;
		pointer-events: none;
		cursor: not-allowed;
	}

	&--danger {
		background-color: $red;
		color: $white;
		transition: $STRATEGY--button-transition;

		&:hover {
			background-color: darken($red, 10%);
		}
	}

	&--danger-disabled {
		background-color: $grey-2;
		color: $grey-4;
		pointer-events: none;
		cursor: not-allowed;
	}

	&--secondary {
		background-color: $grey-1;
		color: $primary-text-color;
		transition: $STRATEGY--button-transition;

		&:hover {
			background-color: darken($grey-1, 10%);
		}
	}

	&--secondary-disabled {
		background-color: $grey-2;
		color: $grey-4;
		pointer-events: none;
		cursor: not-allowed;
	}

	&--muted-primary {
		background-color: transparent;
		color: $primary-text-color;
		transition: $STRATEGY--button-transition;

		&:hover {
			color: $blue;
		}

		i {
			color: $blue;
		}
	}

	&--muted-secondary {
		background-color: transparent;
		color: $grey-5;
		transition: $STRATEGY--button-transition;

		&:hover {
			background-color: $grey-2;
			color: $grey-6;
			i {
				color: $grey-6;
			}
		}

		i {
			color: $grey-5;
		}
	}

	&--muted-primary-disabled,
	&--muted-secondary-disabled {
		color: $grey-4;
		pointer-events: none;
		cursor: not-allowed;
		i {
			color: $grey-4;
		}
	}

	&--dark {
		background-color: $grey-4;
		color: $white;
		transition: $STRATEGY--button-transition;

		&:hover {
			background-color: darken($grey-4, 10%);
		}

		i {
			color: $white;
		}
	}

	&--dark-disabled {
		background-color: $grey-2;
		color: $grey-4;
		pointer-events: none;
		cursor: not-allowed;
	}

	&--light {
		background-color: $white;
		color: $primary-text-color;
		transition: $STRATEGY--button-transition;

		&:hover {
			background-color: $blue;
			color: $white;
		}
	}

	&--light-disabled {
		background-color: $white;
		color: $grey-2;
		pointer-events: none;
		cursor: not-allowed;
	}

	// Button Components
	&__text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 170px;
		padding-left: 0.3rem;

		&--no-icon {
			padding-left: 0;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;

		&--large {
			width: $STRATEGY--field-height;
			height: $STRATEGY--field-height;
			i {
				font-size: 1.4rem;
			}
		}

		&--medium {
			width: $STRATEGY--icon-height;
			height: $STRATEGY--icon-height;
			i {
				font-size: $STRATEGY--font-bigger-size;
			}
		}

		&--small {
			i {
				font-size: $STRATEGY--font-normal-size;
			}
		}

		&--smaller {
			width: $STRATEGY--small-icon-height;
			height: $STRATEGY--small-icon-height;
			i {
				font-size: $STRATEGY--font-smaller-size;
			}
		}
	}
}
