@import "./components/style.scss";

.roadmap-initiative-context-menu {
	&__buttons {
		width: 100%;

		button {
			width: 100%;
			font-size: $STRATEGY--font-smaller-size;
			font-weight: normal;
			justify-content: flex-start;
			margin-bottom: 0.5rem;
		}
	}

	.context-modal {
		max-width: 300px;
	}
}
