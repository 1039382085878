.control-buttons {
	display: inline-flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: inherit;

	%base-button {
		@extend .btn;
		@extend .btn--fill;

		width: 89px;
		margin-left: 0.5em;

		&--spinner {
			font-size: 0.6rem;
		}

		.spinner-wrapper {
			font-size: 0.6em;
			left: -12px;
		}
	}

	.cancel-button {
		@extend %base-button;
		@extend .btn--fill__grey;
	}

	.save-button {
		@extend %base-button;
	}

	.edit-button {
		@extend %base-button;
	}
}
