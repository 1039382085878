.column-headers {
	width: calc(100% - #{$row-header-width} - 15px);
	overflow: hidden;
	margin-left: $row-header-width;
	background-color: $white;
	font-weight: bold;

	&--sticky {
		position: sticky;
		top: 0;
		left: 0;
		background-color: $white;
	}

	&__header {
		justify-content: space-around;
		align-items: center;
		min-width: $column-width;

		button {
			padding: 2px;
		}

		i {
			font-size: small;
		}
	}
}
