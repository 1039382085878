@use "sass:color";
@import "./CreateOutcome/style.scss";

.cc-stage {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	&__row {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: $small-spacing;
		border-bottom: 3px solid $grey-1;
		row-gap: $smaller-spacing;
		position: relative;

		&:last-child {
			border-bottom: none;
		}
	}
}

.team-row-anchor {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	pointer-events: none;

	&__sides {
		width: 5px;
		height: 100%;
	}
}

.stage-team-header-row {
	display: flex;
	width: 100%;
	background-color: $white;
	color: $primary-text-color;
	padding: 0 $smaller-spacing;

	h4 {
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 1rem;
	}
}

.stage-outcomes-row {
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	column-gap: $small-spacing;
}

.stage-indicators-row {
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	column-gap: $small-spacing;
}

.cc-cell {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 24px;
	transition: background-color 250ms ease-in-out;
	padding: $smallest-spacing;
	border-radius: $STRATEGY--border-radius;
	row-gap: $smaller-spacing;

	&--is-active {
		background-color: color.adjust($blue, $alpha: -0.8);
	}

	&--is-dropping {
		background: color.adjust($blue, $alpha: -0.8);
	}

	&__loading {
		align-items: center;
		justify-content: center;
		height: 100%;
		min-width: 25%;

		&--overlay {
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgb(255 255 255 / 65%);
			left: 0;
			top: 0;
		}
	}
}

.period-health-indicator {
	position: relative;
	display: flex;
	height: 0.7rem;
	width: 100%;
	max-width: 100%;
	align-items: stretch;
	justify-content: stretch;
	font-size: 0.5rem;
	line-height: 0.7rem;
	padding: 0 $smallest-spacing;

	&__effort {
		text-align: center;
		color: $white;
		flex: initial;
		min-width: 10px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;

		&--no-capacity {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		&--green {
			background-color: $green;
		}

		&--red {
			background-color: $red;
		}

		&--grey {
			background-color: $grey-1;
			color: $primary-text-color;
		}

		&--full {
			border-radius: 10px;
		}
	}

	&__capacity {
		background-color: $grey-1;
		color: $grey-5;
		flex: auto;
		text-align: center;

		&--no-effort {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&--no-reserved-capacity {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}

	&__reserved-capacity {
		background-color: $grey-4;
		color: $white;
		flex: initial;
		text-align: center;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		min-width: 10px;
		padding: 0 0.3rem 0 0.1rem;
		white-space: nowrap;
	}
}
