.save-btn-wrapper {
	display: flex;
	justify-content: flex-end;

	.spinner-wrapper {
		position: relative;
		display: block;
	}

	.btn {
		width: 100px;
		height: 38px;
		padding: 0;
		position: relative;
		display: flex;
		align-items: center;
	}
}

.save-btn-wrapper-flex {
	.spinner-wrapper {
		display: flex;
		align-items: center;
	}
}
