$border-style: 2px solid $palegrey;

.delete-dataset-modal {
	@extend .modal-body__medium;

	&__list {
		@extend .u-reset-list, %display-shadow-on-overflow;
		border: $border-style;
		max-height: 250px;
		margin: 20px 0px;

		&-item {
			height: 35px;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			border-bottom: $border-style;

			&-name {
				@extend .font-bold;
				display: block;
				word-wrap: break-word;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: 75%;
				padding-left: 10px;
			}

			&__no-access {
				font-style: italic;
			}

			&-type {
				display: flex;
				width: 25%;
				justify-content: flex-end;
				padding-right: 10px;
			}
		}

		&-item:last-child {
			border-bottom: 0px;
		}
	}
}
