.archive-icon {
	max-width: 230px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	@media #{$hd-screen} {
		max-width: 190px;
	}

	&__info {
		font-size: small;
		font-weight: bold;
		color: $blue;
		margin-bottom: 1rem;
	}

	&__text {
		font-size: small;
		margin-bottom: 0.5rem;
	}

	&__warning-text {
		font-size: small;
		font-weight: bold;
		margin-bottom: 0.5rem;
		color: $amber;
	}
}

.archive-button {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&__confirm {
		margin-left: 0.5rem;
	}

	&__undo {
		margin-left: 0.5rem;
	}

	&__group {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__animate {
		// animation states
		&-enter {
			max-width: 0;
			opacity: 0;
		}

		&-enter-active {
			max-width: 100%;
			opacity: 1;
			transition: opacity 100ms, max-width 250ms;
		}

		&-exit {
			opacity: 1;
			max-width: 100%;
		}

		&-exit-active {
			opacity: 0;
			max-width: 0;
			transition: opacity 100ms, max-width 250ms;
		}
	}
}
