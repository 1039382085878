@import "../components/SaveButton/SaveButton/style";
@import "../components/Header/style";
@import "./components/SubHeader/style";

.resource--save-controls {
	&__wrapper {
		@extend .btn-wrapper__right-align;
		align-items: center;

		.btn {
			width: 100px;
			height: 40px;
		}

		.btn:last-child {
			margin-left: 5px;
		}

		.resource--unsaved-changes-warning,
		.btn:not(:last-child) {
			margin: 0px 5px;
		}
	}
}
