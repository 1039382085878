$margin-size: 10px;

.resource-settings-form {
	@extend .flexible-island, .box-shadow;
	border-radius: $border-radius;
	margin: 30px 20px 20px 20px;
	flex-grow: 1;
	position: relative;

	hr {
		border: none;
		border-bottom: 1px solid #dededf;
	}

	.resource-settings-form-label {
		@extend .font-bold;
		font-size: smaller;
		padding-bottom: 5px;
		display: block;
	}

	.resource-settings-form-element-padding {
		padding: 12px 0px;
	}

	.resource-settings-form-input-wrapper {
		display: flex;
	}

	.resource-settings-form-input {
		margin: $margin-size;
	}

	.resource-settings-form-checkbox {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.small-form-label {
			padding-bottom: 5px;
		}

		.styled-checkbox-wrapper {
			width: 21px;
		}
	}

	.resource-settings-form-radio-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.form-error {
		border: 1px solid $red;
		border-radius: $border-radius;
	}
}
