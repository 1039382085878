.filter-group--modal {
	width: 75%;
	max-width: 800px;
}

.filter-group--modal__node-list-wrapper {
	width: 80%;

	.node-name-icon--row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		border-bottom: 1px solid $dark-pale-grey;
		padding: 5px 0;

		&:first-of-type {
			padding-top: 0;
		}
	}
}

.filter-group--modal__body--wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.filter-group--modal__list--wrapper {
	@extend .u-reset-list;
	padding-top: 0.5em;
}

.filter-group--modal__list {
	padding-right: 10px;
	width: 80%;

	&--item,
	&--item__active {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 5px;
		padding-top: 14px;
		height: 62px;

		.input {
			height: 30px;
		}
	}

	&--item {
		border-bottom: 1px solid $palegrey;

		&:first-child {
			border-top: 1px solid $palegrey;
		}

		&--label {
			@extend %text-ellipsis;
		}
	}

	&--item__active {
		@include input-with-crud__hoverable--border-color($white);
		font-weight: bold;
		background-color: $blue;
		color: $white;
		border-radius: $border-radius;

		.icon-btn {
			color: $white;

			&:hover,
			&:focus {
				background-color: $darkblue;
			}
		}
	}
}

@media #{$mobile-break-point} {
	.filter-group--modal__body--wrapper {
		flex-direction: row;
	}

	.filter-group--modal__list {
		padding-right: 10px;
		min-width: 300px;
	}

	.filter-group--modal__list--item,
	.filter-group--modal__list--item__active {
		width: 275px;
	}

	.filter-group--modal__body--wrapper {
		align-items: flex-start;
	}

	.filter-group--modal__list {
		width: auto;
	}
}
