.arrow-down {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;

	border-top: 8px solid $darkgrey;
}

.share-modal-copy-input {
	border-radius: 3px 0 0 3px !important;
}

.share-modal-copy-btn {
	border-radius: 0 3px 3px 0;
	border: 1px solid $lightgrey;
	border-left: none;
}

.share-modal-permission-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	padding: 1px; // to have straight browser outlines
}

.share-modal-permission-btn-text {
	margin-right: 0.25em;
}

.member-row-email {
	margin-left: 0.25em;
	color: $grey;
}

.member-row-team {
	display: flex;
	flex-direction: row;
}

.member-row-team-icon {
	margin-right: 0.25em;
}
