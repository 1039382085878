.react-select__option {
	border-top: 1px solid $lightgrey;
	&:first-child {
		border-top: none;
	}
}

.react-select__option--is-selected {
	background-color: transparentize($color: $lightgrey, $amount: 0.6) !important;
	color: $primary-text-color !important;
}

.react-select__control {
	border: 1px solid $lightgrey !important;
	&:hover,
	&:focus {
		background-color: $fadedpink;
	}
}

.react-select__single-value {
	color: $primary-text-color !important;
}

.react-select__value-container,
.react-select__indicators {
	cursor: pointer;
}

.react-select__control--is-disabled,
.react-creatable-select__control--is-disabled {
	opacity: 0.6;
	background-color: transparent !important;
	&:hover,
	&:focus {
		background-color: transparent !important;
	}
}
