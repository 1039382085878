.context-modal {
	position: absolute;
	padding: 1rem;
	border-radius: $STRATEGY--border-radius;
	background-color: $white;
	box-shadow: $STRATEGY--shadow-distance-3;
	z-index: $STRATEGY--context-layer;
	outline: 0;

	&__trigger {
		position: relative;
		width: fit-content;
	}

	&__header {
		padding-bottom: 1rem;
		width: 100%;
		max-width: 500px;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		&--has-loader {
			min-height: 150px;
			display: flex;
			flex-direction: column;

			.modal-controls {
				margin-top: auto;
			}
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: transparent;
		z-index: $STRATEGY--overlay-content-layer;
		pointer-events: all;
		width: 100%;
		height: 100%;
	}
}

.context-modal-controls {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
	margin-top: 0.5rem;

	&--center {
		justify-content: center;
	}

	& > * {
		margin-left: 0.5rem;
	}

	& :first-child {
		margin-left: 0;
	}

	// This adds extra properties to a base typography class
	.validation-error {
		max-width: 300px;
	}
}

.context-modal-button {
	font-weight: normal;
	width: 100%;
	justify-content: left;

	.button__text {
		max-width: none;
	}
}

.context-modal-toggle-button {
	font-weight: normal;
	width: 100%;
	justify-content: left;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	border-radius: 0.32rem;
	background-color: $grey-1;
	height: 2.2rem;
	margin-bottom: 0.5rem;
	cursor: pointer;
	transition: $STRATEGY--button-transition;

	&:hover {
		background-color: $grey-2;
	}

	&__text {
		max-width: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: $STRATEGY--font-bigger-size;
	}

	&__toggle {
		margin-left: 0.4rem;
		margin-right: 0.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.context-modal-meta {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	&__header {
		color: $grey-4;
		font-size: small;
		font-weight: bold;
	}

	&__content {
		margin-top: 0.5rem;
	}
}

.content-modal-list {
	.context-modal-list-header:first-child {
		margin-top: 0;
	}

	.context-modal-toggle-button:last-child {
		margin-bottom: 0;
	}
}

.context-modal-list-header {
	margin-top: 0.6rem;
	margin-bottom: 0.6rem;
	display: flex;
	align-items: baseline;

	&__header {
		font-size: small;
		font-weight: bold;
	}

	&__meta {
		margin-left: auto;
		font-size: x-small;
		letter-spacing: 0.5px;
		color: $grey-4;
	}
}

.context-menu-warning {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	max-width: 250px;

	&__text {
		font-size: small;
	}
}
