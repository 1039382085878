.resource-management__header {
	@extend .flexible-island;
	margin: 1em 0;
	animation: fade-in 100ms ease-in;
	transition: 120ms;
	width: 100%;
	// IE 11 fix
	min-height: 125px;
	height: 100%;

	&-description {
		color: $primary-text-color;
		margin-bottom: 20px;
		max-width: 600px;
	}
}
