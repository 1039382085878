.resource--sub-header-section {
	min-height: 130px;

	&__meta-data {
		padding-top: 5px;
		font-size: smaller;
	}

	&-title {
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
	}
}

.resource--sub-header-wrapper {
	display: flex;
	align-items: center;
	padding-bottom: 5px;

	.btn {
		margin: 0 2px;
	}

	@media #{$mobile-break-point} {
		padding-bottom: 0px;
	}
}

.resource--unsaved-changes-warning {
	@extend .font-bold;
	color: $darkblue;
	display: block;
	font-size: 13px;
	height: 20px;
}

.resource--date-controls-wrapper {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	@media #{$mobile-break-point} {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
	}
}

.resource--date-range-picker {
	z-index: 2;
	font-size: smaller;
	width: 300px;

	@media #{$mobile-break-point} {
		width: 375px;
	}

	&-label {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: help;
		margin-left: 15px;
		padding-right: 1px;
		border: 2px solid $primary-text-color;
		border-radius: 50%;
		width: 31px;
		height: 31px;

		&-text {
			cursor: help;
			pointer-events: none;
			font-size: 20px;
			font-weight: 700;
		}
	}
}
