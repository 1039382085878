@import "../../lib/style/breakPoints";

.page-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex: 0 0 auto;
	width: auto;
	justify-content: flex-end;
	flex-grow: 0;

	@media #{$mobile-break-point} {
		width: 100%;
	}

	.page-header__title {
		display: flex;
		flex-grow: 1;
		order: 1;
	}

	.page-header-search__wrapper {
		width: 100%;
		flex-grow: 1;
		order: 3;
		@media #{$mobile-break-point} {
			order: 2;
			width: auto;
		}
	}

	.page-header__actions {
		display: flex;
		order: 2;
		@media #{$mobile-break-point} {
			order: 3;
		}
	}
}

.page-sub-header {
	padding: 0.5rem 1rem;
	border-left: 8px solid $standardgrey;
	margin-left: 4px;
}
