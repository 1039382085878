.side-panel-concertina {
	color: $white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $deep-blue;
	width: 100%;
	height: 35px;
	cursor: pointer;
	transition: 250ms;

	&:hover,
	&:focus {
		transition: 250ms;
		background-color: $darkblue;
	}
}
