.cell {
	min-width: $column-width;
	border-right: $border;
	border-bottom: $border;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	&:focus {
		outline: 0;
	}

	&__input {
		height: $column-header-height;
		width: 100%;
		text-align: center;
		border-top: none;
		border-left: none;
		border-right: $border;
		border-bottom: $border;
		border-radius: 0;
		background-color: $lightgrey;

		&[type="number"] {
			-webkit-appearance: textfield;
			-moz-appearance: textfield;
			appearance: auto;
		}
		&[type="number"]::-webkit-inner-spin-button,
		&[type="number"]::-webkit-outer-spin-button {
			-webkit-appearance: none;
		}

		&:focus {
			outline: 0;
		}
	}

	&--extra,
	&--empty {
		cursor: default;
		background-color: $empty-cell-bg;
		border: 0.5px solid $fadedpink;
	}
	&--interactive {
		cursor: pointer;
		border: 0.5px solid $fadedpink;
		&:hover {
			background-color: $lightgrey;
		}
	}
	&--italic {
		font-style: italic;
		font-weight: bold;
		background-color: $extra-info-cell-bg;
	}
	&--negative {
		background-color: $extra-negative-cell-bg;
	}
	&--positive {
		background-color: $extra-positive-cell-bg;
	}
	&--read-only {
		cursor: default;
		&:hover {
			background-color: transparent;
		}
	}
}
