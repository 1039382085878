.filter-control-box {
	background-color: $white;
	border-radius: $STRATEGY--border-radius;
	padding: 1rem;
	box-shadow: $STRATEGY--shadow-distance-2;
	z-index: $STRATEGY--context-layer;
	position: absolute;
	bottom: -70px;
	left: 0;
	width: 500px;
	font-size: small;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > * {
		margin-right: 0.5rem;
	}

	&:last-child {
		margin-right: 0;
	}

	&__dropdown {
		width: 100%;
	}
}

.filter-property-list {
	background-color: $grey-1;
	border-radius: $STRATEGY--border-radius;
	padding: 0.5rem;

	&__item {
		padding-left: 1rem;
		font-size: small;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
