@import "variables";

.root-svg {
	width: 100%;
	/* Percentage is in respect to container. Because this element
	is absolutely positioned, the container is the closest ancestor
	with relative positioning. */
	height: 100%;
	position: absolute;
	transform: translate3d(0, 0, 0);
}

.application-layer {
	flex: 1;
	display: flex;
	flex-direction: column;
	/*Position above absolutely positioned .root-svg*/
	position: relative;

	// we need to hear pointer events on the stage layer - use
	// .interactive (via @extend) to opt in UI elements into interactivity
	pointer-events: none;
}

.html-mark {
	position: absolute;
	top: 0;
	left: 0;
	transition: all 2s;
}

/**
 * Global page layout
 */

.root {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.root-inner {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.root-inner__wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	min-width: 200px;
}

.root-inner__content {
	margin-left: $nav-width-expanded;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-width: 200px;
	position: relative;

	&--no-nav {
		margin-left: 0;
	}

	&--collapsed {
		margin-left: $nav-width-collapsed;
	}
}

@media screen and (max-width: $small-screen-size) {
	.root-inner__content {
		margin-left: 0;
		margin-top: 46px;
	}
}

@media screen and (min-width: $small-screen-size) {
	.root-inner__wrapper {
		flex-direction: row;
	}
}

.app-router {
	display: flex;
	justify-content: center;
}

.page {
	display: flex;
	flex-grow: 1;
	width: 100%;
	max-width: $max-page-width;
}

.content-margin {
	@media (min-width: 1000px) {
		margin: auto;
		padding-left: 5%;
		padding-right: 5%;
	}
}

/*
 * THESE ARE GENERIC STYLES FOR THE REACT 'PAGES' i.e Admin, report list,
 * datasets...
*/

.page-contents {
	@extend .content-margin;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
	width: 100%;
	// Bottom padding for Chatlio live chat widget
	padding-bottom: 75px;

	header h2 {
		margin-top: inherit;
		margin-bottom: inherit;
		font-weight: 700;
		font-size: 2rem;
	}
}

.small-form-label {
	font-size: smaller;
	color: $primary-text-color;
}

.custom-select {
	height: 35px;
	padding-right: 22px;
	padding-left: 10px;
	line-height: 35px;
	width: 100%;
	box-sizing: border-box;
	cursor: default;
	border-radius: 0;
	background-color: $ghostwhite;
	border-color: $dark-pale-grey;
	background-origin: content-box;
	background-repeat: no-repeat;
	background-position: right -14.5px center;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-size: 9px 17px;
	background-image: url("src/reporting/lib/style/images/custom-select.svg");

	&:disabled {
		background-image: url("src/reporting/lib/style/images/custom-select-disabled.svg");
	}
}

// Animations

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.spin {
	animation: spin 1s linear;
	animation-iteration-count: infinite;
}

.u-grey-border-bottom {
	// TODO: Share colour
	border-bottom: 1px solid $lightgrey;
}

dl {
	// Unset UAS
	margin-top: inherit;
	// Unset UAS
	margin-bottom: inherit;
	display: flex;
	flex-wrap: wrap;
}

.dialog {
	&-title {
		margin: 0px 0px -1px;
		padding: calc(#{$vertical-unit} * 1.2);
		color: $primary-text-color;
		font-size: 1.4rem;
		line-height: 1.4rem;
		font-weight: 400;
	}

	.dialog__fieldset {
		padding: 0;
		// HTML element dialog__fieldset has a border by default and not setting this destroys entity table layout
		border: 0 !important;
		margin: 0;

		input,
		textarea {
			width: 100%;
		}
	}

	.dialog__save-container {
		position: relative;
		width: 100%;
		background: $white;
		display: flex;
		justify-items: baseline;
		margin-top: 27px;
		padding: calc((#{$vertical-unit} / 4) * 3);
	}

	.dialog__save-button {
		width: 200px;
		max-width: 100%;
		margin: 0 auto;
	}

	.dialog__action-container {
		display: flex;
		justify-content: flex-end;
		margin-right: $horizontal-unit;
	}
}

table {
	border-spacing: inherit;
	border-collapse: collapse;
	width: 100%;
	font-size: smaller;
}

tr {
	@extend .u-grey-border-bottom;
}

th,
td {
	@extend .flexible-island__item;
	// Unset UAS
	text-align: inherit;
}

// TODO: Deprecate in favour of u-visuallyhidden
.u-hide-text {
	overflow: hidden;
	text-indent: 200%;
	white-space: nowrap;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
// https://github.com/h5bp/html5-boilerplate/blob/29fd4124f47ac30de19f5700129efe17e6f69895/dist/css/main.css#L126
.u-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
	/* 1 */
}

.u-overflow-hidden {
	overflow: hidden;
}

.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.flex-justify-end {
	justify-content: flex-end;
}

.flex-justify-start {
	justify-content: flex-start;
}

.flex-justify-center {
	justify-content: center;
}

.invisible {
	visibility: hidden;
}

.navigation-active {
	font-weight: 700;
	// to allow for bold having different spacings
	letter-spacing: 0.2px;
}

.flex-column-space-between {
	@extend .flex;
	justify-content: space-between;
	flex-direction: column;
}

.inline-error {
	color: $red;
	font-size: 12px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.show-whitespace {
	white-space: pre-wrap;
}

.hide-for-small-screen {
	position: relative;

	@media #{$small-screen} {
		display: none;
	}
}

.menu-button {
	@extend .context-menu__menu__item;
	width: 100%;
}

/*
 * This allows you to specifically target `IE11` & `Edge` in `CSS`
 */
@mixin IEStyles() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}

	@supports (-ms-ime-align: auto) {
		@content;
	}
}

#chatlio-widget {
	z-index: $highlight-layer;
	// IE 11 hack. Position relative forces a new stacking context and stops the ludicrous z-index value of the chatlio
	// widget content (external widget) causing it to render higher than the side bar.
	position: relative;
}

.inset-box-shadow__vertical-bottom {
	box-shadow: inset 0px -13px 10px -14px rgba(0, 0, 0, 0.5);
}

.inset-box-shadow__horizontal-right {
	box-shadow: inset -9px -4px 9px -13px rgba(0, 0, 0, 0.75);
}

// overide for react-datepicker styles appearing out of line.
.react-datepicker {
	display: flex;
	flex-direction: row;
}

%display-shadow-on-overflow {
	overflow: auto;
	/* 	https://stackoverflow.com/questions/9333379/check-if-an-elements-content-is-overflowing
		this magical css will display a little shadow if the element is scrollable
		DO NOT REFACTOR TO SHORT HAND, it breaks.
	*/
	background: linear-gradient($white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), $white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}

// https://stackoverflow.com/questions/50654217/adding-horizontal-scrolling-shadows-effect
%display-shadow-on-overflow__horizontal {
	background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
		linear-gradient(to right, rgba(0, 0, 20, 0.5), rgba(255, 255, 255, 0)),
		linear-gradient(to left, rgba(0, 0, 20, 0.5), rgba(255, 255, 255, 0));
	/* Shadows */
	/* Shadow covers */
	background-position: left center, right center, left center, right center;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 20px 100%, 20px 100%, 5px 100%, 5px 100%;
	background-attachment: local, local, scroll, scroll;
}

%text-ellipsis {
	word-wrap: break-word;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.text-ellipsis {
	@extend %text-ellipsis;
}

.italic {
	font-style: italic;
}

/* allow content editable to work in Safari */
[contenteditable] {
	-webkit-user-select: text;
	/* We should specify `user-select` too given that it's the standard property but doing that causes Safari to stop
   working again with an "unsupported property name" error. */
}
