.input-search-icon {
	color: $inactive;
	&.focused {
		color: $primary-text-color;
	}
}

.input-search-wrapper {
	position: relative;
	border: 1px solid $lightgrey;
	border-radius: $border-radius;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
	width: 100%;

	&.focused {
		outline: -webkit-focus-ring-color auto 5px;
	}

	&.input-search-wrapper__hide-icon {
		padding-left: 0px;
	}
}

.input-search {
	padding: 0.6rem 0.66rem;
	border: none;
	outline: none;
	border-radius: $border-radius;
	-webkit-appearance: textarea;
	width: 100%;
}
