.archive-toast-wrapper {
	position: absolute;
	width: 425px;
	z-index: $toaster;
}

.archive-toast {
	background: $fadedpink;
	color: $primary-text-color;
	z-index: 10;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: 15px;
	border-radius: $border-radius;
	@extend .box-shadow;

	.archive-toast-text {
		padding-right: 25px;
	}
}
