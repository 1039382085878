.side-panel {
	@extend .box-shadow;
	position: fixed;
	top: 0;
	bottom: 0;
	background: $white;
	z-index: $side-panel-layer;
	max-width: 100%;
	display: flex;
	flex-direction: column;

	.side-panel-title {
		font-weight: 700;
		text-transform: uppercase;
	}

	.side-panel__navigation {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.side-panel__nav-title {
		overflow: hidden;
		padding-right: 20px;
	}

	.side-panel__body {
		@extend .show-whitespace;
		overflow: auto;
		overscroll-behavior: contain;
		flex-grow: 1;
		height: 100%;
	}

	.side-panel__body-scroll-indicator {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		padding: 2em;
		background: rgba(255, 255, 255, 0.7);
		z-index: $side-panel-layer;
		pointer-events: none;

		i {
			font-size: 32px;
		}
	}

	.checkbox label:after {
		z-index: 0;
	}
}

@media #{$medium-screen} {
	.side-panel--open {
		min-width: 100%;
		top: 45px;
	}
}
