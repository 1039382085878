// VARIABLES
$btn-small-padding: 3px;

.btn {
	@extend .u-reset-btn;
	@extend .small-flexible-island;
	@extend .horizontal-list;
	@extend .horizontal-list--wrap;
	@extend .horizontal-list--center;
	// Border ensures the button is the same height as inputs and other elements
	// with a visible border.
	border: 1px solid transparent;
	justify-content: center;
	cursor: pointer;
}

.btn--fill__disabled {
	background-color: $inactive;
	border-color: $inactive;
	color: $primary-text-color;
	cursor: default;
	font-weight: 700;
	text-transform: lowercase;
	border-radius: $border-radius;
	padding: 0.5em 1em;
	opacity: 0.6;
}

.btn__p-0 {
	padding: 0;
}

.btn:disabled {
	opacity: 0.6;
	cursor: default;
}

.btn--hollow {
	font-weight: 700;
	.custom-icon {
		font-size: 30px;
	}
}

.btn--hollow__danger {
	color: $red;
}

.btn--hollow__danger:hover,
.btn--hollow__danger:focus {
	color: $darkred;
}

.btn--fill {
	background-color: $blue;
	color: $white;
	font-weight: 700;
	text-transform: lowercase;
	border-radius: $border-radius;
	padding: 0.5em 1em;
}

.btn--fill:hover {
	background-color: $darkblue;
	border-color: $darkblue;
}

.btn--fill__grey {
	color: $primary-text-color;
	background-color: $fadedpink;
	border-color: $fadedpink;
}

.btn--fill__grey:hover,
.btn--fill__grey:focus {
	background-color: $lightgrey;
	border-color: $lightgrey;
}

.btn--fill__danger {
	background-color: $red;
	border-color: $red;
}

.btn--fill__danger:hover,
.btn--fill__danger:focus {
	background-color: $darkred;
	border-color: $darkred;
}

.btn--black-border {
	color: $primary-text-color;
	border: 1px solid $primary-text-color;
	font-weight: 700;
	text-transform: lowercase;
	border-radius: $border-radius;
	padding: 0.5em 1em;

	&:hover {
		background-color: $very-light-grey;
	}

	&:focus {
		background: $fadedpink;
	}

	&:disabled {
		background-color: $inactive;
		border-color: $inactive;
		color: $primary-text-color;
		cursor: default;
	}
}

.btn--fill:disabled,
.btn--fill__grey:disabled,
.btn--fill__danger:disabled {
	background-color: $inactive;
	border-color: $inactive;
	color: $primary-text-color;
	cursor: default;
}

.btn--link-el {
	@extend .u-reset-link;
}

.btn--auto-complete-inline {
	padding: 0.6rem 0.7rem;
}

.icon-btn__query-builder {
	font-weight: 700;
	background-color: $white;
	border: 1px solid $grey;
	border-radius: $border-radius;
	&:hover {
		color: $darkblue;
	}
	&:disabled {
		color: $inactive;
		cursor: default;
	}
	&.active {
		color: $darkblue;
	}
}

.icon-btn {
	color: $primary-text-color;
	border-radius: $border-radius;
}
.icon-btn:focus {
	background: $fadedpink;
}
.icon-btn:hover {
	background: $lightgrey;
}
.icon-btn:disabled {
	background: none;
	cursor: default;
}
.icon-btn__no-label {
	border-radius: 100%;
}
.icon-btn-inner {
	display: flex;
	align-items: center;
}
.icon-btn-label {
	margin-left: 5px;
}
.icon-btn__white-background:hover {
	background: white;
}
.icon-btn__danger {
	color: $red;
}
.icon-btn:not(.icon-btn__no-label).icon-btn__active {
	background: $lightgrey;
}
.icon-btn__no-label.icon-btn__active {
	color: $kiplot-blue;
}
.icon-btn__raised {
	@extend .box-shadow;
	background: $fadedpink;
	margin: 5px;
}
.icon-btn__on-dark-background {
	color: $white;
}
.icon-btn__on-dark-background:hover,
.icon-btn__on-dark-background:focus {
	color: $primary-text-color;
}

.icon-btn__on-blue-background {
	color: $white;
}
.icon-btn__on-blue-background:hover,
.icon-btn__on-blue-background:focus {
	background: $deep-blue;
}

.icon-btn__blue-background {
	background-color: $blue;
	color: $white;

	&:focus,
	&:hover {
		background-color: $kiplot-blue;
	}
}

.btn--rounded {
	border-radius: $border-radius;
	padding-left: 1em;
	padding-right: 1em;
}
.btn--rounded:focus {
	background: $fadedpink;
}

.btn--rounded:hover {
	background: $very-light-grey;
}

.small-btn {
	@extend .horizontal-list;
	@extend .horizontal-list--center;
	@extend .horizontal-list--wrap;
	@extend .half-small-island;
	@extend .u-reset-btn;
	cursor: pointer;
	font-size: smaller;

	i {
		// Correct whitespace for inner icon
		// display: block;
		// Icons are usually taller than text height
		// Downsize to ensure line heights match
		font-size: calc(#{$font-size} * #{$line-height});
	}
}

.small-btn:disabled {
	opacity: 0.6;
	cursor: default;
}

a.btn {
	text-decoration: none;
}

.link-btn {
	background: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	color: $darkgrey;
	position: relative;
	padding: 3px 0;
	&:after {
		content: "";
		position: absolute;
		height: 1px;
		margin: 0 auto;
		width: 100%;
		right: 0;
		left: 0;
		color: $darkgrey;
		background-color: $darkgrey;
		bottom: 0; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
	}

	.icon-exit {
		font-size: 12px;
		font-weight: 700;
		margin-right: 5px;
	}
}

.link-btn__blue {
	color: $blue;
	&:after {
		color: $blue;
		background-color: $blue;
	}
}
