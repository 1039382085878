@use "sass:color";

.cc-unplanned-white-box {
	margin-bottom: 1rem;
}

.cc-unplanned-stage {
	max-height: 14rem;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;

	&--is-dragging {
		overflow: hidden;
	}

	h4 {
		font-weight: normal;
		font-size: 1.15rem;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		transition: background-color 250ms ease-in-out;
		position: relative;
		min-height: 3rem;
	}

	&__loading {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $white;
		opacity: 0.7;
	}

	&__drop-area {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $white;
		opacity: 0.7;
		border-radius: $STRATEGY--border-radius;
		border: 2px $grey-4 dashed;
		pointer-events: none;
		transition: all 250ms ease-in-out;

		&--is-over {
			background: color.adjust($blue, $alpha: -0.8);
		}

		h3 {
			width: 100%;
			text-align: center;
		}
	}
}
