h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	font-weight: bold;
}

h1 {
	font-size: $STRATEGY--header-1-size;
}

h2 {
	font-size: $STRATEGY--header-2-size;
}

h3 {
	font-size: $STRATEGY--header-3-size;
}

h4 {
	font-size: $STRATEGY--header-4-size;
}

h5 {
	font-size: $STRATEGY--header-5-size;
}

button {
	// Fixing extra margin on safari
	-webkit-appearance: none;
	-webkit-margin-before: 0;
	-webkit-margin-end: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-padding-before: 0;
	-webkit-padding-end: 0;
	-webkit-padding-after: 0;
	-webkit-padding-start: 0;
	background-color: transparent;
	border: none;
	cursor: pointer;
	pointer-events: all;
}

input::-ms-clear {
	display: none;
	height: 0;
	width: 0;
}

// Third party library overwrites
.react-datepicker-wrapper {
	width: 100%;
	height: 100%;
}

.react-datepicker__input-container {
	height: 100%;
	width: 100%;
}
