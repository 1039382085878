.dataset-usage-title {
	font-weight: 700;
	font-size: 2rem;
}

.dataset-usage-tile {
	margin-bottom: 20px;

	.dataset-usage-svg {
		width: 100%;
	}

	.dataset-usage-header {
		font-weight: 700;
		font-size: 24px;
	}

	.dataset-usage-divider {
		stroke: $grey;
		stroke-width: 1px;
	}

	.dataset-usage-node {
		cursor: pointer;
		fill: $fadedpink;
		stroke: $lightgrey;
		stroke-width: 1px;
	}

	.dataset-usage-link {
		cursor: default;
		fill: none;
		stroke: $blue;
		stroke-width: 4;
		stroke-opacity: 0.1;
	}

	.dataset-usage-labels {
		font-size: 10px;
	}
}
