@use "sass:color";
@import "CreateInitiative/style";

.roadmap-stage {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch;

	&__row {
		width: 100%;
		padding: $small-spacing $normal-spacing;
		border-bottom: 3px solid $grey-1;
	}
}

.roadmap-initiatives-wrapper {
	display: flex;
	flex-direction: column;
	transition: background-color 250ms ease-in-out;
	position: relative;
	width: 100%;
	row-gap: $smaller-spacing;

	&--is-active {
		background-color: color.adjust($blue, $alpha: -0.8);
	}

	&--is-dropping {
		background: color.adjust($blue, $alpha: -0.8);
	}

	&__loading {
		align-items: center;
		justify-content: center;
		height: 100%;
		min-height: 2rem;

		&--overlay {
			position: absolute;
			z-index: 7;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgb(255 255 255 / 65%);
			left: 0;
			top: 0;
		}
	}
}

.roadmap-swimlane-header {
	position: sticky;
	left: 0;
	z-index: 1;
	background-color: $white;
	color: $primary-text-color;
	padding-bottom: $small-spacing;

	h4 {
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: $STRATEGY--font-bigger-size;
	}
}

.roadmap-initiative-outcomes {
	z-index: 2;
	padding-top: 2rem;
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	align-items: flex-start;
}

.roadmap-initiative-no-outcomes {
	position: relative;
	z-index: 2;
	padding: 2.5rem 0 0.5rem 1rem;
	text-align: left;
	font-size: 0.68rem;
	color: $grey-4;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;
	white-space: nowrap;
}

.roadmap-outcomes-cell {
	flex: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	transition: background-color 250ms ease-in-out;
	position: relative;
	min-height: 2rem;
	padding: calc($small-spacing / 2);

	&__loading {
		align-items: center;
		justify-content: center;
		height: 100%;

		&--overlay {
			position: absolute;
			z-index: 10;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgb(255 255 255 / 65%);
			left: 0;
			top: 0;
		}
	}
}

.roadmap-create-initiative-row {
	display: flex;
	align-items: flex-start;
	width: 100%;

	&__cell {
		flex: auto;
		width: 100%;
		padding: $smallest-spacing 0;
	}
}
