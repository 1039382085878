@import "../../../../../../lib/style/breakPoints";

// extendable so this faf does not need to be duplicated if used elsewhere
%dataset-icon-menu {
	.icon-menu {
		@include hide;

		.delete-dataset-button,
		.duplicate-dataset-button,
		.sync-dataset-button,
		.upload-dataset-button,
		.download-dataset-button,
		.share-dataset-button,
		.edit-dataset-button {
			@include hide;
		}
	}
}

%dataset-mobile-menu {
	.mobile-menu {
		@include show;

		.delete-dataset-icon,
		.duplicate-dataset-icon,
		.sync-dataset-icon,
		.upload-dataset-icon,
		.download-dataset-icon,
		.share-dataset-button-icon,
		.edit-dataset-icon {
			@include hide;
		}
	}
}

.dataset-row__menu {
	position: absolute;
	right: 10px;
	bottom: 0px;
	pointer-events: all;

	@media #{$mobile-break-point} {
		// IE 11 needs a value to be specified
		bottom: 25%;
	}

	@extend %dataset-icon-menu;
	.mobile-menu {
		@media #{$mobile-break-point} {
			@include hide;
		}
	}

	@extend %dataset-mobile-menu;
	.icon-menu {
		@media #{$mobile-break-point} {
			@include show;
		}
	}
}
