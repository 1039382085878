.grey-box {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: $grey-1;
	border-radius: $STRATEGY--border-radius;
	margin-bottom: 2rem;

	&--condensed {
		padding: 0.5rem 1rem;

		.grey-box-content {
			margin: 0.5rem 0;
		}
	}
}

.grey-box-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__title {
		display: flex;
		align-items: center;
		min-height: 2.2rem;

		h3 {
			margin-right: 0.5rem;
		}
	}

	&__controls {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}
}

.grey-box-content {
	margin-top: 1rem;
}
