@import "./Stage/style";
@import "./StageInfo/style";
@import "./UnplannedOutcomes/style";
@import "./DependencyArrows/style";
@import "./StageLoader/style";

.teams-period-headers {
	position: sticky;
	top: 0;
	z-index: 3;
	display: flex;
	width: 100%;
	height: $STRATEGY--CC-period-header-height;
	min-height: $STRATEGY--CC-period-header-height;
	background-color: $white;
	column-gap: $small-spacing;
	border-bottom: 3px solid $grey-1;
	border-radius: $STRATEGY--border-radius $STRATEGY--border-radius 0 0;
	padding: 0 $small-spacing;

	&__nav {
		opacity: 0;
		position: absolute;
		top: 0;
		width: 70px;
		height: $STRATEGY--CC-period-header-height;
		background: $grey-1;
		transition: all 150ms ease-in-out;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			color: $blue;
		}

		&--left {
			left: 0;
			background: linear-gradient(90deg, $grey-1 0%, $grey-1 30%, rgba(255, 255, 255, 0) 100%);
		}

		&--right {
			right: 0;
			justify-content: flex-end;
			background: linear-gradient(270deg, $grey-1 0%, $grey-1 30%, rgba(255, 255, 255, 0) 100%);
		}

		&--show {
			opacity: 1;
		}
	}
}

.teams-period-header {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	flex: auto;
	width: 100%;
	height: 100%;
	padding: $small-spacing $smallest-spacing;

	&:last-child {
		border-top-right-radius: $STRATEGY--border-radius;
	}

	&__label {
		font-size: $STRATEGY--font-normal-size;
		font-weight: bold;
	}

	&__dates {
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		color: $grey-4;
	}

	&__color-code {
		width: 100%;
		height: 19px;
		display: flex;
		padding: 0.1rem 0.5rem;
		margin-top: 0.3rem;
		border-radius: $STRATEGY--border-radius;

		span {
			font-size: $STRATEGY--font-smaller-size;
			font-weight: bold;
		}

		&--current {
			background-color: #1b26ed;
			color: $white;
		}
		&--1 {
			background-color: #565ff1;
			color: $white;
		}
		&--2 {
			background-color: #7178f0;
			color: $white;
		}
		&--3 {
			background-color: #aeb1f4;
			color: $white;
		}
		&--future {
			background-color: #d9d9d9;
			color: $grey-5;
		}
	}
}

.teams-period-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
}

.planning-stage-measure-row {
	width: calc(100% - $small-spacing * 2);
	height: 0;
	margin-left: $small-spacing;
}
