.initiative-list-item {
	display: flex;
	width: 100%;
	align-items: stretch;
	justify-content: stretch;
	height: 4rem;
	transition: $STRATEGY--button-transition;

	&__content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: stretch;
		background-color: $grey-1;
		border-radius: $STRATEGY--border-radius;

		&__details {
			flex: 1;
			padding: 1rem;
			text-decoration: none;

			h3 {
				margin-bottom: 0.2rem;
				max-width: 60vw;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				white-space: nowrap;
				text-decoration: none;
			}

			.team {
				display: inline-block;
				font-size: $STRATEGY--font-smaller-size;
				color: $grey-4;
				text-decoration: none;
			}
		}

		&__actions {
			flex-basis: 160px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 1rem;

			button {
				margin: 0 2px;
			}
		}

		&:hover {
			background-color: $grey-2;
		}
	}
}
