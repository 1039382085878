.color-item__label-input {
	display: flex;
	flex-direction: column;
	margin-right: 1.5em;
	align-items: center;

	span {
		@extend .font-bold;
		font-size: smaller;
		margin-bottom: 6px;
	}
}

.add-color-dialog__label-input {
	display: flex;
	flex-direction: column;
	margin-top: -15px;
	align-items: center;

	span {
		@extend .font-bold;
		font-size: smaller;
		margin-bottom: 6px;
	}
}
