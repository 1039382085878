/**
	if you put flex on for all browsers Safari implodes in on itself all other browsers are fine.
	If you take flex off all browsers are fine but on IE if the table is scrollable <left right> the height of the scroll bar does not adjust the height of the <Card />
	element so the submit button gets pushed outside of <Card />
 */
.box-wrapper__windows-browsers {
	display: flex;
	flex-direction: column;
}

.box-wrapper {
	@extend .box-shadow, .flexible-island;
	position: relative;
	margin: 30px 20px 20px 20px;

	@media #{$medium-screen} {
		margin: 10px 8px;
	}

	@media #{$small-screen} {
		margin: 15px 8px;
	}

	p:first-child,
	h1:first-child,
	h2:first-child,
	h3:first-child,
	h4:first-child {
		margin-top: 0;
	}
}

.rounded-box {
	border-radius: $border-radius;
}
