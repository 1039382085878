.archived-page {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem;
	border-radius: $STRATEGY--border-radius;
	background-color: $blue;
	color: white;
	font-weight: bold;
	margin-bottom: 1rem;

	i {
		margin-right: 1rem;
	}
}
