.modal-overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(255, 255, 255, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: calc(#{$small-vertical-unit} * 2) calc(#{$small-horizontal-unit} * 2);
	z-index: $modal-base-layer;
}

.modal-navigation {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding-right: 2px !important;
}

.modal-title {
	text-transform: uppercase;
	font-weight: 700;
}

.modal-section {
	padding: 1em 0;
}

.modal-section__border-bottom {
	border-bottom: 1px solid $lightgrey;
}

.modal-section-title {
	margin-bottom: 0.5em;
}

.modal-section-subtitle {
	color: $grey;
	font-size: smaller;
	margin-bottom: 0.5em;
}

.modal-content {
	@extend .island;
	@extend .box-shadow;
	background: $white;
	overflow: auto;
	border-radius: 4px;
	max-height: 100%;
	outline: 0;
	min-width: 610px;

	@media #{$medium-screen} {
		padding: 15px;
		min-width: 95%;
	}

	.modal-select {
		@extend .custom-select;
		padding-left: 10px;
		border-radius: 5px;
	}

	.modal-input {
		@extend .input;
		height: 35px;
		width: 100%;
		background-color: $ghostwhite;
		border-color: $dark-pale-grey;
		padding: 0.6rem 0.66rem;
	}

	.modal__label {
		@extend .small-form-label;
	}

	.modal-form {
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 15px;
	}

	@media #{$small-screen} {
		padding: 15px;
	}

	p {
		@extend .u-reset-p;
	}

	h3,
	h4 {
		@extend .u-reset-h;
		// TODO: Set global heading styles
		font-weight: bold;
	}

	table {
		font-size: initial;
	}

	td {
		padding: 1em 0;
	}
}

.modal-input-select {
	width: 99.7%;
	margin-left: 1px;
}

.modal-close__btn {
	z-index: $modal-mid-layer;
}

%btn-wrapper {
	padding: 5px 0px;
	display: flex;
}

.btn-wrapper__right-align {
	@extend %btn-wrapper;
	justify-content: flex-end;
}

.btn-wrapper__left-align {
	@extend %btn-wrapper;
	justify-content: flex-start;
}

.modal-message {
	padding-top: 0.6rem;
}

.modal-body__medium {
	min-width: 610px;
	max-width: 40vh;

	@media #{$medium-screen} {
		max-width: 100%;
		min-width: 100%;
	}
}

.modal--progress-btn {
	@extend .btn--fill;
	width: 89px;
	font-size: 0.6em;

	.spinner-wrapper {
		left: -12px;
	}
}
