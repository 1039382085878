.control-center-goals {
	flex: 1 0 calc(25% - 0.75rem);
	min-width: 20%;
	max-width: 600px;
	margin-left: 1rem;

	&:first-child {
		margin-left: 0;
	}

	&__wrapper {
		margin-bottom: 1rem;
	}

	&__wrapper-content {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: visible;
		padding-bottom: 2px;

		.grey-box {
			margin-bottom: 4px;
		}
	}

	.white-box-content {
		margin-bottom: 0;
	}
}
