.resizable-frame__dragzone {
	height: 100%;
	display: flex;
	position: absolute;
	cursor: ew-resize;
	z-index: $modal-top-layer;
}

.resize-indicator {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	align-items: center;
	height: 100%;

	.circle {
		height: 4px;
		width: 4px;
		background: $almostblack;
		border-radius: 50%;
		padding: 1px;
		opacity: 0.8;
		margin: 3px;
	}
}
