.delete-report-modal {
	padding-top: 10px;

	&--checkbox {
		padding-top: 10px;
		display: flex;
		align-items: center;
		font-size: small;

		&-label {
			@extend .link-btn__blue;
			@extend .font-bold;
			padding-left: 5px;
			cursor: pointer;
		}
	}

	&--table {
		padding: 10px 0;
		font-size: small;
		max-height: 40vh;
		overflow-y: auto;
		overflow-x: auto;

		&__row {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			border: none;
			border-radius: $border-radius;
			width: 100%;
			font-size: small;

			td {
				@extend .flexible-island;
				padding-left: 10px;
				border-bottom: 1px solid $lightgrey;
				display: flex;
				flex-grow: 1;
			}
		}
	}

	.disabled-row {
		background: $lightgrey;
		color: $grey;
	}
}
