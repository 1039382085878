.color-legend {
	@extend .horizontal-list;
	@extend .horizontal-list--wrap;

	overflow: hidden;

	.color-option {
		/*Do not allow options to shrink*/
		flex-shrink: 0;
	}

	.color-option__system {
		cursor: default;
	}

	.color-option--inactive {
		opacity: 0.4;
	}

	.color-option--value-not-present {
		opacity: 0.6;
	}

	.color-legend-toggle {
		white-space: nowrap;
		flex-shrink: 0;
	}

	.color-icon {
		$dim: 0.8em;
		width: $dim;
		height: $dim;
		border-radius: $dim;
		border-width: 1.5px;
		border-style: solid;
	}
}

.print-to-pdf {
	.color-legend {
		max-height: none !important;
	}
	.color-option.invisible {
		visibility: visible;
	}
	.color-legend-toggle {
		display: none;
	}
}

.generic-select-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	// This is the same height as the linear legend and prevents a slight jump in ui
	height: 47.75px;
}
