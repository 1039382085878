.tabs-list {
	padding: 0;
	margin: 0 0 1rem 0;

	&--compact {
		margin: 0;
	}

	&__tab-wrapper {
		display: inline-block;
	}

	&__tab {
		padding: 0.6rem 0.66rem;
		color: $grey-3;
		border-bottom: 3px solid $grey-3;
		cursor: pointer;
		font-size: $STRATEGY--font-bigger-size;
		font-weight: bold;
		margin-right: 1rem;
		display: inline-block;
		vertical-align: bottom;
		border-radius: $STRATEGY--border-radius $STRATEGY--border-radius 0 0;
		transition: $STRATEGY--button-transition;
		text-decoration: none;

		&:hover {
			background-color: $grey-1;
		}

		&--selected {
			color: $blue;
			border-bottom: 3px solid $kiplot-red;
		}

		&--compact {
			padding: 0.2rem 0.1rem;
		}
	}
}
