.project-kpis {
	width: 100%;

	&__controls {
		margin-top: 20px;
	}
}

.project-table-controls {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.input-search-wrapper {
		width: 50%;
		font-size: smaller;
	}
}

.header-detail {
	&__kpiId-level {
		.context-menu-wrapper {
			margin-left: auto;
		}
	}

	&__kpi-type-level {
		padding-left: 20px;
		font-weight: bold;
		cursor: default;
	}
}
