@import "../../components/ResourceTable/style";
@import "Timesheets/style";
@import "Settings/style";

.resource-page {
	.content-margin {
		@media (min-width: 1000px) {
			margin: auto;
			padding-left: 5%;
			padding-right: 5%;
		}
	}

	.page-contents {
		height: 100%;
	}
}
