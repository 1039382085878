.content-modal {
	padding: 2rem;
	border-radius: $STRATEGY--border-radius;
	background-color: $white;
	overflow: auto;
	outline: 0;
	min-width: 300px;
	width: 80%;
	box-shadow: $STRATEGY--shadow-distance-3;
	z-index: $STRATEGY--modal-layer;

	&--small {
		max-width: 400px;
	}

	&--medium {
		max-width: 600px;
	}

	&--large {
		max-width: 800px;
	}

	&--x-large {
		max-width: 1200px;
	}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: $STRATEGY--overlay-content-layer;
		pointer-events: all;
		width: 100%;
		height: 100%;

		/* slightly transparent fallback */
		background-color: rgba(255, 255, 255, 0.65);

		/* if backdrop support: very transparent and blurred */
		@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
			background-color: rgba(255, 255, 255, 0.5);
			-webkit-backdrop-filter: blur(4px);
			backdrop-filter: blur(4px);
		}
	}

	&__header {
		margin-bottom: 2rem;
	}

	&__editable-header {
		margin-bottom: 1rem;
	}

	&__body {
		position: relative;

		&--has-loader {
			min-height: 250px;
			display: flex;
			flex-direction: column;

			.modal-controls {
				margin-top: auto;
			}
		}
	}
}

.modal-controls {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-top: 3rem;

	&__left {
		display: flex;
		flex-wrap: nowrap;
		width: 50%;
		align-items: center;
		justify-content: flex-start;

		& > * {
			margin-right: 0.5rem;
		}
	}

	&__right {
		display: flex;
		flex-wrap: nowrap;
		width: 50%;
		align-items: center;
		justify-content: flex-end;

		& > * {
			margin-left: 0.5rem;
		}
	}
}

.modal-close-button {
	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 1;
}
