.list-item {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	background-color: $grey-1;
	transition: $STRATEGY--button-transition;
	border-radius: $STRATEGY--border-radius;

	&:hover {
		background-color: $grey-2;
	}

	&__details {
		width: calc(100% - 80px);
		padding: 1rem;
		text-decoration: none;
		h3 {
			text-decoration: none;
		}
	}

	&__actions {
		width: 80px;
		display: flex;
		justify-content: flex-end;
		padding-right: 0.5rem;
	}
}

.archive-team-modal {
	max-width: 280px;

	&__warning-text {
		font-size: small;
		margin-bottom: 1rem;
		font-weight: bold;
		color: $kiplot-red;
	}

	&__text {
		font-size: small;
		margin-bottom: 1rem;
		color: $grey-5;
	}
}
