.outcome-info {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__header {
		font-weight: bold;
		margin-top: 10px;

		span {
			color: $blue;
		}
	}

	&__row {
		display: flex;
		align-items: center;
		margin-top: 8px;
		width: 100%;
	}

	&__legend {
		display: block;
		// hack for some browsers
		min-width: 20px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-left: 20px;

		&--red {
			background-color: $STRATEGY--cc-effort-risk-color-red;
		}
		&--amber {
			background-color: $STRATEGY--cc-effort-risk-color-amber;
		}
		&--green {
			background-color: $STRATEGY--cc-effort-risk-color-green;
		}
		&--icon {
			i {
				font-size: 20px;
			}
		}
	}

	&__text {
		margin-left: 5px;
		color: $grey-5;

		&--no-legend {
			margin-left: 20px;
		}
	}
}
