.search-field {
	display: flex;
	align-items: center;
	height: $STRATEGY--field-height;
	width: 100%;
	min-width: 200px;
	border-radius: $STRATEGY--field-border-radius;
	border: $STRATEGY--field-border-width solid $white;

	&--is-focused {
		border: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color;
	}

	&__icon {
		display: flex;
		align-items: center;
		height: 100%;
		width: 35px;
		background-color: $white;
		padding: 0 0.5rem;
		transition: all 250ms ease-in-out;
	}

	&__input {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		border: none;
		padding: 0 0.5rem 0 0;
		position: relative;
		transition: all 250ms ease-in-out;
		font-size: $STRATEGY--font-normal-size;

		&:focus-visible {
			outline: none;
		}
	}
}
