@import "../../../../../../lib/style/variables";
@import "../../../../../../lib/style/breakPoints";

.dataset-name {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	margin-bottom: 15px;
	max-width: 69vw;
	position: relative;

	@media #{$mobile-break-point} {
		margin-bottom: 5px;
		max-width: $max-page-width - 200px;
	}

	@media #{$navigation-collapse-break-point} {
		max-width: 55vw;
	}

	.content-editable__bold {
		max-width: 95%;
		padding: 5px;

		@media #{$mobile-break-point} {
			max-width: 88%;
		}

		&.focus {
			// IE 11 fallback
			background-color: $standardgrey;
			background-color: $standardgrey + d4;
			z-index: 2;
			padding: 5px;
			border-radius: $border-radius;

			.content-editable-can-edit__input {
				word-wrap: inherit;
				overflow: visible;
				white-space: normal;
				text-overflow: inherit;
			}
		}

		.content-editable-can-edit__input {
			word-wrap: break-word;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}
