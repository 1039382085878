@import "variables";

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// SVG should sit behind the content and have an equal height to bod
// http://jsbin.com/ceweyo/38/edit?html,css,output
html {
	/* Viewport height and width - no scroll bars */
	height: 100%;
	width: 100%;
	font-size: 111.1%;

	@media #{$desktop-screen} {
		font-size: 100%;
	}

	@media #{$hd-screen} {
		font-size: 86.9%;
	}

	@media #{$mobile-screen} {
		font-size: 80%;
	}

	@media #{$medium-screen} {
		font-size: 71.4%;
	}

	@media #{$small-screen} {
		font-size: 66.6%;
	}
}

body {
	// Unset UAS
	margin: inherit;

	line-height: $line-height;
	font-family: century-gothic, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;

	background-color: $white;
	color: $primary-text-color;

	display: flex;
	flex-direction: column;
	/* Remove whitespace at bottom of inline element */
	vertical-align: middle;
	/* Ensure a min-width as inline-block does not stretch when empty */
	min-width: 100%;

	/* Height of parent, for when contents are smaller */
	min-height: 100%;
	/* Child .root-svg is relative to this */
	position: relative;

	height: auto;

	font-size: 0.9rem;

	@media #{$desktop-screen} {
		font-size: 1rem;
	}

	@media #{$hd-screen} {
		font-size: 1.15rem;
	}

	@media #{$mobile-screen} {
		font-size: 1.25rem;
	}

	@media #{$medium-screen} {
		font-size: 1.4rem;
	}

	@media #{$small-screen} {
		font-size: 1.5rem;
	}
}

button {
	// Fixing extra margin on safari
	-webkit-appearance: none;
	-webkit-margin-before: 0;
	-webkit-margin-end: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-padding-before: 0;
	-webkit-padding-end: 0;
	-webkit-padding-after: 0;
	-webkit-padding-start: 0;
}

input::-ms-clear {
	display: none;
	height: 0;
	width: 0;
}

button,
textarea,
select,
input,
a {
	font: unset;
	color: inherit;
	//  IE 11 fix for font being overridden by browser default
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: inherit;
}

select {
	border: 1px solid $grey-2;
	background: $white;
}
