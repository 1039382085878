.initiative-benefits {
	width: 100%;

	&__controls {
		margin-top: 20px;
	}
}

.initiative-table-controls {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.input-search-wrapper {
		width: 50%;
		font-size: smaller;
	}
}
