.radio-styled_wrapper {
	position: relative;
	display: block;
	height: 27px;
	padding-top: 3px;

	.radio-styled {
		position: absolute;
		z-index: $menu-top-layer;
		cursor: pointer;
		width: 21px;
		height: 21px;
		opacity: 0;

		& + label {
			border: 1px solid $translucent-black;
			height: 22px;
			width: 22px;
			border-radius: 50%;
			margin: 0;
			padding-right: 3px;
			padding-bottom: 1px;
			transition: 0.3s;
			cursor: pointer;
		}

		&:checked + label {
			border: 1px solid $blue;
		}

		&:hover + label {
			box-shadow: 0 0 0 4px $transparent-black;
			transition: 0.3s;
		}

		// Box focus
		&:focus + label {
			transition: 0.3s;
			box-shadow: 0 0 0 3px $transparent-black;
		}

		&:disabled + label {
			border: none;
			background: #ddd;
			cursor: auto;
		}

		& + label:before {
			content: "";
			display: inline-block;
			vertical-align: text-top;
			width: 18px;
			height: 20px;
			background: white;
			border-radius: 50%;
			transition: transform 0.18s cubic-bezier(0.45, 1.8, 0.5, 0.75);
			transform: scale(0, 0);
		}

		&:checked + label:before {
			transition: transform 0.18s cubic-bezier(0.45, 1.8, 0.5, 0.75);
			transform: scale(1, 1);
			margin-left: 3px;
			margin-top: 3px;
			background: $blue;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			padding: 0px;
		}
	}
}
