@import "./KeyResult/style";

.objective-list-item {
	display: flex;
	width: 100%;
	align-items: stretch;
	justify-content: stretch;
	transition: $STRATEGY--button-transition;

	&__content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: stretch;
		background-color: $grey-1;
		border-radius: $STRATEGY--border-radius;
		max-width: 100%;

		&__details {
			flex: 1;
			max-width: calc(100% - 80px);
			padding: 1rem;
			text-decoration: none;

			h3 {
				max-width: 100%;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				white-space: nowrap;
				text-decoration: none;
			}
		}

		&__actions {
			flex-basis: 80px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-right: 0.5rem;
			max-width: 80px;

			button {
				margin: 0 2px;
			}
		}

		&:hover {
			background-color: $grey-2;
		}
	}
}

.no-objective-list {
	max-height: 400px;
	overflow-y: auto;

	& > * {
		margin-bottom: $small-spacing;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.assigned-objective-list {
	& > * {
		margin-bottom: $small-spacing;
	}

	&:last-child {
		margin-bottom: 0;
	}
}
