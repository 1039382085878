@import "PageControls/style";
@import "TileHeaderColors/style";

.color-item {
	@extend .small-flexible-island;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	&__controls {
		display: inline-flex;

		.styled-checkbox-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 1em;
		}
	}
}

.add-color-dialog {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 1em 0;

	&__controls {
		display: inline-flex;

		.styled-checkbox-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 1em;
		}
	}

	&__duplicate {
		color: $red;
		font-size: 12px;
		font-weight: bold;
		width: 100%;
	}
}

.add-new-color-btn {
	@extend .btn;
	@extend .btn--fill;

	width: 40px;
	height: 40px;
	margin-left: auto;
	margin-top: auto;
	color: $white;

	&:hover,
	&:focus {
		background-color: $darkblue;
		border-color: $darkblue;
	}
}

.color-definition-list {
	max-height: 500px;
	width: 100%;
	overflow-y: auto;
	margin-bottom: 1em;

	&__default-label {
		@extend .font-bold;
		font-size: smaller;
	}
}
