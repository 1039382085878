.outcome-detail-modal {
	max-height: 90vh;
	overflow-y: auto;
	position: relative;

	// overwrites
	.page-wrapper {
		padding: 0;
	}

	.page-with-gutter__content {
		min-height: auto;
	}
}
