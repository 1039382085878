.report-info__header {
	@extend .report-info__section;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.report-info__title {
		@extend .font-bold;
		margin: 0;
		text-transform: uppercase;
	}

	.report-info__close-button {
		height: 40px;
		width: 40px;

		&:hover,
		&:focus {
			background: $deep-blue;
		}

		.close-icon {
			color: $white;
			font-size: 30px;
		}
	}
}
