.concertina-menu {
	width: 100%; // IE11 fix.
}
.concertina-menu__btn {
	display: flex;
	align-items: center;
	font-size: 1rem;

	& > span {
		display: flex;
		flex-grow: 1;
		overflow: hidden;
		word-wrap: break-word;
	}

	& > i {
		width: 20px;
	}
}

.concertina-menu__list-item {
	font-size: 0.8rem;
	padding-left: 16px;
}
