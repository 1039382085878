$loader-size: 5em;
$check-height: calc(#{$loader-size} / 2.1);
$check-width: calc(#{$check-height} / 2.1);
$check-left: 1em;
$check-thickness: 3px;
$check-color: $blue;

.circle-loader {
	margin-bottom: calc(#{$loader-size} / 2);
	border: 3px solid $grey-3;
	border-left-color: $check-color;
	animation: loader-spin 1.2s infinite linear;
	position: relative;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	width: $loader-size;
	height: $loader-size;

	&--load-complete {
		-webkit-animation: none;
		animation: none;
		border-color: $check-color;
		transition: border 500ms ease-out;
	}

	&__ie11-done {
		color: $check-color;
		position: absolute;
		left: 24%;
		top: 24%;
		opacity: 1;
		animation-duration: 800ms;
		animation-timing-function: ease;
		animation-name: checkmark-done;

		i {
			font-size: 2.5rem;
		}
	}

	&__checkmark {
		display: none;

		&.draw:after {
			animation-duration: 800ms;
			animation-timing-function: ease;
			animation-name: checkmark;
			transform: scaleX(-1) rotate(135deg);
		}

		&:after {
			opacity: 1;
			height: $check-height;
			width: $check-width;
			transform-origin: left top;
			border-right: $check-thickness solid $check-color;
			border-top: $check-thickness solid $check-color;
			content: "";
			left: $check-left;
			top: $check-height;
			position: absolute;
		}
	}
}

@keyframes loader-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: $check-width;
		opacity: 1;
	}
	40% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
	100% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
}

@keyframes checkmark-done {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.loader-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: $STRATEGY--modal-layer + 1;
	border-radius: $STRATEGY--border-radius;

	/* slightly transparent fallback */
	background-color: rgba(255, 255, 255, 0.65);

	/* if backdrop support: very transparent and blurred */
	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		background-color: rgba(255, 255, 255, 0.65);
		-webkit-backdrop-filter: blur(20px);
		backdrop-filter: blur(4px) brightness(90%);
	}
}

.loader-message {
	color: $check-color;
	font-size: $STRATEGY--font-bigger-size;
}

.spinner-transition {
	// class naming for "react-transition-group"
	&-enter {
		opacity: 0;
	}

	&-enter-active {
		opacity: 1;
		transition: opacity 250ms ease-in;
	}

	&-exit {
		opacity: 1;
	}
}

$dark-dot: $grey-5;
$light-dot: $grey-2;
$dot-gap: 9px;

.dot-loader {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: $dark-dot;
	box-shadow:
		$dot-gap 0 $light-dot,
		-$dot-gap 0 $light-dot;
	position: relative;
	animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
	0% {
		background-color: $dark-dot;
		box-shadow:
			$dot-gap 0 $dark-dot,
			-$dot-gap 0 $light-dot;
	}
	50% {
		background-color: $light-dot;
		box-shadow:
			$dot-gap 0 $dark-dot,
			-$dot-gap 0 $dark-dot;
	}
	100% {
		background-color: $dark-dot;
		box-shadow:
			$dot-gap 0 $light-dot,
			-$dot-gap 0 $dark-dot;
	}
}

$circle-size: 24px;

.spinner-loader {
	width: $circle-size;
	height: $circle-size;
	border: 2px solid $grey-4;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;

	&::after {
		content: "";
		box-sizing: border-box;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $circle-size;
		height: $circle-size;
		border-radius: 50%;
		border: 2px solid transparent;
		border-bottom-color: $kiplot-blue;
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
