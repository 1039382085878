.dataset-revisions-table {
	&__wrapper {
		width: 1200px;
		max-width: 90vh;
		max-height: 300px;

		overflow-y: scroll;
		overflow-x: auto;

		margin-top: 1em;
		margin-bottom: 1em;
	}

	&__item {
		cursor: pointer;
		transition: background 0.1s ease;

		td {
			padding: 0.5em 0;
		}

		&--no-items {
			text-align: center;
		}

		&:hover {
			background: $very-light-grey;
		}
	}

	&__item-date {
		width: 180px;
	}

	&__item-actions {
		width: 60px;
	}
}

.dataset-download-btn {
	@extend .btn;
	@extend .icon-btn;
	@extend .icon-btn__no-label;
	display: inline-block;
}

.time-machine-modal {
	max-height: 90vh;

	h4 {
		margin-top: 2em;

		&:first-child {
			margin-top: 1em;
		}
	}

	&__date-picker {
		margin-top: 1em;
	}

	&__clear-link {
		padding-left: 0.5rem;
	}

	&__info-icon {
		display: inline-block;
		padding-left: 0.5em;
		position: relative;
		top: 5px;
	}
}
