.schema-editor_list_wrapper {
	width: 100%;
	overflow: auto;
}

.schema-editor_list {
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	min-width: 950px;
	margin: 0;
	padding: 1em 0;
}

.schema-editor_row {
	display: flex;
	height: 100%;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid $standardgrey;
	padding-top: 10px;
	padding-bottom: 10px;
	align-items: center;

	.required-field {
		color: $grey;
		font-size: 12px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.schema-btn_wrap {
		display: flex;
		justify-content: center;
		padding-top: 4px;
	}

	.schema-editor_item {
		display: flex;
		flex-direction: column;
		padding: 0 3px;
		max-width: 180px;
		justify-content: space-between;
		height: 100%;
		position: relative;

		.schema-editor_item-title {
			font-size: smaller;
			font-weight: bold;
			color: $grey;
		}

		.modal-select {
			border: 0;
			padding-left: 0;
			height: 27px;
		}
	}

	.text-area.error {
		border-bottom: 1px dashed $red;
	}

	.text-area.small {
		font-size: inherit !important;
	}

	.text-area {
		outline: none;
		padding-top: 7px;
		min-width: auto;
		width: 168px;
		border-bottom: 1px dashed $lightgrey;
		.content-editable-can-edit__input {
			width: 100%;
			max-width: 148px;
			word-wrap: break-word;
		}
	}

	.styled-checkbox-wrapper {
		height: 27px;
		padding-top: 3px;
	}

	.remove-minus-btn {
		cursor: pointer;
	}
}
.schema-editor_drag-handle {
	cursor: pointer;
}

.warning-box {
	border: 2px solid $warning;
	background: $light-beige;
	padding: 1rem;
}

.link {
	background: none;
	border: none;
	color: $kiplot-blue;
	text-decoration: underline;
	cursor: pointer;
}

.derivedDatasetList {
	margin: 1rem;
}

@import "components/style";
