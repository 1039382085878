$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-a-z: "\e900";
$icon-add: "\e901";
$icon-align-centre: "\e902";
$icon-align-left: "\e903";
$icon-align-right: "\e904";
$icon-alignment-dropdown: "\e905";
$icon-area-chart: "\e906";
$icon-arrow-down-thin: "\e907";
$icon-arrow-left-thin: "\e908";
$icon-arrow-right-thin: "\e909";
$icon-arrow-up-thin: "\e90a";
$icon-back-arrow: "\e90b";
$icon-bar-chart-with-axis: "\e90c";
$icon-bar-chart-with-line: "\e90d";
$icon-bar-chart: "\e90e";
$icon-bin-2: "\e90f";
$icon-bin-3: "\e910";
$icon-bin: "\e911";
$icon-black-list: "\e912";
$icon-bold: "\e913";
$icon-bookmark-selected: "\e914";
$icon-bookmark-unselected: "\e915";
$icon-box-plot: "\e916";
$icon-broken-link: "\e917";
$icon-bulleted-list: "\e918";
$icon-burger-menu: "\e919";
$icon-category: "\e91a";
$icon-clock: "\e91b";
$icon-cog: "\e91c";
$icon-column-chart: "\e91d";
$icon-comma-separated-value: "\e91e";
$icon-comparison: "\e91f";
$icon-content-blocks: "\e920";
$icon-currency: "\e921";
$icon-dataset: "\e922";
$icon-date: "\e923";
$icon-datetime: "\e924";
$icon-double-arrow-horizontal: "\e925";
$icon-doughnut-chart: "\e926";
$icon-down: "\e927";
$icon-download: "\e928";
$icon-drop-down-thin: "\e929";
$icon-duplicate: "\e92a";
$icon-stream-graph: "\e92b";
$icon-edit-page: "\e92c";
$icon-ellipsis-vertical: "\e92d";
$icon-ellipsis: "\e92e";
$icon-enter-fullscreen: "\e92f";
$icon-equals: "\e930";
$icon-exit-fullscreen: "\e931";
$icon-exit-preview: "\e932";
$icon-exit: "\e933";
$icon-favourite-unselected: "\e934";
$icon-favourite: "\e935";
$icon-file: "\e936";
$icon-filter: "\e937";
$icon-force-directed: "\e938";
$icon-gantt-chart: "\e939";
$icon-gantt-with-milestones: "\e93a";
$icon-grid-plot: "\e93b";
$icon-group: "\e93c";
$icon-heatmap: "\e93d";
$icon-highlight: "\e93e";
$icon-hollow-cog: "\e93f";
$icon-import: "\e940";
$icon-italic: "\e941";
$icon-key: "\e942";
$icon-left-arrow-thin: "\e943";
$icon-left: "\e944";
$icon-line-chart: "\e945";
$icon-link: "\e946";
$icon-location: "\e947";
$icon-markdown: "\e948";
$icon-matrix: "\e949";
$icon-milestones: "\e94a";
$icon-moon: "\e94b";
$icon-move: "\e94c";
$icon-new-tile: "\e94d";
$icon-number: "\e94e";
$icon-numbered-list: "\e94f";
$icon-page: "\e950";
$icon-padlock: "\e951";
$icon-paint-palette: "\e952";
$icon-pencil: "\e953";
$icon-people: "\e954";
$icon-percentage: "\e955";
$icon-performance-indicator: "\e956";
$icon-pin-selected: "\e957";
$icon-pin-unselected: "\e958";
$icon-pivot: "\e959";
$icon-preview: "\e95a";
$icon-question-mark: "\e95b";
$icon-refresh: "\e95c";
$icon-report: "\e95d";
$icon-reset-password: "\e95e";
$icon-reset: "\e95f";
$icon-right-arrow-thin: "\e960";
$icon-right: "\e961";
$icon-sankey: "\e962";
$icon-save: "\e963";
$icon-scatter-plot: "\e964";
$icon-search: "\e965";
$icon-share: "\e966";
$icon-SQL: "\e967";
$icon-sun: "\e968";
$icon-table: "\e969";
$icon-text-colour: "\e96a";
$icon-text: "\e96b";
$icon-tick-in-circle: "\e96c";
$icon-tick: "\e96d";
$icon-tiles-filled: "\e96e";
$icon-tiles-outlines: "\e96f";
$icon-tree: "\e970";
$icon-tree1: "\e971";
$icon-treemap: "\e972";
$icon-turn-back-time: "\e973";
$icon-UK: "\e974";
$icon-underline: "\e975";
$icon-undo: "\e976";
$icon-up-arrow-thin: "\e977";
$icon-up-down: "\e978";
$icon-up: "\e979";
$icon-upload: "\e97a";
$icon-USA: "\e97b";
$icon-waterfall: "\e97c";
$icon-white-list: "\e97d";
$icon-world: "\e97e";
$icon-error: "\e983";
$icon-chat: "\e97f";
$icon-storage: "\e984";
$icon-functions: "\e980";
$icon-info: "\e981";
$icon-info_outline: "\e982";
$icon-swap_horizontal_circle: "\e985";
$icon-cases: "\e986";
$icon-logout: "\e987";
$icon-double_arrow: "\e988";
$icon-office: "\e989";
$icon-calendar: "\e98a";
$icon-users: "\e98b";
$icon-spinner8: "\e98c";
$icon-arrow-up: "\ea32";
$icon-arrow-down: "\ea36";
$icon-make-group: "\ea58";
