.team-name-link {
	font-size: inherit;
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: none;
		color: inherit;
	}
}
