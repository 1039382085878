.mark-tooltip-data {
	overflow: auto;
	width: 220px;

	h4 {
		margin-top: inherit;
		margin-bottom: inherit;
		font-weight: bold;
		font-size: larger;
		word-wrap: break-word;
		@extend .small-flexible-island__item;
	}
}

.mark-tooltip-item {
	margin-left: inherit;
	width: 60%;
	@extend .u-grey-border-bottom;
	word-wrap: break-word;
	@extend .small-flexible-island__item;
}

.mark-tooltip-title {
	@extend .u-grey-border-bottom;
	@extend .small-flexible-island__item;
	font-weight: bold;
	width: 40%;
	word-wrap: break-word;
}
