.roadmap-initiative-outcome-item {
	flex: auto;
	width: 100%;
	box-sizing: content-box;
	display: flex;
	align-items: stretch;
	justify-content: stretch;
	height: 1.6rem;
	transition: $STRATEGY--button-transition;
	position: relative;
	border-radius: $STRATEGY--border-radius;
	user-select: none;
	z-index: 2;
	margin: calc($smaller-spacing / 2) 0;

	&__left-pill {
		box-sizing: border-box;
		min-width: 16px;
		width: 16px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.2rem 0 0;
		font-size: $STRATEGY--font-smallest-size;
		font-weight: bold;
		border-right: 0.125rem solid $white;
		background-color: $grey-blue-2;
		color: $grey-blue-5;

		&--green {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-green;
		}

		&--amber {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-amber;
		}

		&--red {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-red;
		}

		&--done {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-done;
		}

		&--grey {
			background-color: $grey-blue-2;
			color: $grey-blue-5;
		}

		&--rounded {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		&--torn {
			clip-path: $STRATEGY--left-tear-clipping-path;
		}
	}

	&__content {
		flex: auto;
		display: flex;
		align-items: center;
		justify-content: stretch;
		background-color: $grey-blue-1;
		padding: 0.2rem;
		max-width: calc(100% - 16px - 6px);
	}

	&__content-name {
		flex: auto;
		max-width: 100%;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		font-size: $STRATEGY--font-smallest-size;
		color: $primary-text-color;
		padding: 0.4rem 0;
	}

	&__content-actions {
		flex: initial;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__action-button {
		margin: 0 1px;
		height: $STRATEGY--icon-height;
		width: $STRATEGY--icon-height;

		i {
			font-size: $STRATEGY--font-bigger-size;
		}
	}

	&__right-edge {
		width: 6px;
		height: 1.6rem;
		background-color: $grey-blue-1;

		&--rounded {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		&--torn {
			clip-path: $STRATEGY--right-tear-clipping-path;
		}
	}
}

.roadmap-outcome-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: stretch;
	width: 100%;
	background-color: $white;
	border: 2px solid $grey-blue-2;
	border-radius: 6px;
	margin: 0.15rem 0;
	z-index: 1;

	&--grouped-with-previous {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		margin-top: 0;
		border-top: none;

		.roadmap-outcome-group__header {
			display: none;
		}
	}

	&--grouped-with-next {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: 0;
		border-bottom: none;

		.roadmap-outcome-group__content {
			padding-bottom: 0;
		}
	}

	&__header {
		width: 100%;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}

	&__header-name {
		flex: 1;
		max-width: 100%;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		font-size: $STRATEGY--font-smallest-size;
		color: $primary-text-color;
		padding: 0.3rem 0.4rem 0.1rem 0.4rem;
	}

	&__header-actions {
		flex-basis: calc(#{$STRATEGY--icon-height} * 1);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 0.4rem;
	}

	&__content {
		width: 100%;
		padding: 0 0.25rem 0.25rem 0.25rem;
	}
}
