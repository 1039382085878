@import "./components/style";

.filter-modal {
	width: 500px;
}

.filters {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__empty-state {
		font-size: small;
		color: $grey-4;
		text-align: center;
		align-self: center;
		margin: 1rem 0 2rem 0;
	}

	&__list {
		& > * {
			margin-bottom: 0.5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
