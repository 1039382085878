.markdown-inspector-view {
	// This is for when the <p> needs to go back to its default in a modal
	p {
		display: block;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;
	}
}

.markdown-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.jira-markdown {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: bold;
	}

	blockquote {
		border-left: 2px solid $standardgrey;
		margin-left: 0;
		padding: 0.5em 1em;
	}

	hr {
		border: none;
		height: 2px;
		background: $standardgrey;
		margin: 1em 0;
	}

	.panel {
		border-radius: 3px;
		margin: 0.75em 0 0;
		padding: 8px;
		min-width: 48px;
		word-break: break-word;
	}

	.status {
		display: inline-block;
		padding: 2px 6px;
		font-weight: bold;
		color: $white;
		border-radius: 3px;
	}

	.inline-date,
	.inline-code {
		display: inline-block;
		padding: 2px 6px;
		background: $very-light-grey;
		color: $darkgrey;
		border-radius: 3px;
		white-space: pre;
	}

	.mention-person {
		display: inline-block;
		padding: 2px 6px;
		background: $skyblue;
		color: $fadedblue;
		font-weight: bold;
		border-radius: 3px;
	}

	.unsupported {
		display: inline-block;
		padding: 2px 6px;
		background: $pastelpink;
		color: $red;
		font-weight: bold;
		border-radius: 3px;
	}

	table {
		border-collapse: collapse;

		tr,
		tbody,
		thead {
			border: none;
		}

		th,
		td {
			min-width: 48px;
			font-weight: normal;
			vertical-align: top;
			border: 1px solid $standardgrey;
			border-image: initial;
			padding: 8px;
		}

		th {
			background: $palegrey;
		}
	}

	a {
		color: $skyblue;
	}
}
