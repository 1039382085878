.text-input--wrapper {
	border: 1px solid transparent;
	display: flex;
	flex-direction: column;

	.input {
		margin-top: 2px;
		height: 38px;
		z-index: auto;

		&.inline-error {
			border-color: $red;
		}
	}
}
