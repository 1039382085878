.outcome-dates {
	display: flex;
	width: 100%;

	& > div {
		margin-bottom: 0;
	}

	& > :first-child {
		margin-right: 1rem;
	}
}
