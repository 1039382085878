.change-dataset {
	hr {
		border: none;
		height: 9px;
		margin: 0;

		&.top {
			box-shadow: inset 0px 4px 9px -9px rgba(0, 0, 0, 0.75);
		}

		&.bottom {
			box-shadow: inset 0px -4px 9px -9px rgba(0, 0, 0, 0.75);
		}
	}

	&--tab-container {
		@extend .flexible-island;
		padding-top: 0px;
		display: flex;
		flex-direction: row;

		.dataset-tab {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 35px;
			border: none;
			cursor: pointer;
			margin-right: 10px;
			min-width: 120px;
			max-width: 120px;
			padding-left: 5px;
			transition: 250ms;
			outline: none;
			background: none;
			border-radius: $border-radius;

			.styled-checkbox:not(:checked) + label:before {
				background: none;
			}

			.styled-checkbox + label::before {
				border: 1px solid $primary-text-color;
				border-radius: $border-radius;
			}

			&:last-child {
				margin-right: 0em;
			}

			&:hover {
				background-color: $lightblue;
			}
		}
	}
}

.upload-dataset__wrapper {
	background-color: $white;
	height: 100%;
	width: 100%;
	box-shadow: none;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.datasets-table__wrapper {
	max-height: 400px;
	min-height: 400px;
	overflow-y: auto;
	overflow-x: auto;
	padding-top: 0;
	padding-bottom: 0;
}

.datasets-table__item {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	cursor: pointer;
	border-radius: $border-radius;
	width: 100%;

	&-row {
		@extend .flexible-island;
		border-bottom: 1px solid $lightgrey;
		display: flex;
		flex-grow: 1;
		font-size: smaller;
	}

	&:hover {
		background: $lightgrey;
	}

	.datasets-table-name {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 270px;
	}

	.datasets-table-date {
		width: 100px;
		max-width: 100px;
	}

	&.active {
		background-color: $kiplot-blue;
		color: $white;
		font-weight: bold;
	}
}

.datasets-table__item--no-items {
	text-align: center;
}

.datasets-table__cell {
	width: 115px;
	max-width: 115px;

	&.selected {
		color: $kiplot-blue;
	}
}
