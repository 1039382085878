.totals-row {
	margin-top: 10px;

	&__header {
		@extend .font-bold;
		width: $row-header-width;
		justify-content: center;
		align-items: flex-end;
		padding-right: 10px;
		background-color: $very-light-grey;
		border-radius: $border-radius 0 0 $border-radius;
	}

	&__amounts {
		width: calc(100% - #{$row-header-width} - 15px);
		overflow: hidden;
		background-color: #f7f7f7;
		font-weight: bold;

		&--sticky {
			position: sticky;
			top: 0;
			left: 0;
			border-right: 1px solid $standardgrey;
		}

		.t-vertical {
			justify-content: center;
			align-items: center;
			min-width: $column-width;
		}
	}
}
