.ie11-loader {
	$spinner-size: 2rem;

	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	&--html-table {
		min-height: calc(#{$spinner-size} * 4);
	}

	&__spinner {
		width: $spinner-size;
		height: $spinner-size;
		display: block;
		color: $grey-2;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			display: inline-block;
			border: 5px solid currentColor;
			border-bottom-color: transparent;
			border-radius: 100%;
			background: transparent;

			animation: ball-clip-rotate 0.75s linear infinite;
		}
	}
}

@keyframes ball-clip-rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
