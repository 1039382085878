@import "DetailsModal/style";
@import "Plan/style";

.key-result-title {
	&__sub-title {
		margin: 0.3rem 0 1.5rem 0;
	}

	&__objective {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__objective-name {
		margin-left: 0.6rem;
		color: $blue;
		font-weight: bold;
		max-width: 700px;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
	}
}
