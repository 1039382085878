.validation-error {
	color: $red;
	font-size: small;
}

.validation-warning {
	color: $amber;
	font-size: small;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.secondary-text {
	color: $grey-5;
}

.page-description {
	font-size: small;
	line-height: 1rem;
}
