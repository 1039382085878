@import "components/style";

.app-nav {
	width: $nav-width-expanded;
	height: 100vh;
	pointer-events: all;
	display: flex;
	flex-direction: column;
	background-color: $darkblue;
	color: $white;
	padding-top: 20px;
	border-radius: 0 10px 10px 0;
	letter-spacing: 1px;
	z-index: 8;
	position: fixed;
	top: 0;
	left: 0;

	transition: width 150ms ease-in-out;

	&--hovered {
		width: $nav-width-expanded;
	}

	&--collapsed {
		width: $nav-width-collapsed;
	}

	a {
		text-decoration: none;
	}

	&__footer {
		margin-top: auto;
	}
}
