.project-detail-page {
	@import "components/style";
	@import "ProjectCosts/style";
	@import "ProjectBenefits/style";
	@import "ProjectResources/style";
	@import "ProjectInfo/style";
	@import "ProjectKpis/style";

	max-width: 1675px;

	.content-margin {
		@media (min-width: 1000px) {
			margin: auto;
			padding-left: 5%;
			padding-right: 5%;
		}

		@media (min-width: 1200px) {
			padding-left: 1%;
			padding-right: 1%;
		}
	}

	.page-contents {
		height: 100%;
	}

	.rounded-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1600px;
	}
}

// styles used inside modal
.add-row-select-input {
	margin-bottom: 17px;

	.add-row-resource {
		display: inline-flex;
		width: 100%;

		.multi-level-dropdown {
			flex-grow: 1;

			.input-search-wrapper {
				border-radius: 0 $border-radius $border-radius 0;
				border-left: none;
			}
			&:first-child .input-search-wrapper {
				border-radius: $border-radius 0 0 $border-radius;
				border-left: 1px solid $lightgrey;
			}
		}
	}
}

.modal-warning {
	font-size: smaller;
	font-weight: bold;
	color: $red;
	margin-top: 10px;
}

.range-filler {
	border-bottom: 2px solid $palegrey;
	border-top: 2px solid $palegrey;
	padding: 10px 0;
	margin-bottom: 25px;
	cursor: pointer;
}
