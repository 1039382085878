$mobile-break-point: "(min-width: 1023px)";

$navigation-collapse-break-point: "(min-width: 768px)";

@mixin hide {
	display: none;
	visibility: hidden;
}

@mixin show {
	display: flex;
	visibility: visible;
}
