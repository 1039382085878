@import "../../lib/style/variables";
.toasts {
	@extend .u-reset-list;
	@extend .island;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 25px;
	right: 0;
	color: $white;
	z-index: $toaster;

	.error {
		background-color: $red;
		fill: $white;
	}

	.success {
		background-color: $success;
		fill: $white;
	}

	.info {
		background-color: $kiplot-blue;
		fill: $white;
	}

	.warning {
		background-color: $warning;
		fill: $primary-text-color;
		color: $primary-text-color;
	}

	.toast-icon {
		margin-right: 5px;
		svg {
			width: 24px;
			height: 24px;
		}
	}
	.icon-btn {
		padding: 1px;
		border-radius: 3px;
	}
}

.toasts--empty {
	visibility: hidden;
}

.toast {
	@extend .box-shadow;
	@extend .small-island;
	border-radius: 3px;
	max-width: 300px;
	width: 100%;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.toast-inner {
	display: flex;
	align-items: flex-start;
}
