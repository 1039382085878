@keyframes moving-gradient {
	0% {
		background-position: -250px 0;
	}
	100% {
		background-position: 250px 0;
	}
}

.loading-table {
	width: 100%;
	animation: fade-in 400ms ease-in;
	transition: 500ms;
	border: 2px solid transparent;

	.table-row {
		width: 100%;
		height: 21px;
		margin: 16px 0;
		border-bottom: 1px solid $lightgrey;

		&-isLoading {
			background: linear-gradient(to right, $very-light-grey 20%, $lightgrey 50%, $very-light-grey 80%);
			background-size: 500px 10px;
			animation-name: moving-gradient;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-fill-mode: forwards;
		}
	}
}
