@import "VersionList/style";

.initiative-history-modal {
	width: 70%;
	max-width: 900px;

	@media #{$hd-screen} {
		width: 90%;
	}
}
