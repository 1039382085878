$initiative-list-item-row-padding: 0.5em;

.initiative-status {
	@extend .text-ellipsis;
	font-size: small;
	padding: 3px 15px;
	border-radius: 12px;
	color: $white;
	margin: 0 2px 0 2px;
}

.initiative-stage {
	@extend .text-ellipsis;
	font-size: small;
	padding: 3px 15px;
	background-color: $light-beige;
	border-radius: 12px;
	margin: 0 2px 0 2px;
}

.initiative-title {
	@extend .font-bold;
	@extend .text-ellipsis;
	font-size: 18px;
}

.initiative-meta-data {
	font-size: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	overflow: hidden;
	flex-wrap: wrap;

	&__item {
		padding-right: $initiative-list-item-row-padding;

		&--title {
			@extend .font-bold;
			@extend .text-ellipsis;
		}

		&--detail {
			@extend .text-ellipsis;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}
}

.initiative-meta-data .initiative-meta-data__item:first-child,
.initiative-meta-data .initiative-meta-data__item:nth-child(2) {
	margin-right: $initiative-list-item-row-padding;
	border-right: 1px solid $primary-text-color;
}

.initiative-menu {
	padding-right: 10px;
}

.initiative-link {
	padding: 10px 0;
	border-radius: $border-radius;
	background-color: $very-light-grey;
	transition: 250ms;
	margin: 0 20px 10px 15px;

	.link-box__contents,
	.link-box {
		height: 100%;
	}

	.link-box__contents {
		display: flex;
		align-items: center;
	}

	&:hover {
		background-color: $standardgrey;
		transition: 250ms;
	}
}

.initiative-list-item-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&__info {
		display: flex;
		flex-direction: column;
		width: 90%;
		justify-content: space-between;
		padding-left: $initiative-list-item-row-padding;
	}
}
