.report-unsaved-changes {
	min-height: 200px;
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 600px;

	&__list {
		min-height: 200px;
	}

	&__progress {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		&--text {
			padding-bottom: 5px;
		}

		.progress-indicator__modal {
			position: static;
			top: auto;
			left: auto;
			right: auto;
		}
	}
}
