.dataset-info__metadata {
	display: flex;
	flex-direction: column;
	color: $white;
	background-color: $kiplot-blue;
	padding-bottom: 1rem;

	.sub-title {
		font-size: smaller;
		margin-bottom: 1em;
	}

	.title {
		margin: 0;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.owners p {
		margin-top: 0.3rem;
		font-size: 0.9rem;
	}
}
