$STRATEGY--border-radius: 0.32rem;
$STRATEGY--button-transition: all 200ms ease-in-out;
/* this is so that buttons can be placed next to input fields including react-select and still
 have a consistent the same height */
$STRATEGY--field-height: 2.2rem;
$STRATEGY--field-border-radius: 3px;
$STRATEGY--field-border-width: 2px;
$STRATEGY--field-border-focused-color: $highlight-color;
$STRATEGY--field-horizontal-padding: 0.4rem;
$STRATEGY--icon-height: 1.5rem;
$STRATEGY--small-icon-height: 1rem;

// TYPOGRAPHY
$STRATEGY--header-1-size: 2rem;
$STRATEGY--header-2-size: 1.5rem;
$STRATEGY--header-3-size: 1.15rem;
$STRATEGY--header-4-size: 1rem;
$STRATEGY--header-5-size: 0.9rem;
$STRATEGY--font-normal-size: 0.9rem;
$STRATEGY--font-smaller-size: 0.8rem;
$STRATEGY--font-small-size: 0.72rem;
$STRATEGY--font-smallest-size: 0.68rem;
$STRATEGY--font-bigger-size: 1rem;

// Z-INDEX
$STRATEGY--table-headers: 7;
$STRATEGY--overlay-content-layer: 9;
$STRATEGY--modal-layer: 10;
$STRATEGY--context-layer: 11;
$STRATEGY--dropdown-menu-layer: 12;

// BOX-SHADOW DISTANCES
$STRATEGY--shadow-distance-1: 0 1px 3px rgba(0, 0, 0, 0.15);
$STRATEGY--shadow-distance-2: 0 6px 10px rgba(0, 0, 0, 0.15);
$STRATEGY--shadow-distance-3: 1px 9px 25px rgba(0, 0, 0, 0.25);

$STRATEGY--shadow-highlight-color: $highlight-color;
$STRATEGY--shadow-highlight-distance-1:
	0 0 2px 2px $STRATEGY--shadow-highlight-color,
	0 0 4px 4px $STRATEGY--shadow-highlight-color inset;

$STRATEGY--color-period-1: #1b26ed;
$STRATEGY--color-period-2: #565ff1;
$STRATEGY--color-period-3: #7178f0;
$STRATEGY--color-period-4: #aeb1f4;
$STRATEGY--color-period-5: #e6e8ff;

// LAYOUT
$STRATEGY--max-page-width: 1440px;

// CONTROL-CENTER
// EFFORT RISK COLORS
$STRATEGY--cc-effort-risk-color-red: #ff5252;
$STRATEGY--cc-effort-risk-color-yellow: #ffc107;
$STRATEGY--cc-effort-risk-color-amber: #ffc107;
$STRATEGY--cc-effort-risk-color-green: #4caf50;
$STRATEGY--cc-effort-risk-color-done: $blue;

$STRATEGY--left-tear-clipping-path: path(
	"M4,0 s -4,2 -4,4 4,3 4,5 -4,3 -4,5 4,3 4,5 -4,3 -4,5 4,3 4,5 -4,3 -4,5 4,3 4,5 -4,2 -4,4 h 100 v -43 Z"
);
$STRATEGY--right-tear-clipping-path: path(
	"M6,0 s -4,2 -4,4 4,3 4,5 -4,3 -4,5 4,3 4,5 -4,3 -4,5 4,3 4,5 -4,3 -4,5 4,3 4,5 -4,2 -4,4 h -6 v -43 Z"
);
