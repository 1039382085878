// -----------------------------------+
//             TYPOGRAPHY
// -----------------------------------+
$font-size: 1rem;
$line-height: 1.2;
$icon-font-size: 24px;
$bold-font: 600;

// -----------------------------------+
//             COLOR
// -----------------------------------+
// Brand colors
$kiplot-blue: #1b26ed;
$kiplot-red: #ff5252;

// Grey scale
$grey-1: #f0f0f0;
$grey-2: #d9d9d9;
$grey-3: #a5a5a5;
$grey-4: #979797;
$grey-5: #7b7b7b;
$grey-6: #4c4b4b;

// Grey-Blue scale (Corresponds to Blue Gray palette from Material Design)
$grey-blue-1: #eaecf0; // 50
$grey-blue-2: #cfd8dc; // 100
$grey-blue-3: #b0bec5; // 200
$grey-blue-4: #90a4a6; // 300
$grey-blue-5: #607d82; // 500
$grey-blue-6: #455a60; // 700

// Base colors
$white: #ffffff;
$black: #000000;

$pale-blue: #dfebf2;
$dark-pale-blue: #b6cad4;
$light-blue: #3f94ff;
$blue: #3d3cb4;
$darkblue: #161580;
$amber: #ff9800;
$green: #4caf50;
$light-green: #a6eaa2;
$yellow: #ffc107;
$red: #ff5252;
$pink: #ff00f3;
$cyan: #32bdf3;

$primary-text-color: #4c4b4b;
$highlight-color: $light-blue;
$small-spacing: 0.5rem;
$smaller-spacing: 0.3rem;
$smallest-spacing: 0.2rem;
$normal-spacing: 0.8rem;
$big-spacing: 1rem;

// -----------------------------------+
//             LAYOUT
// -----------------------------------+
$small-screen-size: 600px;
$medium-screen-size: 768px;
$mobile-screen-size: 1024px;
$hd-screen-size: 1280px;
$desktop-screen-size: 1600px;
$full-hd-screen-size: 1980px;
$quad-hd-screen-size: 2560px;

// Screen Dimensions
$small-screen: "(max-width: #{$small-screen-size})";
$medium-screen: "(max-width: #{$medium-screen-size})";
$mobile-screen: "(max-width: #{$mobile-screen-size})";
$hd-screen: "(max-width: #{$hd-screen-size})";
$desktop-screen: "(max-width: #{$desktop-screen-size})";
$full-hd-screen: "(max-width: #{$full-hd-screen-size})";
$quad-hd-screen: "(max-width: #{$quad-hd-screen-size})";
$ultra-hd-screen: "(min-width: calc(#{$quad-hd-screen-size} + 1px))";
