.custom-icon {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

// Internet Explorer 10 & 11 fix to make
// icons layout correctly in iconBtn
@media all and (-ms-high-contrast: none) {
	i[class^="custom-icon icon-"],
	i[class*="custom-icon icon-"] {
		font-size: 20px;
	} /* IE10 */
	*::-ms-backdrop,
	i[class^="custom-icon icon-"],
	i[class*="custom-icon icon-"] {
		font-size: 20px;
	} /* IE11 */
}
