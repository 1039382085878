.business-unit-config {
	margin-top: 1rem;

	&:first-child {
		margin-top: 0;
	}

	&__sub-business-units-list {
		padding: 0 !important;
	}
}

.sub-business-units-table {
	&__name-column {
		padding-left: 1rem !important;
	}
}
