%backgound-styles {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.logo {
	height: 45px;
	width: 130px;
	position: fixed;
	top: 20px;
	left: 20px;
	background-repeat: no-repeat;
	background-image: url("src/reporting/App/SignIn/assets/colour.svg");
	@media #{$small-screen} {
		z-index: 2;
		background-image: url("src/reporting/App/SignIn/assets/white.svg");
	}
}

@mixin mobile-background($image) {
	background-image: url($image);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top;
	background-position-y: -60px;
	position: fixed;
	display: block;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-height: 300px;
	height: 100%;
}

.sign-in-page {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
	// IE11 fix
	min-height: 100vh;

	@media #{$small-screen} {
		justify-content: center;
	}

	.sign-in-mobile {
		display: none;
		@media #{$small-screen} {
			&.login-background {
				@include mobile-background("src/reporting/App/SignIn/assets/login_mobile_header.png");
			}

			&.reset-background {
				@include mobile-background("src/reporting/App/SignIn/assets/reset_mobile_header.png");
			}
		}
	}

	&.reset-background {
		@extend %backgound-styles;
		background-image: url("src/reporting/App/SignIn/assets/reset.png");
	}

	&.login-background {
		@extend %backgound-styles;
		background-image: url("src/reporting/App/SignIn/assets/login.png");
	}

	&.reset-background,
	&.login-background {
		@media #{$small-screen} {
			background-image: none;
		}
	}

	.sign-in-wrapper {
		width: 90%;
		max-width: 555px;
		position: relative;
		background: transparent;

		@media screen and (min-width: 1200px) {
			right: 15%;
		}

		@media #{$small-screen} {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		.sign-in-content {
			width: 90%;
			margin: 2em;
			background-color: $white;
			@extend .modal-content;
			min-width: auto;

			@media #{$small-screen} {
				width: 100%;
				margin: 2em 0em;
				background-color: transparent;
				min-height: auto;
				height: auto;
				box-shadow: none;
				padding-top: 120px;
				overflow: auto;
			}
		}

		.password-reset-success {
			padding: 2em 1em;
		}
	}

	.oauth {
		width: 100%;
		text-align: center;
		padding: 1em 0;
		.btn--oauth {
			@extend .box-shadow;
			margin: 1em 0;
			background-color: $fadedpink;
			padding: 0.75em;
			border-radius: 50px;
			font-weight: 700;
			&:hover,
			&:focus {
				background-color: $lightgrey;
			}
		}
		.icon {
			background-repeat: no-repeat;
			background-position: 15px center;
			background-size: 24px;
		}
		.icon--google {
			background-image: url("src/reporting/App/SignIn/assets/google-icon.svg");
		}
		.icon--auth0 {
			background-image: url("src/reporting/App/SignIn/assets/auth0-icon.svg");
		}
	}

	.email-and-password {
		position: relative;
		hr {
			border: 0.5px solid $lightgrey;
			margin-bottom: 0;
		}
		.form-toggle-button {
			width: 100%;
			color: grey;
			text-align: center;
			margin-bottom: 0.5em;
			padding: 0.5em;
			border: none;
			cursor: pointer;
		}
		i {
			color: grey;
			position: absolute;
			right: 0;
			top: 18px;
			pointer-events: none;
		}
	}

	.reset-password-wrapper {
		display: flex;
		bottom: 20px;
		width: 100%;
		font-size: 0.85em;
		margin-bottom: 3em;
		.btn--reset-password {
			transition: 0.5s;

			&:hover {
				color: $white;
				transition: 0.5s;
				background: $darkgrey;
			}
		}

		.link {
			border: none;
			cursor: pointer;
			position: relative;
			text-decoration: none;
			color: $darkgrey;
		}
		.link:after {
			content: "";
			position: absolute;
			height: 1px;
			margin: 0 auto;
			width: 100%;
			right: 0;
			left: 0;
			color: $darkgrey;
			background-color: $darkgrey;
			bottom: -3px; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
		}
	}
}

.sign-in-label {
	@extend .font-bold;
	padding-bottom: 5px;
}

.sign-in-input__wrapper {
	@extend .flex, .flex-column;
	width: 100%;
	padding-bottom: 1em;

	.input {
		background: $white;
	}
}

.sign-in-form {
	@extend .flex, .flex-column;
	width: 100%;
	min-height: 290px;
	flex-grow: 1;
	justify-content: space-between;

	@media #{$small-screen} {
		justify-content: center;
		height: 100%;
	}
}

.sign-in-input__link {
	color: $primary-text-color;
	float: right;
	border: none;
	border-bottom: 1px solid $primary-text-color;
	cursor: pointer;
}

.sign-in-fields {
	padding: 0.5em 0;
}
