.info-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: $border-radius;
	margin: 0 1rem 1rem 1rem;
	background-color: $palegrey;

	&__title {
		@extend .flexible-island;
		@extend .font-bold;
		width: 100%;
		margin: 0;
	}

	&__content {
		@extend .flexible-island;
		padding-top: 0;
		width: 100%;
		height: 100%;
	}
}

.field {
	@extend .flexible-island;
	display: flex;
	flex-direction: column;
	margin: 8px;
	background-color: $white;
	width: calc(50% - 16px);
	min-width: 200px;
	border-radius: $border-radius;
	align-self: stretch;

	&__label {
		@extend .font-bold;
		font-size: small;
		color: $slate-grey;
	}

	&__value {
		margin-top: 10px;
		&--not-set {
			color: $grey;
		}
	}

	@media (min-width: 1024px) {
	}
}

.field-dates {
	.field {
		margin-left: 0;
		margin-bottom: 8px;
		width: 100%;

		&__label {
			text-align: left;
		}
	}

	table {
		border-radius: $border-radius;
		background-color: $white;
	}

	th {
		padding: 10px 5px 10px 10px;
		.sorting-icon {
			display: inline-block;
			font-size: 16px;
			margin-left: 1em;
		}
	}

	td {
		padding: 10px;
	}

	thead {
		border-bottom: 1px solid $palegrey;
		@extend .font-bold;
		font-size: small;
		color: $slate-grey;

		& th:first-child {
			width: 40%;
		}
	}

	tr {
		border: none;
		border-radius: $border-radius;
	}
}
