.initiative-list-page {
	@import "components/InitiativeList/style";
	@import "components/InitiativeListTabs/style";

	.content-margin {
		@media (min-width: 1000px) {
			margin: auto;
			padding-left: 5%;
			padding-right: 5%;
		}
	}

	.page-contents {
		height: 100%;
	}

	.initiative-list-tabs-wrapper {
		@extend .u-reset-list;
		padding: 0.9rem 0.99rem;
		display: flex;
		justify-content: space-between;
	}
}
