.color-select-with-preview {
	width: 55px;
	height: 28px;
	display: inline-flex;

	&--preview {
		width: 50%;
		height: 100%;
		border: 2px solid $blue;
		border-radius: 5px 0 0 5px;
		border-right: none;
	}
	&--select {
		@extend .btn;
		color: #fff;
		padding: 0;
		width: 50%;
		height: 100%;
		border-radius: 0 5px 5px 0;
		background-color: $blue;
		display: flex;
		justify-content: center;
		align-items: center;

		i {
			font-size: 19px;
		}
	}
}
