@import "src/core/styles/variables";
@import "../lib/style/variables";
// these are css-only components, often extended by others

// annotate things on the application layer that need
// to be interactive (the rest isn't to avoid blocking
// interaction with the stage)
.interactive {
	pointer-events: all;
}

.u-reset-list {
	/* Unset UAS */
	padding-left: inherit;
	/* Unset UAS */
	margin-top: inherit;
	/* Unset UAS */
	margin-bottom: inherit;
	/* Unset UAS */
	list-style: none;
}

.u-reset-link {
	// Unset UAS style for anchor
	text-decoration: inherit;
	color: inherit;
}

.u-reset-p {
	margin-top: inherit;
	margin-bottom: inherit;
}

.u-reset-h {
	margin-top: inherit;
	margin-bottom: inherit;
}

.u-reset-btn {
	// TODO: Should this be here? Nope!
	@extend .interactive;
	/* Unset UAS */
	background: none;
	/* Unset UAS */
	padding: inherit;
	/* Unset UAS */
	border: none;
	// Unset UAS
	color: inherit;
}

.u-reset-textarea {
	border: none;
	padding: inherit;
	background: none;
	color: inherit;
	z-index: $general-mid-layer;
}

.u-reset-input {
	border: none;
	padding: inherit;
	background: none;
	z-index: $general-mid-layer;
}

.faded {
	opacity: 0.6;
}

// Islands are just containers with our standard units as padding
.small-island {
	padding: $small-vertical-unit $small-horizontal-unit;
}

.island {
	padding: $vertical-unit $horizontal-unit;
}

.small-horizontal-island {
	padding-left: $small-vertical-unit;
	padding-right: $small-vertical-unit;
}

.horizontal-island-margin {
	margin-left: $horizontal-unit;
	margin-right: $horizontal-unit;
}

.small-horizontal-island-margin {
	margin-left: $small-horizontal-unit;
	margin-right: $small-horizontal-unit;
}

.small-vertical-island-margin {
	margin-top: $small-vertical-unit;
	margin-bottom: $small-vertical-unit;
}

.horizontal-list {
	display: flex;
}

.horizontal-list--wrap {
	flex-wrap: wrap;
}

.horizontal-list--center {
	align-items: center;
}

.horizontal-list--space-between {
	justify-content: space-between;
}

.horizontal-list--space-evenly {
	// this is an IE11 / Edge fallback as `space-evenly` is not supported
	justify-content: space-around;
	justify-content: space-evenly;
}

.horizontal-list--end {
	justify-content: flex-end;
}

// TODO: Rename to flex-{row,column}
.vertical-list {
	display: flex;
	flex-direction: column;
}

// Suffixed with tsu to avoid name conflict with external UI component styles
.flex-grow-tsu {
	flex-grow: 1;
}

.flex-shrink-none {
	flex-shrink: 0;
}

.push-right {
	margin-left: auto;
}

.input,
.textarea {
	border: 1px solid $lightgrey;
	@extend .small-island;
	border-radius: $border-radius;
	&:hover,
	&:focus {
		background-color: $fadedpink;
	}
	&:disabled {
		background-color: $white;
	}
}

.input {
	@extend .u-reset-input;
}

.textarea {
	@extend .u-reset-textarea;
}

.u-block {
	display: block;
}

.half-small-island {
	padding: calc(#{$small-vertical-unit} / 2) calc(#{$small-horizontal-unit} / 2);
}

// These island variants help with managing whitespace between child elements
// As described in https://oliverjash.me/2016/12/28/gutters-for-flexible-components-with-sliced-padding
.small-flexible-island {
	padding: calc((#{$small-vertical-unit} / 4) * 3) calc((#{$small-horizontal-unit} / 4) * 3);
}

.small-flexible-island__item {
	padding: calc((#{$small-vertical-unit} / 4) * 1) calc((#{$small-horizontal-unit} / 4) * 1);
}

.flexible-island {
	padding: calc((#{$vertical-unit} / 4) * 3) calc((#{$horizontal-unit} / 4) * 3);
}

.flexible-island__item {
	padding: calc((#{$vertical-unit} / 4) * 1) calc((#{$horizontal-unit} / 4) * 1);
}

.box-shadow {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.scrollable {
	// Scroll when necessary
	overflow: auto;
}

.left-guttering {
	padding-left: calc((#{$small-vertical-unit} / 4) * 3);
}
