@import "components/style";
@import "components/ReportInfoSidePanel/style";
@import "components/ReportRowMetaData/style";

.page-list {
	@extend .horizontal-island-margin;
	@extend .u-reset-list;
	border-top: 0.5px solid $lightgrey;
	margin-top: 1em;
}

.page-list-item {
	border-bottom: 0.5px solid $lightgrey;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.6em 0;
}

.page-list-info {
	overflow: hidden;
}

.page-list-title {
	display: flex;
	flex-direction: row;
	align-items: center;

	@media #{$medium-screen} {
		font-size: 18px;
	}
}

.page-list-name {
	font-weight: 700;
	margin: 0;
}

.page-list-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 75px; // For IE flex layout
	// Wrap content around the button
	// In the future we can use CSS exclusions for this, but they are not
	// well supported yet.

	// IE fix:
	> * {
		max-width: 100%;
		box-sizing: border-box;
	}
}

.page-list-meta {
	font-size: smaller;
	color: $grey;
	height: 15px;
	display: none;

	@media #{$medium-screen} {
		margin-bottom: 15px;
	}

	@media screen and (min-width: 550px) {
		display: flex;
		overflow: hidden;
	}
}

/**
 * Report-thumbnail
 */
.reports-no-items {
	text-align: center;
	font-size: 1.2em;
}

.sort-buttons {
	position: absolute;
	right: 0;
	padding-right: 1em;
	display: flex;
	font-weight: bold;
}

.report-list__wrapper {
	position: relative;
}
@import "components/CreateReportButton/style";
