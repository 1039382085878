.row-headers {
	min-width: $row-header-width;
	max-width: $row-header-width;
	height: calc(#{$table-height} - 17px);
	overflow: hidden;

	&__grouped {
		display: flex;
		flex-direction: column;
		margin-bottom: 4px;
		align-items: flex-start;
		padding-left: 10px;
		background-color: $very-light-grey;
		border-bottom: 1px solid $very-light-grey;
		border-radius: $border-radius 0 0 $border-radius;

		.header-detail {
			display: inline-flex;
			align-items: center;
			height: 40px;
			width: 100%;
			border-bottom: 1px solid $fadedpink;
			border-top: 1px solid $fadedpink;
			cursor: pointer;
			&:first-child {
				border-top: 1px solid $very-light-grey;
			}
			&:last-child {
				border-bottom: 1px solid $very-light-grey;
			}
			&:hover .path .custom-icon {
				transition: color 350ms ease-in-out;
				color: $blue;
			}

			&__controls {
				display: inline-flex;
				align-items: center;
				justify-content: flex-end;
				width: 20%;
			}

			&__text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&--item-level {
				cursor: default;
				.header-detail__text {
					font-weight: bold;
				}
			}
		}
	}
}

.path {
	display: inline-flex;
	margin-right: 5px;

	.custom-icon {
		color: $grey;
		font-size: 15px !important;
	}

	.icon-down {
		font-size: 18px !important;
	}
}

.row-action-controls,
.category-action-controls {
	button {
		padding: 4px;
	}

	i {
		font-size: 15px;
	}
}

.row-note-tooltip {
	flex-grow: 1;
	i {
		font-size: 15px;
	}
}
