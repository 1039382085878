.dataset-info__header {
	color: $white;
	background-color: $kiplot-blue;
	margin-bottom: -1px; // fix 1px gap on touch devices

	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media #{$medium-screen} {
			padding-top: 0;
		}

		.close-button {
			color: $white;
			display: flex;
			margin-top: -4px;

			&:hover,
			&:focus {
				background: $deep-blue;
			}

			.close-icon {
				color: $white;
				font-size: 30px;
			}
		}
	}
}
