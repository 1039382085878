// Animating stroke-dasharray gets blocked by JavaScript hence 2 spinners chasing eachother

.spinner-wrapper {
	height: 25px;
	width: 25px;
	position: relative;

	.spinner {
		position: absolute;
		animation: aggressive-rotator 600ms linear infinite;
		stroke-dasharray: 66;
		stroke-dashoffset: 8;
		height: 25px;

		circle {
			transform-origin: center;
			stroke-width: 6;
			fill: none;
			stroke-linecap: round;
			stroke: $blue;
		}
	}

	.spinner-chaser {
		position: absolute;
		height: 25px;
		stroke-dasharray: 80;
		stroke-dashoffset: 100;
		animation: aggressive-rotator 500ms linear infinite;

		circle {
			opacity: 0.5;
			transform-origin: center;
			stroke-width: 6;
			fill: none;
			stroke-linecap: round;
			stroke: $blue;
		}
	}
}

// this gives the spinner a bit more punch
@keyframes aggressive-rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
