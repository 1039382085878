.drag-handle-tooltip {
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $white;
		opacity: 0.5;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
