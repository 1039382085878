@import "./ExternalLinksField/style.scss";
@import "./EditableFieldWrapper/style.scss";
@import "./MarkdownField/style.scss";

.editable-field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	max-width: 100%;
	font-size: $STRATEGY--font-normal-size;

	&--with-display {
		height: $STRATEGY--field-height;
		left: calc(-#{$STRATEGY--field-horizontal-padding} - #{$STRATEGY--field-border-width});
	}

	&--full-width {
		width: 100%;
	}

	&--with-longtext-display {
		min-height: $STRATEGY--field-height;
		left: calc(-#{$STRATEGY--field-horizontal-padding} - #{$STRATEGY--field-border-width});
	}

	&__date-input {
		display: flex;
		align-items: center;
		height: $STRATEGY--field-height;
		width: 100%;
		border: $STRATEGY--field-border-width solid $grey-1;
		border-radius: $STRATEGY--field-border-radius;
		padding: 0 $STRATEGY--field-horizontal-padding;
		transition: $STRATEGY--button-transition;

		&--is-invalid {
			border: $STRATEGY--field-border-width solid $red;
		}

		&:focus-visible {
			outline: none;
			border: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color;
		}
	}

	&__date-input-popper {
		z-index: $STRATEGY--context-layer;
		height: 100%;
		width: 100%;
	}

	&__dropdown {
		height: $STRATEGY--field-height;
		width: 100%;
	}

	&__number-input {
		display: flex;
		align-items: center;
		height: $STRATEGY--field-height;
		width: 100%;
		border: $STRATEGY--field-border-width solid $grey-1;
		border-radius: $STRATEGY--field-border-radius;
		padding: 0 $STRATEGY--field-horizontal-padding;
		transition: $STRATEGY--button-transition;

		&--is-invalid {
			border: $STRATEGY--field-border-width solid $red;
		}

		&:focus-visible {
			outline: none;
			border: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color;
		}
	}

	&__text-input {
		display: flex;
		align-items: center;
		height: $STRATEGY--field-height;
		width: 100%;
		border: $STRATEGY--field-border-width solid $grey-1;
		border-radius: $STRATEGY--field-border-radius;
		padding: 0 $STRATEGY--field-horizontal-padding;
		transition: $STRATEGY--button-transition;

		&--is-invalid {
			border: $STRATEGY--field-border-width solid $red;
		}

		&:focus-visible {
			outline: none;
			border: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color;
		}
	}

	&__long-text-input {
		line-height: 1rem;
		min-height: $STRATEGY--field-height;
		width: 100%;
		border: $STRATEGY--field-border-width solid $grey-1;
		border-radius: $STRATEGY--field-border-radius;
		padding: 0.48rem $STRATEGY--field-horizontal-padding;
		transition: $STRATEGY--button-transition;

		&--is-invalid {
			border: $STRATEGY--field-border-width solid $red;
		}

		&:focus-visible {
			outline: none;
			border: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color;
		}
	}

	&__generate-key {
		display: flex;
		height: $STRATEGY--field-height;
		width: 100%;
		transition: $STRATEGY--button-transition;
		border-radius: $STRATEGY--field-border-radius;
		align-items: center;
		font-weight: bold;
		color: $grey-4;
		cursor: pointer;

		i {
			margin-left: 0.5rem;
		}

		&:hover {
			color: $blue;
		}

		&--loading {
			cursor: not-allowed;

			i {
				animation: spin-animation 0.5s infinite;
				display: inline-block;
			}
		}
	}

	&__validation-dialog {
		position: absolute;
		top: $STRATEGY--field-height + 0.225rem;
		left: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		align-self: start;
		color: $red;
		font-size: 0.6rem;
		font-weight: bold;
		max-width: 100%;
		background-color: $white;
		border-radius: $STRATEGY--field-border-radius;
		padding: 0.3rem;
		box-shadow: $STRATEGY--shadow-distance-1;
		z-index: $STRATEGY--context-layer;
		min-width: 14rem;

		&--with-controls {
			flex-wrap: nowrap;

			& > :first-child {
				margin-right: 0.5rem;
			}

			& > :last-child {
				margin-left: 0.5rem;
			}
		}

		&--as-warning {
			color: $amber;
		}
	}

	&__text-title-input {
		font-weight: bold;
		align-items: center;
		width: 100%;
		max-width: 100%;
		border: none;
		border-bottom: $STRATEGY--field-border-width solid $grey-1;
		border-radius: 0;
		padding: 0;
		transition: $STRATEGY--button-transition;

		&--is-invalid {
			border-bottom: $STRATEGY--field-border-width solid $red;
		}

		&--h1 {
			font-size: $STRATEGY--header-1-size;
		}

		&--h2 {
			font-size: $STRATEGY--header-2-size;
		}

		&--h3 {
			font-size: $STRATEGY--header-3-size;
		}

		&--h4 {
			font-size: $STRATEGY--header-4-size;
		}

		&--h5 {
			font-size: $STRATEGY--header-5-size;
		}

		&:focus-visible {
			outline: none;
			border-bottom: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color;
		}
	}

	&--title {
		width: 100%;
		height: 2.5rem;
	}
}

.display-value {
	box-sizing: border-box;
	display: flex;
	align-self: flex-start;
	align-items: center;
	justify-content: flex-start;
	height: $STRATEGY--field-height;
	border: $STRATEGY--field-border-width solid transparent;
	border-radius: $STRATEGY--field-border-radius;
	padding-left: $STRATEGY--field-horizontal-padding;
	cursor: pointer;
	width: 100%;
	max-width: 100%;

	&--header {
		font-weight: bold;
	}

	&--long-text {
		min-height: $STRATEGY--field-height;
		height: 100%;
		width: 100%;
		max-width: 100%;
		word-break: break-all;

		.button {
			margin-top: 0;
		}
	}

	&--readonly {
		cursor: default;
	}

	&__dot {
		border-radius: 10px;
		height: 10px;
		width: 10px;
		margin-right: 8px;
		display: block;
	}

	&__is-archived {
		color: $amber;
		font-size: medium;
		margin-right: 5px;
	}

	&__text {
		line-height: 1rem;
		padding: 0.48rem $STRATEGY--field-horizontal-padding 0.48rem 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;

		&--none {
			color: $grey-3;
		}
	}

	&__edit-button {
		visibility: hidden;

		&--show {
			visibility: visible;
		}
	}
}

.display-title {
	max-width: 100%;
	display: flex;
	align-self: start;
	align-items: flex-start;
	min-height: $STRATEGY--field-height;
	cursor: pointer;

	&--readonly {
		cursor: default;
	}

	&--with-context-menu {
		flex: 1;
		max-width: calc(100% - 50px);
	}

	&__wrapper {
		width: 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__context-menu {
		display: flex;
		flex-basis: 50px;
		width: 50px;
		justify-content: flex-end;
	}

	&__text {
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 0;
		max-width: 100%;
	}

	&__edit-button {
		margin-left: 0.5rem;
		visibility: hidden;

		&--show {
			visibility: visible;
		}

		&--h1 {
			width: 2rem;
			height: 2rem;
			margin-top: 0.2rem;
		}

		&--h2 {
			width: 1.7rem;
			height: 1.7rem;
			margin-top: 0.1rem;
		}

		&--h3 {
			width: 1.5rem;
			height: 1.5rem;
			margin-top: -0.1rem;
		}
	}

	&__context-buttons {
		width: 100%;

		button {
			width: 100%;
			font-size: $STRATEGY--font-smaller-size;
			font-weight: normal;
			justify-content: flex-start;
		}

		& > * {
			margin-bottom: 0.5rem;
		}

		& :last-child {
			margin-bottom: 0;
		}
	}

	&__context-button {
		&--h1 {
			width: 2rem;
			height: 2rem;
		}

		&--h2 {
			width: 1.7rem;
			height: 1.7rem;
		}

		&--h3 {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.toggle-field-wrapper {
	height: $STRATEGY--field-height;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.toggle-field {
	height: 0;
	width: 0;
	visibility: hidden;
	position: absolute;

	$switch-size: $STRATEGY--field-height - 1rem;

	&__label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: $switch-size * 2 + 0.625rem;
		height: $STRATEGY--field-height - 0.625rem;
		background: $grey-2;
		border-radius: $STRATEGY--border-radius;
		position: relative;
		transition: $STRATEGY--button-transition;

		&--is-on {
			background: $blue;
		}

		&--is-disabled {
			cursor: not-allowed;
			background: $grey-4;
		}

		&--is-readonly {
			cursor: not-allowed;
		}
	}

	&__switch {
		content: "";
		position: absolute;
		top: 3px;
		left: 3px;
		width: $switch-size;
		height: $switch-size;
		border-radius: $STRATEGY--border-radius - 0.125rem;
		transition: $STRATEGY--button-transition;
		background: $white;
		box-shadow: $STRATEGY--shadow-distance-1;

		&--is-disabled {
			background: $grey-3;
		}
	}
}

.toggle-field:checked + .toggle-field__label .toggle-field__switch {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.toggle-field__label:active .toggle-field__switch {
	width: 25px;
}

// Third party library overwrites
.react-datepicker__close-icon {
	top: -0.25rem;
	right: 0.45rem;

	&:after {
		font-size: 2.2rem;
	}
}

.react-select {
	font-size: $STRATEGY--font-normal-size;

	&__menu {
		z-index: $STRATEGY--dropdown-menu-layer;
		margin-top: 1px;
	}

	&__control {
		border: $STRATEGY--field-border-width solid $grey-1 !important;
		border-radius: $STRATEGY--field-border-radius;
		transition: $STRATEGY--button-transition;
		box-shadow: none;
		min-height: $STRATEGY--field-height;
		height: $STRATEGY--field-height;

		&:focus-visible,
		&--is-focused {
			outline: none;
			border: $STRATEGY--field-border-width solid $STRATEGY--field-border-focused-color !important;
		}
	}

	&__value-container {
		padding: 0 calc(#{$STRATEGY--field-horizontal-padding} - 2px);
	}

	&__indicator {
		padding: 0.2rem;
	}

	&__indicator-separator {
		margin: 0.3rem 0;
	}
}

.strategy-date-picker-wrapper {
	z-index: $STRATEGY--modal-layer;
	.react-datepicker-wrapper {
		width: 100%;
		height: 100%;
	}

	.react-datepicker-popper {
		z-index: $STRATEGY--modal-layer;
	}

	.react-datepicker__input-container {
		height: 100%;
		width: 100%;
	}

	.react-datepicker {
		color: $primary-text-color;
		border: none;
		border-radius: 5px;
		box-shadow: 0 3px 13px rgba(0, 0, 0, 0.12);
		font-family: century-gothic, Arial, sans-serif;
	}

	.react-datepicker__header {
		border-bottom: none;
	}

	.react-datepicker__triangle::before {
		border-bottom-color: #f0f0f0;
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected {
		background-color: $blue;
	}

	// navigation arrows
	.react-datepicker__navigation {
		background: none;
		line-height: 1.7rem;
		text-align: center;
		cursor: pointer;
		position: absolute;
		top: 10px;
		width: 0;
		padding: 0;
		border: 0.45rem solid transparent;
		z-index: 1;
		height: 10px;
		// overwrite is necessary for some browsers to work
		width: 10px;
		text-indent: -999em;
		overflow: hidden;
	}

	.react-datepicker__navigation--next {
		right: 10px;
		border-left-color: $grey-4;
	}

	.react-datepicker__navigation--previous {
		left: 10px;
		border-right-color: $grey-4;
	}
}

.key-display {
	font-size: large;
	cursor: default;
	font-weight: bold;
	color: $grey-4;
}

.required-label {
	&:after {
		content: " *";
		color: $red;
	}
}
