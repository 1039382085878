.permissions-modal {
	.auto-complete__label {
		font-size: smaller;
		padding-bottom: 10px;
		color: $almostblack;
	}
	.tabs {
		.horizontal-island-margin {
			margin: 0;
		}
	}
}

.permissions-rows {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid;
	border-color: $dark-pale-grey;
}

.create-user-permission__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;

	.create-user-permission__item {
		display: flex;
		width: 32%;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		padding: 10px 0;
		border-color: $dark-pale-grey;
		text-align: center;

		@media #{$medium-screen} {
			width: 45%;
			height: 140px;
			padding: 10px 30px;
		}
	}

	.create-user-permission__name {
		padding-bottom: 8px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}

.create-user-success__text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px 0;
}

.create-user-list {
	width: 568px;
	display: flex;
	flex-wrap: wrap;
	@media #{$small-screen} {
		width: auto;
	}

	.create-user-list__item {
		display: flex;
		align-items: center;
		width: 50%;
		padding-bottom: 25px;

		@media #{$small-screen} {
			width: 100%;
		}
	}
}

.sort-buttons {
	position: absolute;
	right: 0;
	padding-right: 1em;
	display: flex;
	font-weight: bold;
}
