.report-info__controls-wrapper {
	@extend .report-info__section, .flex;
	min-height: 75px;
	max-height: 75px;

	.report-info__control-btn {
		color: $white;
		padding-left: 0;
		width: 100%;
		display: flex;
		justify-content: flex-start;

		&:hover,
		&:focus,
		&:active {
			background-color: $deep-blue;
		}

		.report-info__control-icon {
			@extend .box-shadow;
			color: $primary-text-color;
			background: $very-light-grey;
			height: 40px;
			width: 40px;
			border-radius: 50%;
		}
	}
}
