.sort-button {
	display: inline-flex;
	width: 90px;
	justify-content: center;
	align-items: center;

	&__arrow {
		margin-right: 10px;
	}

	@media screen and (max-width: 930px) {
		display: none;
	}
}
