.sync-status {
	&--divider {
		border-top: 0.2px solid $lightgrey;
		margin: 0;
	}

	&--header {
		font-size: 1em;
		margin-bottom: 0.5em;
		color: $grey;
	}

	&--last {
		@extend .flexible-island;
		@extend .vertical-list;

		.date {
			font-size: 1.5em;
		}
	}

	&--errors,
	&--warnings {
		@extend .flexible-island;
		@extend .vertical-list;

		&-list {
			padding-left: 0;
			list-style: none;
		}
	}

	&--errors {
		color: $red;
	}

	&--warnings {
		color: $warning;
	}

	&--no-errors {
		display: flex;
		flex-direction: column;
		align-items: center;

		&__img {
			width: 60%;
			height: 60%;
		}

		&__text {
			@extend .font-bold;
			font-size: 1.5em;
			color: $black;
			text-align: center;
		}
	}
}
