/*Helpers can be used directly, or extended (@extend) */
@import "Tile/DatasetInfo/components/ChangeDataset/style";
@import "Tile/FullVisualization/style";

.u-strong {
	font-weight: bold;
}

.larger-text {
	font-size: larger;
}

.rounded-filled-box-shadow {
	@extend .box-shadow;
	border-radius: $border-radius;
	background-color: $white;
}

/*TODO: extend*/
.report-page .legends,
.small-text {
	font-size: 0.9em;
}

.reset-input {
	border: inherit;
	padding: inherit;
}

.report-page {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 100px;
	.legends {
		overflow-x: hidden;
		justify-content: center;
	}
	.xy-legend {
		// To match chart-area and ensure both center consistently.
		margin-left: 30px;
		padding: 0;
	}

	&__big-screen-shrink {
		@media #{$mobile-screen} {
			width: 90%;
			margin: 0 5%;
		}
	}
}

.report-page-grid {
	@extend .small-island;

	.report-page-grid-horizontal {
		flex-wrap: wrap;
	}

	.cell-placeholder {
		min-height: 150px;
	}

	.cell-placeholder.long {
		flex-grow: 1;
	}

	.cell-placeholder.short {
		flex-basis: 150px;
	}
}

.cell-placeholder-btn {
	// Override btn
	border: 3px dashed $lightgrey;
	flex-grow: 1;
	border-radius: $border-radius;
}

.visualization-root {
	min-height: $visualization-cell-default-height;
	flex-grow: 1;
}
.visualization-root__fit-to-page {
	// Correct rendering for print preview + fit to row
	min-height: 0;
	flex-grow: 1;
}
.visualization__fit-to-page {
	// Correct rendering for print preview + fit to row
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.visualization-toolbar {
	@extend .horizontal-list;
	@extend .horizontal-list--wrap;
	@extend .interactive;
	justify-content: space-between;
}

.visualization-toolbar__styled-headers {
	border-bottom: 0px;
	margin: -4px -25px 10px;

	& .content-editable {
		font-size: larger;
	}

	& .icon-btn {
		color: $white;
	}
	& .icon-btn__raised {
		color: $primary-text-color;
	}
	& .icon-btn:hover,
	& .icon-btn:focus {
		color: $primary-text-color;
	}
}

.visualization-toolbar__styled-headers--text-tile {
	margin: 0;
}

.cell-inactive {
	border: 4px solid transparent;
}

.visualization-toolbar-group {
	@extend .horizontal-list;
	@extend .horizontal-list--center;
	@extend .horizontal-list--wrap;
	font-size: 0.9em;
}

.visualization-toolbar-group__fixed {
	position: absolute;
	flex-direction: column;
	right: -2em;
	top: -5em;
}

.visualization-controls {
	z-index: $general-top-layer;
}

.report-header {
	@extend .small-island;
	@extend .horizontal-list;
	@extend .horizontal-list--center;
	@extend .interactive;
	justify-content: space-between;
	align-items: flex-start;
	pointer-events: none;
	min-height: 60px;

	.report-header__inner {
		min-height: 40px;
		align-items: center;
		width: 100%;
		@media #{$mobile-screen} {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.report-header__divider {
		display: flex;
		@media #{$mobile-screen} {
			display: none;
		}
	}
	.report-name-wrapper {
		flex-shrink: 1;
		display: flex;
		font-size: larger;
		@media #{$mobile-screen} {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

.visualization-source {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: right;
	color: $grey;
	font-size: 0.8em;
	opacity: 0;
	pointer-events: none;
	padding: 0 20px 5px;
}

.visualization-source--visible {
	opacity: 1;
}

.visualization-source--clickable {
	text-decoration: underline;
	cursor: pointer;
}

.report-controls {
	@extend .interactive;
	z-index: $general-top-layer;
}

.report-title {
	z-index: $general-top-layer;
	display: flex;
	@media #{$mobile-screen} {
		width: 100%;
	}
}

.report-page-title {
	@extend %content-editable__default-title;
	z-index: $general-top-layer;
	margin-left: 5px;
	@media #{$mobile-screen} {
		margin-left: 0px;
		margin-top: 0.5em;
		width: 100%;
	}
}

/* Specific styles for edit mode */

/*Override external UI component styles*/
create-dataset-via-upload {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	ck-file-select {
		align-self: stretch;

		.drag-drop-area {
			min-height: inherit;
		}

		.drag-drop-label {
			margin-top: inherit;
			margin-bottom: inherit;
		}
	}
}

.filters {
	.filter-header {
		margin-top: 2px;
		margin-bottom: 2px;
		cursor: pointer;
		border: none;
		width: 100%;
		background: transparent;
	}

	.filter-header-btn {
		flex-grow: 1;
		padding-left: 0;
	}

	.filter-applied {
		padding: 0 $small-horizontal-unit;
	}

	h4 {
		margin-top: inherit;
		margin-bottom: inherit;
		font-weight: bold;
		padding: 0;
		padding-left: 0.5em;
		display: flex;
		align-items: center;
	}

	.collapse-icon {
		margin-right: 5px;
	}

	.filter-name {
		flex-grow: 1;
		text-align: left;
	}

	.filter-applied {
		margin-top: calc(#{$font-size} * 0.1);
		font-size: calc(#{$font-size} * 0.8);
		color: rgba(0, 0, 0, 0.33);
	}
}

.discrete-filters {
	@extend .u-reset-list;
	display: flex;
	flex-direction: column;
	// We don't want the clickable areas of the labels to stretch
	align-items: flex-start;
	@extend .small-flexible-island;

	label {
		@extend .horizontal-list;
		@extend .horizontal-list--center;
	}

	input {
		// Unset UAS
		margin: inherit;
	}
}

.pagination-links-wrapper {
	text-align: center;
	margin-top: $small-vertical-unit;

	.pagination-links {
		display: inline-block;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;

			&.active-page > a {
				background-color: hsl(0, 0%, 88%);
				cursor: default;
				pointer-events: none;
			}

			> a {
				@extend .u-reset-link;
				@extend .small-flexible-island;

				text-decoration: none;
				background-color: hsl(0, 0%, 93%);

				cursor: pointer;
				margin: 0 1px;

				&:hover {
					background-color: hsl(0, 0%, 88%);
				}
			}

			&.disabled {
				> a {
					color: $inactive;
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
}

.series-legend-line {
	// draw a small coloured line
	display: inline-block;
	width: 2em;
	height: 3px;
	// separate from legend text
	margin-left: 0.25em;

	vertical-align: middle;
}

.text-node {
	width: 100%;
	height: 100%;
	min-height: 160px;
}

.text-node-value {
	font-size: smaller;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul,
	ol,
	blockquote {
		margin-top: inherit;
		margin-bottom: $horizontal-unit;
	}

	img {
		max-width: 100%;
	}
}

.text-node-value.style-headers {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-left: calc(-1 * (#{$horizontal-unit} / 4) * 3);
		padding-left: calc((#{$horizontal-unit} / 4) * 3);
		background-color: $blue;
		color: $white;
	}
}

.text-form {
	@extend .flex-grow-tsu;
	// This can just be display: contents, but we don't have support for it
	// yet.
	@extend .vertical-list;

	textarea {
		@extend .flex-grow-tsu;
	}
}

.dimension-label__name {
	width: 30%;
}

.dimension-label__value {
	font-weight: bold;
	white-space: nowrap;
}

.xy-visualization {
	display: flex;
	flex-direction: row;

	// Correct rendering for print preview + fit to row
	flex-grow: 1;

	.x-axis {
		display: flex;
		justify-content: center;
		font-size: 0.9em;
	}

	.x-axis-dimension {
		display: flex;
	}

	.y-axis {
		font-size: 0.9em;
		// Set width for vertical flexbox writing style element for full browser support...
		width: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		// IE11 needs flex-direction column set for justify-content to work.
		-ms-flex-direction: column;
	}

	.chart-area {
		flex: 1;
		// Set width for vertical flexbox writing style element for full browser support...
		width: calc(100% - 30px);
		// Correct rendering for print preview + fit to row
		display: flex;
		flex-direction: column;
	}
}

.vertical-select-outer-container {
	display: inline-block;
}

.y-axis .visualization-toolbar-group {
	// hacky fix for not-rotated element covering chart area
	pointer-events: none;
}

.y-axis .visualization-toolbar-group .flexible-island__item {
	transform: rotate(-90deg);
	display: flex;

	// hacky fix for blurry text in webkit browsers (vs 50% 50%)
	transform-origin: 50% 49%;

	.context-menu__menu {
		transform: rotate(90deg);
		transform-origin: 30px 60px;
		margin-top: calc(-1.5 * #{$horizontal-unit});
	}
}

.visualization-message-overlay {
	min-height: $visualization-cell-default-height;
	padding: 1em;
	text-align: center;
}
.visualization-message-controls {
	display: flex;
	justify-content: center;
	button {
		margin-right: 1em;
	}
	button:last-child {
		margin-right: 0;
	}
}

.chart-menu .context-menu {
	> .context-menu__menu {
		right: inherit;
		width: 454px; // Additional width for optional scrollbar
		margin-left: -13rem;
		flex-wrap: wrap;
		flex-direction: row;
		overflow: auto;
		> .context-menu__menu__item {
			width: 13rem;
		}
	}
}

.chart-menu-item-active {
	background: $lightgrey;
	border-radius: $border-radius;
}

.chart-menu-item-label {
	display: flex;
}

.hoverable-select {
	font-weight: bold;

	select {
		border: 1px solid white;
		background: white;

		&:hover {
			border: 1px solid $lightgrey;
			background: $fadedpink;
		}
	}
}

.togglable-button {
	display: flex;
	border-radius: $border-radius;

	&.togglable-button--active {
		color: $blue;
	}

	.togglable-button-icon-wrapper--active {
		padding-right: 0;
	}

	.togglable-button-icon--active {
		color: $blue;
	}

	.togglable-button-body {
		display: flex;
		color: $blue;
	}
}

.grouping-context-menu {
	> nav {
		padding: 0.5em;
		min-width: 300px;
	}
}

.more-context-menu {
	> nav {
		padding: 0.5em;
		min-width: 380px;
		overflow: auto;
		> .context-menu__menu__item {
			min-height: 32px;
		}
	}
}

.dropdown-menu .context-menu {
	position: inherit;

	.select-btn {
		// hacky overwrite for button setting "font: unset !important" in cssOnly.scss
		font-weight: bold !important;

		// additional space for triangle, this is a fix for rotated text for y-axis
		width: calc(100% + #{$vertical-unit});
	}

	.triangle {
		display: inline;
		font-size: 0.5rem;
		margin-left: 0.2rem;
		margin-top: 0.15rem;
		opacity: 0;
	}

	&:hover {
		.triangle {
			opacity: 1;
		}
	}

	> .context-menu__menu {
		display: block;
		max-height: 200px;
		overflow: auto;
		padding: 0;
		right: inherit;

		// uncomment for menu over dropdown label
		// margin-top: calc(-1.5 * #{$horizontal-unit});
	}

	// FIXME - clicking IE11 scrollbar will make the context-menu lose focus and closes it.
	// To prevent it scrollbars are hidden in IE.
	> .context-menu__hide-scroll {
		-ms-overflow-style: none;
	}

	.dropdown-menu-item {
		background: white;
		justify-content: flex-start;
		width: 100%;

		&:hover {
			background: $fadedpink;
		}
	}

	.dropdown-menu-item--active {
		background: $blue;
		color: white;

		&:hover {
			background: $blue;
		}
	}
}

// Badge added to the title of inspector when a a field on an entity is changed
.update-state-badge {
	float: right;
	font-size: 0.8em;
	padding: 0.2em 0.5em;
	&.updated {
		background: $brightyellow;
	}
	&.created {
		background: $blue;
		color: $white;
	}
	&.unchanged {
		background: $grey;
		color: $darkgrey;
	}
}

.grouping-label {
	margin-bottom: $small-horizontal-unit;
}

.grouping-list {
	list-style: none;
	width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: $horizontal-unit;

	.grouping-list-item {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $lightgrey;
		cursor: move;

		.grouping-list-item-label {
			@extend .small-island;
		}
	}

	.grouping-list-icon-drag {
		@extend .small-island;
		font-size: 1em;
	}

	.grouping-list-icon-remove {
		font-size: 0.7em;
	}
}

.datum-table__wrapper {
	display: flex;
	justify-content: center;

	.datum-table {
		@extend .u-reset-list;
		font-size: smaller;
		margin-top: 2rem;
		width: 100%;
		margin-left: 1.32rem;
		margin-right: 1.32rem;

		.datum-row:nth-child(1) {
			border-top: 1px solid $fadedpink;
		}

		.datum-row {
			display: flex;
			flex-direction: row;
			border-bottom: 1px solid $fadedpink;
			border-right: 1px solid $fadedpink;
		}

		.datum-row--updated {
			background: $brightyellow;
		}

		.datum-row__value {
			padding: 4px;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;

			input {
				border: 0;
				border-bottom: 1px solid $grey;
			}
		}

		.datum-row__title {
			background-color: $fadedpink;
			padding: 4px;
			text-align: right;
			font-weight: bold;
			flex: 200 0 auto;
			width: 200px;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.editable-title {
	border: none;
	border-bottom: 1px dashed $grey;
}

.tile-datapoints,
.tile-source {
	overflow: hidden;
	height: 19px;
	line-height: 1.5;
	align-self: flex-end;
}
