$page-wrapper-top-padding: 2rem;
$page-wrapper-left-padding: 2rem;

.page-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-width: $STRATEGY--max-page-width;
	padding: $page-wrapper-top-padding $page-wrapper-left-padding 0 $page-wrapper-left-padding;

	&--full-width {
		max-width: 100%;
	}

	@media #{$desktop-screen} {
		padding: 1.6rem 2rem 0 2rem;
	}

	@media #{$hd-screen} {
		padding: 1.2rem 1.6rem 0 1.6rem;
	}

	@media #{$mobile-screen} {
		padding: 1rem 1.2rem 0 1.2rem;
	}
}

.page-content {
	& > * {
		margin-bottom: 1rem;
	}
}

.page-with-gutter {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;

	@media (min-width: #{$desktop-screen-size}) {
		justify-content: center;
	}

	.page-wrapper {
		padding: $page-wrapper-top-padding $page-wrapper-top-padding 0 $page-wrapper-left-padding;
	}

	&__content {
		padding-right: 1rem;
		min-height: 100vh;
		flex-grow: 5;
		max-width: 1000px;

		@media #{$desktop-screen} {
			max-width: 900px;
		}

		@media #{$hd-screen} {
			max-width: 750px;
		}

		@media #{$mobile-screen} {
			max-width: 600px;
		}

		@media #{$medium-screen} {
			max-width: 400px;
		}

		&--in-modal {
			max-width: 850px;

			@media #{$desktop-screen} {
				max-width: 750px;
			}

			@media #{$hd-screen} {
				max-width: 650px;
			}

			@media #{$mobile-screen} {
				max-width: 400px;
			}

			@media #{$medium-screen} {
				max-width: 300px;
			}
		}
	}

	&__gutter {
		border-left: 1px solid $grey-1;
		margin: 6.5rem 0 $page-wrapper-top-padding 0;
		flex-grow: 2;
		max-width: 400px;
		min-width: 250px;
	}

	&__gutter-content {
		height: 100%;
		padding: 0 1rem;
	}
}
