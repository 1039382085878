.action-button {
	display: flex;
	cursor: pointer;

	&.disabled {
		cursor: default;
		opacity: 0.6;
	}

	&--icon-group {
		@extend .flexible-island;
		padding-left: 0;

		&__icon {
			font-size: medium;
			color: $darkgrey;
		}

		&__bg {
			@extend .box-shadow;
			background-color: $very-light-grey;
		}
	}

	&--text {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&__subtitle {
			font-size: smaller;
		}
	}
}
