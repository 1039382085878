@import "Header/style";
@import "ReportControls/style";
@import "CreatedInfo/style";
@import "Aliases/style";

.report-info__section {
	padding: 10px 15px;
	color: $white;
	background-color: $kiplot-blue;
	position: relative;

	.content-editable__bold {
		max-width: 95%;

		&.focus {
			background-color: $deep-blue;
			z-index: 2;
			border-radius: $border-radius;
			padding: 5px;

			.content-editable-can-edit__input {
				word-wrap: inherit;
				overflow: visible;
				white-space: normal;
				text-overflow: inherit;
			}
		}

		.content-editable-can-edit__input {
			word-wrap: break-word;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.report-info__wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.concertina-menu__list-item {
		font-size: medium;
	}
}

.report-info__sub-section {
	@extend .report-info__section, .font-bold;
	background-color: $deep-blue;
	padding-top: 0;
	padding-bottom: 0;
	min-height: 40px;
	display: flex;
	align-items: center;
}

.report-info__attach-list-wrapper {
	@extend .flexible-island;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.report-info__sub-text {
	color: $grey;
	font-size: smaller;
	display: block;
	margin-bottom: 15px;
}
