$STRATEGY--roadmap-header-height: 4.4rem;

@import "./components/style.scss";
@import "./components/Goals/style";

.roadmap-white-box {
	border-radius: $STRATEGY--border-radius;
	background-color: $white;

	&__header {
		display: flex;
		padding: 0 1rem 1rem 1rem;
		align-items: center;

		h3 {
			font-weight: normal;
			margin-right: 0.5rem;
		}
	}
}

.roadmap-wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	place-items: stretch;
	overflow: auto;

	height: calc(100vh - 14rem);
}

.roadmap-buttons {
	display: flex;

	& > * {
		margin-left: 0.5rem;
	}
}

.roadmap-measure-row {
	width: calc(100% - $normal-spacing * 2);
	height: 0;
	margin-left: $normal-spacing;
}
