$cell-width: 145px;

%importTableEllipsis {
	word-wrap: break-word;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: $cell-width;
}

.column-edit-row-wrapper {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	width: 100%;
	padding-bottom: 15px;

	.column-edit-row {
		display: flex;
		flex-direction: column;
		margin: 2px;

		.column-edit-row__item {
			border: 1px solid $lightgrey;
			border-radius: 4px;
			padding: 4px;
			@extend %importTableEllipsis;
			width: 141px;
			height: 58px;
			font-size: smaller !important;

			&.error {
				border: 1px solid $red;
			}

			.inline-column-error {
				@extend .inline-error;
				height: 16px;
			}

			.error-placeholder {
				height: 16px;
				width: 100%;
				display: block;
			}

			> select {
				background-color: transparent;
				border-bottom: none;
			}
		}
	}

	.modal-input {
		margin: 6px;
		height: 35px;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		width: auto !important;
		background-color: $fadedpink;
		border-color: $lightgrey;
		font-size: smaller !important;
	}
}

.column-editor__wrapper {
	width: 100%;
	height: 100%;
	padding: 20px;
	margin-top: 30px;
	position: relative;
	overflow: auto;
}

.column-editor_header {
	display: flex;
	justify-content: space-between;
}

.invalid-select {
	background-color: $fadedpink !important;
	border-radius: 5px !important;
	border-bottom: 1px transparent;
}

.row-editor-table__position {
	position: absolute;
	padding-bottom: 60px;
}

.row-editor-table__wrapper {
	width: 100%;
	height: 100%;
	overflow: scroll;
	position: relative;
}

.row-editor-table {
	overflow: auto;
}

.row-editor-header {
	font-size: smaller;
	background: $lightgrey;
}

.row-editor-table__head {
	text-align: center;
	width: $cell-width;
	display: inline-block;
	@extend %importTableEllipsis;
}

.row-editor-table__cell {
	@extend %importTableEllipsis;
	font-size: smaller;
	text-align: center;
	padding: 1em 0.5em;
	width: $cell-width;
	display: inline-block;
	&.row-error {
		background: $fadedpink;
	}
}
