.forecast-list {
	& > * {
		margin-bottom: 1rem;
	}

	& :last-child {
		margin-bottom: 0;
	}
}

.forecast-entry-list {
	&--center-text {
		text-align: center;
	}

	&--center {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}

.forecast-description {
	display: flex;
	flex-direction: column;
	margin: 1rem 0;
}
