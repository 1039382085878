.stage-anchor-points {
	position: absolute;
	left: 0;
	top: $STRATEGY--CC-period-header-height;
	width: calc(100% - 10px);
	max-height: calc(100% - 70px);
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	pointer-events: none;
	overflow: hidden;

	&__vertical {
		width: 100%;
		height: 2px;
		display: flex;
		column-gap: $small-spacing;
		padding: 0 $small-spacing;
	}

	&__cell {
		width: 100%;
		height: 100%;
	}
}

.unplanned-dependency-arrow {
	width: 1.6rem;
	height: 1.6rem;
	background-color: $red;
	position: absolute;
	z-index: 2;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: all;
	cursor: pointer;

	&--hovered {
		background-color: $blue;
	}
}
