@import "../../../../lib/style/breakPoints";

.dataset-sort-buttons {
	@include hide;
	padding-left: 5px;

	&:hover .sort-icon {
		opacity: 0.3;
	}

	.sort-icon {
		margin-right: 3px;
		opacity: 0;

		&.sorting {
			opacity: 1;
		}
	}

	.sort-button {
		width: 92px;
	}

	@media #{$mobile-break-point} {
		@include show;
	}
}
