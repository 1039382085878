@use "sass:color";

.control-center-outcome-item {
	box-sizing: content-box;
	display: flex;
	align-items: stretch;
	justify-content: stretch;
	height: 2rem;
	transition: $STRATEGY--button-transition;
	position: relative;
	border-radius: $STRATEGY--border-radius;
	user-select: none;

	&--focused {
		filter: drop-shadow(0px 0px 3px #05f);
	}

	&__left-edge {
		width: 6px;
		height: 2rem;

		&--rounded {
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}

		&--torn {
			clip-path: $STRATEGY--left-tear-clipping-path;
		}
	}

	&__estimate {
		box-sizing: border-box;
		min-width: 40px;
		width: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 0.2rem 0 0;
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		border-right: 0.125rem solid $white;
	}

	&__color-status {
		box-sizing: border-box;
		min-width: 40px;
		width: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 0.2rem 0 0;
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		border-right: 0.125rem solid $white;
	}

	&__left-pill-color {
		background-color: $grey-blue-2;
		color: $grey-blue-5;

		&--green {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-green;

			&.control-center-outcome-item__left-pill-color--remain {
				color: $primary-text-color;
				background-color: color.adjust($STRATEGY--cc-effort-risk-color-green, $lightness: 25%);
			}
		}

		&--amber {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-amber;

			&.control-center-outcome-item__left-pill-color--remain {
				color: $primary-text-color;
				background-color: color.adjust($STRATEGY--cc-effort-risk-color-amber, $lightness: 25%);
			}
		}

		&--red {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-red;

			&.control-center-outcome-item__left-pill-color--remain {
				color: $primary-text-color;
				background-color: color.adjust($STRATEGY--cc-effort-risk-color-red, $lightness: 25%);
			}
		}

		&--done {
			color: $white;
			background-color: $STRATEGY--cc-effort-risk-color-done;
		}

		&--grey {
			color: $primary-text-color;
			background-color: $grey-blue-2;
		}
	}

	&__estimate-delimiter {
		height: 1px;
		width: 60%;
		background: $white;

		&--remain {
			background: $black;
		}
	}

	&__content {
		flex: auto;
		display: flex;
		align-items: center;
		justify-content: stretch;
		background-color: $grey-blue-1;
		padding: 0.4rem 0 0.4rem 0.4rem;
		max-width: calc(100% - 40px - 6px * 2);
	}

	&__content-name {
		flex: auto;
		max-width: 100%;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		font-size: $STRATEGY--font-small-size;
		font-weight: bold;
		color: $primary-text-color;
		padding: 0.45rem 0.3rem 0.45rem 0;
	}

	&__content-actions {
		flex: initial;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__action-button {
		margin: 0 1px;
		height: $STRATEGY--icon-height;
		width: $STRATEGY--icon-height;

		i {
			font-size: $STRATEGY--font-bigger-size;
		}
	}

	&__right-edge {
		width: 6px;
		height: 2rem;
		background-color: $grey-blue-1;

		&--rounded {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		&--torn {
			clip-path: $STRATEGY--right-tear-clipping-path;
		}
	}
}

.control-center-outcome-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: stretch;
	width: 100%;
	background-color: $white;
	border-radius: 6px;
	border: 2px solid $grey-blue-2;

	&--grouped-with-previous {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		margin-top: -0.5rem;
		padding-top: 0.5rem;
		border-top: none;

		.control-center-outcome-group__header {
			display: none;
		}
	}

	&--grouped-with-next {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: -0.5rem;
		padding-bottom: 0.5rem;
		border-bottom: none;

		.control-center-outcome-group__content {
			padding-bottom: 0;
		}
	}

	&__header {
		width: 100%;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}

	&__header-name {
		flex: 1;
		max-width: calc(100% - 0.4rem - (#{$STRATEGY--icon-height} - 1px) * 1);
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		white-space: nowrap;
		font-size: $STRATEGY--font-small-size;
		color: $primary-text-color;
		padding: 0.4rem;
	}

	&__header-actions {
		flex-basis: calc(#{$STRATEGY--icon-height} * 1);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 0.4rem;
	}

	&__content {
		width: 100%;
		padding: 0 0.4rem 0.4rem 0.4rem;
	}
}
