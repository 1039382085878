@import "Header/style";
@import "IconMobileMenu/style";
@import "OtherReports/style";
@import "SyncStatus/style";
@import "Metadata/style";
@import "ActionButton/style";
@import "AdvancedSettings/style";

.dataset-info__body {
	background-color: $kiplot-blue;
	color: $white;
	margin-top: -1rem;

	.derived-dataset--external-link .external-link a {
		border-bottom: none;
	}

	.derived-dataset--external-link .external-link a:hover {
		color: $white;
	}
}

.accordion-tabs {
	color: $white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $deep-blue;
	width: 100%;
	height: 35px;
	cursor: pointer;
	padding: 0 0.99rem;

	&:hover,
	&:focus {
		background-color: $darkblue;
	}

	h4 {
		margin: 0;
	}

	&__spinner-with-message {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;

		.spinner > circle {
			stroke: $white;
		}
	}
}
